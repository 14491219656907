/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown, Input } from 'semantic-ui-react';
import ReactGridLayout from 'react-grid-layout';
import { difference, flatMap } from 'lodash';
import { useStateValue } from '../../../core/context/StateProvider';
import './TablesScreen.css';
import TablesScreenModal from './TablesScreenModal';
import { createRooms, deleteRoom, getRooms, getTables } from '../../../api/ShopAPI';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const TableOverviewModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isTablesFetched, setIsTablesFetched] = useState(false);
  const [selectedRoomName, setSelectedRoomName] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [newRoomModalOptions, setNewRoomModalOptions] = useState({ open: false });
  const [addTableModalOptions, setAddTableModalOptions] = useState({ open: false });
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newTableModalOptions, setNewTableModalOptions] = useState({ table: null, open: false });
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState({ open: false });
  const [openDeleteTableModal, setOpenDeleteTableModal] = useState({ open: false, item: null });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [tables, setTables] = useState([]);

  useEffect(() => {
    if (open === true && isDataFetched === false) {
      getRoomsData();
    }
    if (open === true && rooms.length !== dropdownOptions.length) {
      setDropdownOptions(
        rooms.map((room, index) => ({ key: room.name + index, text: room.name, value: room.name }))
      );
    }
    if (open === true && isTablesFetched === false) {
      getAllTables();
    }
  });

  const getAllTables = async () => {
    try {
      setIsTablesFetched(true);
      const res = await getTables(token);
      if (res && res.success) {
        setTables(res.tables);
      } else {
        setTables([]);
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablePlanError'),
        });
      }
    } catch (_) {
      setTables([]);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('tablePlanError'),
      });
    }
  };

  const getRoomsData = async () => {
    try {
      setIsDataFetched(true);
      const res = await getRooms(token);
      if (res && res.success) {
        setRooms(res.rooms);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablePlanError'),
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('tablePlanError'),
      });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    try {
      setIsLoading(true);
      const res = await createRooms(token, rooms);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablePlanCreateError'),
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('tablePlanCreateError'),
      });
    }
  };

  const onDeleteRoomSave = async (roomName) => {
    try {
      setIsLoading(true);
      const res = await deleteRoom(token, roomName);
      if (res && res.success) {
        setOpenDeleteRoomModal({ open: false });
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('roomDeleteError'),
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('roomDeleteError'),
      });
    }
  };

  const onDeleteFromRoom = () => {
    if (
      openDeleteTableModal.item &&
      openDeleteTableModal.item.number !== null &&
      selectedRoomIndex !== null
    ) {
      const index = rooms[selectedRoomIndex].tables.findIndex(
        (table) => table.number === openDeleteTableModal.item.number
      );
      rooms[selectedRoomIndex].tables.splice(index, 1);
      setRooms([...rooms]);
      setOpenDeleteTableModal({ open: false, item: null });
    }
  };

  const onLayoutChange = (newLayout) => {
    if (selectedRoomIndex !== null) {
      for (let i = 0; i < rooms[selectedRoomIndex].tables.length; i++) {
        const table = rooms[selectedRoomIndex].tables[i];
        const layout = newLayout[i];
        if (table.number === Number(layout.i)) {
          rooms[selectedRoomIndex].tables[i] = {
            ...rooms[selectedRoomIndex].tables[i],
            w: layout.w,
            h: layout.h,
            x: layout.x,
            y: layout.y,
          };
        }
      }
      setRooms([...rooms]);
      setIsSaveDisabled(false);
    }
  };

  const onCreateRoom = () => {
    if (rooms.find((room) => room.name === roomName)) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('roomNameError'),
      });
    } else {
      setRooms([...rooms, { name: roomName, tables: [] }]);
      setNewRoomModalOptions({ open: false });
      setRoomName('');
    }
  };

  const onDeleteRoom = async () => {
    if (selectedRoomIndex !== null) {
      await onDeleteRoomSave(selectedRoomName);
    }
  };

  const onCloseNewTableModal = async (refreshNeeded) => {
    setNewTableModalOptions({ table: null, open: false });
    if (refreshNeeded) {
      setIsTablesFetched(false);
      setTables([]);
    }
  };

  const closeModal = (refreshNeeded) => {
    setIsSaveDisabled(true);
    setIsLoading(false);
    setIsDataFetched(false);
    setIsTablesFetched(false);
    setSelectedRoomName(null);
    setSelectedRoomIndex(null);
    setRooms([]);
    setRoomName('');
    setNewRoomModalOptions({ open: false });
    setAddTableModalOptions({ open: false });
    setDropdownOptions([]);
    setNewTableModalOptions({ open: false, table: null });
    setOpenDeleteTableModal({ open: false, item: null });
    setOpenDeleteRoomModal({ open: false });
    setErrorWarningModalOptions({ open: false, message: '' });
    setTables([]);
    onClose(refreshNeeded);
  };

  const onDropDownChange = (value) => {
    const index = rooms.findIndex((room) => room.name === value);
    setSelectedRoomIndex(index);
    setSelectedRoomName(value);
  };

  const parseGridItems = (layout) =>
    layout.map((item) => (
      <div
        key={item.number}
        className="tables-grid-item"
        data-grid={{
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          minH: item.minH,
          maxW: item.maxW,
          maxH: item.maxH,
          minW: item.minW,
          isBounded: true,
          verticalCompact: true,
        }}
      >
        {item.number}
        <span
          className="remove"
          style={{
            position: 'absolute',
            left: '4px',
            top: '-4px',
            cursor: 'pointer',
            fontSize: 20,
            color: 'black',
          }}
          onTouchStart={() => setOpenDeleteTableModal({ open: true, item })}
          onMouseDown={() => setOpenDeleteTableModal({ open: true, item })}
        >
          ×
        </span>
      </div>
    ));

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="large"
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none', height: 55 }}>
          <Dropdown
            selection
            placeholder={i18n.t('chooseRoom')}
            options={dropdownOptions}
            value={selectedRoomName}
            onChange={(_, data) => {
              onDropDownChange(data.value);
            }}
            style={{ height: 20, fontSize: 15, fontWeight: 600 }}
          />
          {selectedRoomName !== null && (
            <Button
              icon="cancel"
              color="red"
              basic
              onClick={() => setOpenDeleteRoomModal({ open: true })}
              circular
              style={{ marginLeft: 5 }}
            />
          )}
          <Button
            icon="add"
            color="violet"
            basic
            onClick={() => setNewRoomModalOptions({ open: true })}
            circular
            style={{ marginLeft: 5 }}
          />
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            className="bookings-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            {selectedRoomIndex !== null ? (
              <>
                <ReactGridLayout
                  onLayoutChange={(e) => onLayoutChange(e)}
                  cols={18}
                  rowHeight={25}
                  width={950}
                  compactType={null}
                  className="react-grid-layout-tables"
                  style={{
                    backgroundColor: '#7268cd5e',
                    borderRadius: 10,
                    maxHeight: 'calc(-250px + 100vh)',
                    marginTop: 10,
                    marginRight: 5,
                    width: '100%',
                    overflow: 'scroll',
                  }}
                >
                  {parseGridItems(rooms[selectedRoomIndex].tables)}
                </ReactGridLayout>
              </>
            ) : (
              <>
                <p style={{ fontWeight: 600 }}>{i18n.t('chooseRoomDescription')}</p>
              </>
            )}
          </div>
        </Modal.Content>
        {selectedRoomIndex !== null && (
          <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: 'white' }}>
            <Button
              basic
              content={i18n.t('tablesAddNewItem')}
              floated="left"
              onClick={() => {
                if (selectedRoomIndex != null) {
                  const allTableNumbersInRooms = flatMap(rooms, (room) => room.tables).map(
                    (table) => table.number
                  );
                  const allTableNumberOfShop = tables.map((table) => table.number);
                  if (difference(allTableNumberOfShop, allTableNumbersInRooms).length !== 0) {
                    setAddTableModalOptions({ open: true });
                  } else {
                    setNewTableModalOptions({ open: true, table: null });
                  }
                }
              }}
              disabled={selectedRoomIndex == null}
              color="violet"
            />
            <Button
              loading={isLoading}
              content={i18n.t('tableModalSave')}
              disabled={isSaveDisabled === true || isLoading === true}
              floated="right"
              onClick={onSave}
              color="teal"
            />
          </Modal.Actions>
        )}
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <AnimatedModal dimmer="blurring" size="tiny" open={newRoomModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none', paddingLeft: 14, paddingBottom: 5 }}>
          {i18n.t('newRoom')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setRoomName('');
              setNewRoomModalOptions({ open: false });
            }}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content style={{ padding: 14 }}>
          <p style={{ fontWeight: 600, fontSize: 16 }}>Name</p>
          <Input
            placeholder="Name"
            fluid
            onChange={(e) => {
              setRoomName(e.target.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', minHeight: 65, backgroundColor: 'white' }}>
          <Button
            disabled={roomName.length === 0}
            content={i18n.t('create')}
            onClick={onCreateRoom}
            color="teal"
            size="large"
            style={{ marginLeft: 0 }}
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal dimmer="blurring" size="tiny" open={addTableModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setAddTableModalOptions({ open: false });
            }}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content style={{ padding: 10 }}>
          {tables.length > 0 && (
            <>
              <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
                {i18n.t('chooseTable')}
              </p>
              <div style={{ border: '1px solid #ccc', borderRadius: 15, padding: 5 }}>
                {tables.map((table, index) => (
                  <>
                    {rooms.find((room) =>
                      room.tables.find((rTable) => rTable.number === table.number)
                    ) == null && (
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          cursor: 'pointer',
                          padding: 15,
                        }}
                        onClick={() => {
                          const room = rooms[selectedRoomIndex];
                          table.x = 0;
                          table.y = 0;
                          table.w = 1;
                          table.h = 2;
                          table.minH = 2;
                          table.maxH = 4;
                          table.maxW = 4;
                          table.minW = 1;
                          room.tables.push(table);
                          rooms[selectedRoomIndex] = room;
                          setRooms([...rooms]);
                          setAddTableModalOptions({ open: false });
                        }}
                      >
                        <p>{`${table.description} (${table.number})`}</p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button
            style={{ marginLeft: 0 }}
            floated="right"
            content={i18n.t('createNewTable')}
            onClick={() => {
              setAddTableModalOptions({ open: false });
              setNewTableModalOptions({ open: true, table: false });
            }}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <TablesScreenModal
        open={newTableModalOptions.open}
        table={newTableModalOptions.table}
        onClose={onCloseNewTableModal}
        tableNumbers={tables.map((table) => table.number)}
      />
      <AnimatedModal dimmer="blurring" size="tiny" open={openDeleteTableModal.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('deleteTable')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setOpenDeleteTableModal({ open: false, item: null });
            }}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{i18n.t('deleteTableFromRoom')}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: 'white' }}>
          <Button
            content={i18n.t('deleteFromRoom')}
            onClick={() => onDeleteFromRoom(openDeleteTableModal.item)}
            color="teal"
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal dimmer="blurring" size="tiny" open={openDeleteRoomModal.open}>
        <Modal.Header style={{ borderBottom: 'white' }}>
          {i18n.t('deleteRoom')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setOpenDeleteRoomModal({ open: false });
            }}
            floated="right"
            circular
            size="big"
            className="tables-close-btn"
          />
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{i18n.t('deleteRoomWarning')}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: 'white' }}>
          <Button
            content={i18n.t('itemsDelete')}
            onClick={() => onDeleteRoom()}
            loading={isLoading}
            disabled={isLoading}
            color="red"
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
    </>
  );
};

export default TableOverviewModal;
