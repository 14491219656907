import { get, del } from './Fetch';

export const getOrCreateTSEExport = async (token, date, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}tse/export/${date}/${shopId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getTSEExportLink = async (token, exportId, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}tse/export/download/${exportId}/${shopId}`;
  const headers = { Authorization: token, 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    method: 'GET',
    headers,
  });
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', 'tse-export.tar');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  return { success: true, downloaded: true };
};

export const deleteTSEExport = async (token, month, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}tse/export/${month}/${shopId}`;
  const headers = { Authorization: token };
  return del(url, headers, {});
};
