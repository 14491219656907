/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, Dropdown, Form, Grid, Image, Input } from 'semantic-ui-react';
import { createTerminal, deleteTerminalReader } from '../../../api/PaymentAPI';
import {
  getAllShops,
  updateCardPaymentSetting,
  updateHardware,
  updateLogo,
  updateShop,
} from '../../../api/ShopAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import { asCurrency } from '../../../utils/NumberFormatter';
import './AddRemoveHardwareScreen.css';
import Toggle from '../../../components/general/Toggle/Toggle';
import i18n from '../../../i18n/config';

const AddRemoveHardwareScreen = () => {
  const [{ token, config }, dispatch] = useStateValue();
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shops, setShops] = useState([]);
  const [isShopsLoaded, setIsShopsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [isCardPaymentFromOtherAllowed, setIsCardPaymentFromOtherAllowed] = useState(
    selectedShop && selectedShop.isCardPaymentFromOtherAllowed != null
      ? selectedShop.isCardPaymentFromOtherAllowed
      : false
  );
  const [rentedHardware, setRentedHardware] = useState({
    iPads: [],
    posPrinters: [],
  });
  const [rentedTerminals, setRentedTerminals] = useState([]);
  const [newIpad, setNewIpad] = useState({
    model: '',
    pricePerMonth: 0,
    serialNumber: '',
  });
  const [newPosPrinter, setNewPosPrinter] = useState({
    model: '',
    pricePerMonth: 0,
    serialNumber: '',
  });
  const [newTerminalReader, setNewTerminalReader] = useState({
    registrationCode: '',
    pricePerMonth: 0,
    description: '',
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (dropDownOptions.length === 0 && isShopsLoaded === false) {
      getShops();
    }
    if (
      selectedShop &&
      selectedShop.isCardPaymentFromOtherAllowed !== isCardPaymentFromOtherAllowed
    ) {
      setIsCardPaymentFromOtherAllowed(selectedShop.isCardPaymentFromOtherAllowed);
    }
  });

  const onSave = async () => {
    setIsLoading(true);
    try {
      const update = {};
      update.iPads = rentedHardware.iPads.map((iPad) => ({
        model: iPad.model,
        pricePerMonth: iPad.pricePerMonth,
        serialNumber: iPad.serialNumber,
      }));
      update.posPrinters = rentedHardware.posPrinters.map((posPrinter) => ({
        model: posPrinter.model,
        pricePerMonth: posPrinter.pricePerMonth,
        serialNumber: posPrinter.serialNumber,
      }));
      const res = await updateHardware(token, update, selectedShop._id.toString());
      if (res && res.success) {
        setIsSaveDisabled(true);
      } else {
        alert('something went wrong');
      }
      setIsLoading(false);
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  const getShops = async () => {
    const res = await getAllShops(token);
    if (res && res.success === true) {
      setIsShopsLoaded(true);
      setShops(res.shops);
      setDropDownOptions(
        res.shops.map((shop) => ({
          key: shop._id.toString(),
          text: `${shop.name} ${shop._id.toString()}`,
          value: shop._id.toString(),
        }))
      );
      if (selectedShop) {
        setSelectedShop(
          res.shops.find((shop) => shop._id.toString() === selectedShop._id.toString())
        );
        setIsCardPaymentFromOtherAllowed(selectedShop.isCardPaymentFromOtherAllowed);
      }
    }
  };

  const onDeleteTerminal = async (readerId, token) => {
    setIsLoading(true);
    try {
      const res = await deleteTerminalReader(selectedShop._id.toString(), readerId, token);
      if (res && !res.success) {
        alert(`something went wrong. ${res.error}`);
      }
      setDropDownOptions([]);
      setIsLoading(false);
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  const onCreateTerminal = async (price, registrationCode, description) => {
    if (price.includes(',')) {
      price = price.replace(',', '.');
    }
    price = parseFloat(price) * 100;
    setIsLoading(true);
    try {
      const res = await createTerminal(
        selectedShop._id.toString(),
        registrationCode,
        price,
        description,
        token
      );
      if (res && !res.success) {
        alert(`something went wrong. ${res.error}`);
      }
      setDropDownOptions([]);
      setIsLoading(false);
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }

    setNewTerminalReader({ registrationCode: '', pricePerMonth: 0, description: '' });
  };

  const onFileUpload = async (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 500000) {
        alert(i18n.t('itemImageError'));
      } else {
        setIsSaveDisabled(false);
        setImage(URL.createObjectURL(e.target.files[0]));
        const reader = new FileReader();
        reader.onload = async (upload) => {
          setImageBase64(upload.target.result);
          const toUpload = upload.target.result;
          try {
            const result = await updateLogo(token, toUpload, selectedShop._id);
            if (result && result.success) {
              alert('funzt, aktualisieren bitte');
            } else {
              alert('FEHLER, try again loser');
            }
          } catch (error) {
            alert(`FEHLER: ${error}`);
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const parseRentalHardwareIPad = () => (
    <Form>
      {rentedHardware.iPads && rentedHardware.iPads.length > 0 ? (
        rentedHardware.iPads.map((iPad, index) => (
          <>
            <Form.Group className="items-specialSelections-group">
              <Form.Input
                placeholder="Modell"
                name={`${iPad.model}-${index}`}
                key={`${iPad.model}-${index}`}
                id={`${iPad.model}-${index}`}
                defaultValue={iPad.model}
                onChange={(e) => {
                  rentedHardware.iPads[index].model = e.target.value;
                }}
              />
              <CurrencyInput
                className="items-specialSelections-price"
                placeholder="Price Per Month"
                disableGroupSeparators
                allowNegativeValue={false}
                id={`${iPad.pricePerMonth}-${index}`}
                name={`${iPad.pricePerMonth}-${index}`}
                key={`${iPad.pricePerMonth}-${index}`}
                intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                defaultValue={`${iPad.pricePerMonth / 100}`}
                onValueChange={(value) => {
                  if (value) {
                    const val = parseFloat(value.replace(',', '.'));
                    rentedHardware.iPads[index].pricePerMonth = val * 100;
                  }
                }}
              />
              <Form.Input
                placeholder="Serial Number"
                name={`${iPad.serialNumber}-${index}`}
                key={`${iPad.serialNumber}-${index}`}
                id={`${iPad.serialNumber}-${index}`}
                defaultValue={iPad.serialNumber}
                onChange={(e) => {
                  rentedHardware.iPads[index].serialNumber = e.target.value;
                }}
              />
              <Form.Button
                basic
                color="red"
                icon="delete"
                key={`${iPad.model}-${index}3`}
                onClick={() => {
                  rentedHardware.iPads.splice(index, 1);
                  setRentedHardware(rentedHardware);
                  setNewIpad({ model: '', pricePerMonth: 0, serialNumber: '' });
                  setIsSaveDisabled(false);
                }}
              />
            </Form.Group>
          </>
        ))
      ) : (
        <></>
      )}
      <Form.Group className="items-specialSelections-group" key="specialSelection2">
        <Form.Input
          placeholder="Model"
          key="specialSelection3"
          name="model"
          value={newIpad.model}
          onChange={(e) => {
            setNewIpad({
              model: e.target.value,
              pricePerMonth: newIpad.pricePerMonth,
              serialNumber: newIpad.serialNumber,
            });
          }}
        />
        <CurrencyInput
          className="items-specialSelections-price"
          placeholder="Price per Month"
          name="price"
          key="specialSelection4"
          disableGroupSeparators
          allowNegativeValue={false}
          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
          onValueChange={(value) => {
            setNewIpad({
              model: newIpad.model,
              pricePerMonth: value,
              serialNumber: newIpad.serialNumber,
            });
          }}
          value={newIpad.pricePerMonth}
        />
        <Form.Input
          placeholder="Serial Number"
          key="specialSelection5"
          name="model"
          value={newIpad.serialNumber}
          onChange={(e) => {
            setNewIpad({
              serialNumber: e.target.value,
              pricePerMonth: newIpad.pricePerMonth,
              model: newIpad.model,
            });
          }}
        />
        <Form.Button
          color="green"
          basic
          key="specialSelection6"
          icon="add"
          disabled={!(newIpad.model && newIpad.pricePerMonth && newIpad.serialNumber)}
          onClick={() => {
            let { pricePerMonth } = newIpad;
            if (pricePerMonth.includes(',')) {
              pricePerMonth = pricePerMonth.replace(',', '.');
            }
            rentedHardware.iPads.push({
              model: newIpad.model,
              pricePerMonth: parseFloat(pricePerMonth) * 100,
              serialNumber: newIpad.serialNumber,
            });
            setRentedHardware(rentedHardware);
            setNewIpad({ model: '', pricePerMonth: 0, serialNumber: '' });
            setIsSaveDisabled(false);
          }}
        />
      </Form.Group>
    </Form>
  );

  const parseRentalHardwarePosPrinter = () => (
    <Form>
      {rentedHardware.posPrinters.length > 0 ? (
        rentedHardware.posPrinters.map((posPrinter, index) => (
          <>
            <Form.Group className="items-specialSelections-group">
              <Form.Input
                placeholder="Modell"
                name={`${posPrinter.model}-${index}`}
                key={`${posPrinter.model}-${index}`}
                id={`${posPrinter.model}-${index}`}
                defaultValue={posPrinter.model}
                onChange={(e) => {
                  rentedHardware.posPrinters[index].model = e.target.value;
                }}
              />
              <CurrencyInput
                className="items-specialSelections-price"
                placeholder="Price Per Month"
                disableGroupSeparators
                allowNegativeValue={false}
                id={`${posPrinter.pricePerMonth}-${index}`}
                name={`${posPrinter.pricePerMonth}-${index}`}
                key={`${posPrinter.pricePerMonth}-${index}`}
                intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                defaultValue={`${posPrinter.pricePerMonth / 100}`}
                onValueChange={(value) => {
                  if (value) {
                    const val = parseFloat(value.replace(',', '.'));
                    rentedHardware.posPrinters[index].pricePerMonth = val * 100;
                  }
                }}
              />
              <Form.Input
                placeholder="Serial Number"
                name={`${posPrinter.serialNumber}-${index}`}
                key={`${posPrinter.serialNumber}-${index}`}
                id={`${posPrinter.serialNumber}-${index}`}
                defaultValue={posPrinter.serialNumber}
                onChange={(e) => {
                  rentedHardware.posPrinters[index].serialNumber = e.target.value;
                }}
              />
              <Form.Button
                basic
                color="red"
                icon="delete"
                key={`${posPrinter.model}-${index}3`}
                onClick={() => {
                  rentedHardware.posPrinters.splice(index, 1);
                  setRentedHardware(rentedHardware);
                  setNewPosPrinter({ model: '', pricePerMonth: 0, serialNumber: '' });
                  setIsSaveDisabled(false);
                }}
              />
            </Form.Group>
          </>
        ))
      ) : (
        <></>
      )}
      <Form.Group className="items-specialSelections-group" key="specialSelection2">
        <Form.Input
          placeholder="Model"
          key="specialSelection99"
          name="model1"
          value={newPosPrinter.model}
          onChange={(e) => {
            setNewPosPrinter({
              model: e.target.value,
              pricePerMonth: newPosPrinter.pricePerMonth,
              serialNumber: newPosPrinter.serialNumber,
            });
          }}
        />
        <CurrencyInput
          className="items-specialSelections-price"
          placeholder="Price per Month"
          name="price1"
          key="specialSelection91"
          disableGroupSeparators
          allowNegativeValue={false}
          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
          onValueChange={(value) => {
            setNewPosPrinter({
              model: newPosPrinter.model,
              pricePerMonth: value,
              serialNumber: newPosPrinter.serialNumber,
            });
          }}
          value={newPosPrinter.pricePerMonth}
        />
        <Form.Input
          placeholder="Serial Number"
          key="specialSelection109"
          name="serialno1"
          value={newPosPrinter.serialNumber}
          onChange={(e) => {
            setNewPosPrinter({
              serialNumber: e.target.value,
              pricePerMonth: newPosPrinter.pricePerMonth,
              model: newPosPrinter.model,
            });
          }}
        />
        <Form.Button
          color="green"
          basic
          key="specialSelection119"
          icon="add"
          disabled={
            !(newPosPrinter.model && newPosPrinter.pricePerMonth && newPosPrinter.serialNumber)
          }
          onClick={() => {
            let { pricePerMonth } = newPosPrinter;
            if (pricePerMonth.includes(',')) {
              pricePerMonth = pricePerMonth.replace(',', '.');
            }
            rentedHardware.posPrinters.push({
              model: newPosPrinter.model,
              pricePerMonth: parseFloat(pricePerMonth) * 100,
              serialNumber: newPosPrinter.serialNumber,
            });
            setRentedHardware(rentedHardware);
            setNewPosPrinter({ model: '', pricePerMonth: 0, serialNumber: '' });
            setIsSaveDisabled(false);
          }}
        />
      </Form.Group>
    </Form>
  );

  const parseTerminalReaders = () => (
    <>
      <div style={{ display: 'flex', gap: 50 }}>
        <p>Other Card Payment Service</p>
        <Toggle
          style={{ position: 'relative' }}
          checked={isCardPaymentFromOtherAllowed}
          onChange={async () => {
            if (
              selectedShop &&
              (selectedShop.terminalReaders == null || selectedShop.terminalReaders.length === 0)
            ) {
              const res = await updateCardPaymentSetting(
                token,
                !isCardPaymentFromOtherAllowed,
                selectedShop._id.toString()
              );
              if (res && res.success) {
                setIsCardPaymentFromOtherAllowed(!isCardPaymentFromOtherAllowed);
                await getShops();
              }
            }
          }}
        />
      </div>
      {selectedShop && selectedShop.terminalReaders && selectedShop.terminalReaders.length > 0 ? (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {selectedShop.terminalReaders.map((terminalReader) => (
            <>
              <div
                style={{ width: '40%', border: '1px solid #7268cdaa', padding: 5, borderRadius: 8 }}
              >
                <p>{`Description: ${terminalReader.description}`}</p>
                <p>{`Registration Code: ${terminalReader.registrationCode}`}</p>
                <p>{`Serial Number: ${terminalReader.serialNumber}`}</p>
                <p>{`Device Type: ${terminalReader.deviceType}`}</p>
                <p>{`Reader ID: ${terminalReader.readerId}`}</p>
                <p>{`Location ID: ${terminalReader.locationId}`}</p>
                <p>{`Price Per Month: ${`${asCurrency(terminalReader.pricePerMonth / 100)}€`}`}</p>
                <Button
                  loading={isLoading}
                  fluid
                  content="Delete"
                  color="red"
                  onClick={() => onDeleteTerminal(terminalReader.readerId, token)}
                  disabled={isLoading || !selectedShop}
                />
              </div>
            </>
          ))}
        </div>
      ) : (
        <></>
      )}
      <br />
      <p style={{ fontWeight: 600 }}>New Terminal Reader</p>
      <Form>
        <Form.Group className="items-specialSelections-group" key="specialSelection2">
          <Form.Input
            placeholder="Description"
            key="description1"
            name="description"
            value={newTerminalReader.description}
            onChange={(e) => {
              setNewTerminalReader({
                registrationCode: newTerminalReader.registrationCode,
                pricePerMonth: newTerminalReader.pricePerMonth,
                description: e.target.value,
              });
            }}
          />
          <Form.Input
            placeholder="Registration Code"
            key="registrationcode1"
            name="registrationcode"
            value={newTerminalReader.registrationCode}
            onChange={(e) => {
              setNewTerminalReader({
                registrationCode: e.target.value,
                pricePerMonth: newTerminalReader.pricePerMonth,
                description: newTerminalReader.description,
              });
            }}
          />
          <CurrencyInput
            className="items-specialSelections-price"
            placeholder="Price per Month"
            name="price"
            key="specialSelection4"
            disableGroupSeparators
            allowNegativeValue={false}
            intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
            onValueChange={(value) => {
              setNewTerminalReader({
                registrationCode: newTerminalReader.registrationCode,
                pricePerMonth: value,
                description: newTerminalReader.description,
              });
            }}
            value={newTerminalReader.pricePerMonth}
          />
          <Form.Button
            color="green"
            basic
            key="specialSelection6"
            content="Create"
            disabled={
              !(
                newTerminalReader.registrationCode &&
                newTerminalReader.pricePerMonth &&
                newTerminalReader.description
              ) || isLoading
            }
            onClick={() => {
              const { pricePerMonth, registrationCode, description } = newTerminalReader;
              onCreateTerminal(pricePerMonth, registrationCode, description);
            }}
          />
        </Form.Group>
      </Form>
    </>
  );

  const parsePrinterLogo = () => (
    <>
      <label htmlFor="file" style={{ display: 'table', margin: '0 auto' }}>
        <Image
          src={
            image ||
            (selectedShop && selectedShop.logo
              ? `${config.cloudinaryUrlPrefix}${selectedShop.logo}`
              : null)
          }
          rounded
          size="medium"
          wrapped
          className="items-modal-image"
          id="items-modal-image"
          htmlFor="file"
          style={{ border: '1px solid #7268cd' }}
        />
        <input type="file" id="file" onChange={onFileUpload} accept="image/*" />
      </label>
    </>
  );

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">Hardware</p>
      </div>
      <div
        style={{
          height: 80,
          backgroundColor: 'white',
          margin: '43px 14px 0px 44px',
          verticalAlign: 'center',
          borderRadius: 24,
          boxShadow: 'rgb(114 104 205 / 20%) 0px 3px 7px',
          padding: 20,
        }}
      >
        <Dropdown
          selection
          clearable
          fluid
          placeholder="Select Shop"
          options={dropDownOptions}
          onChange={(_, data) => {
            if (data.value) {
              const tmpShop = shops.filter((shop) => shop._id.toString() === data.value)[0];
              setSelectedShop(tmpShop);
              if (tmpShop.rentedHardware) {
                setRentedHardware(tmpShop.rentedHardware);
              } else {
                setRentedHardware({
                  iPads: [],
                  posPrinters: [],
                });
              }
            } else {
              setSelectedShop(null);
              setRentedHardware({ iPads: [], posPrinters: [] });
            }
          }}
        />
      </div>

      <div className="add-hardware-container">
        <p style={{ fontSize: 17, fontWeight: 700 }}>Rental Hardware</p>
        <br />
        <p style={{ fontWeight: 500 }}>Rental iPads</p>
        {parseRentalHardwareIPad()}
        <br />
        <p style={{ fontWeight: 500 }}>Rental POS Printers</p>
        {parseRentalHardwarePosPrinter()}
        <br />
        <Button
          loading={isLoading}
          fluid
          content="Save"
          color="teal"
          onClick={onSave}
          disabled={isLoading || isSaveDisabled || !selectedShop}
        />
      </div>
      <div className="add-hardware-container">
        <p style={{ fontSize: 17, fontWeight: 700 }}>Terminal Readers</p>
        {parseTerminalReaders()}
      </div>
      <div className="add-hardware-container">
        <p style={{ fontSize: 17, fontWeight: 700 }}>Printer Logo</p>
        {parsePrinterLogo()}
      </div>
      <div style={{ marginBottom: 180 }} />
    </>
  );
};

export default AddRemoveHardwareScreen;
