/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { Button, Loader, Modal } from 'semantic-ui-react';
import i18n from 'i18next';
import './PinNumPad.css';
import AnimatedModal from '../../general/AnimatedModal/AnimatedModal';

const PinNumPad = (props) => {
  const { open, onClose, onSendPin } = props;
  const [pin, setPin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setPin([]);
    setIsLoading(false);

    onClose();
  };

  const addToPin = (num) => {
    if (pin.length < 4) {
      pin.push(num);
      setPin([...pin]);
    }
  };

  useEffect(() => {
    if (open === false && isLoading === true) {
      setIsLoading(false);
    }
  });

  if (open === true) {
    return (
      <>
        <AnimatedModal
          open={open === true}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{ borderBottom: 'none', padding: 12, backgroundColor: '#F5F5F9', height: 42 }}
          >
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal()}
              floated="right"
              className="settings-close"
              circular
              disabled={isLoading === true}
            />
          </Modal.Header>
          <Modal.Content scrolling style={{ background: '#f5f5f9' }}>
            {isLoading === true ? (
              <>
                <div style={{ height: 200 }}>
                  <Loader active={isLoading === true} inverted className="bookings-create-loader" />
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="pin-input-container">
                    <div className="pin-input">{pin.length > 0 ? pin[0] : ''}</div>
                    <div className="pin-input">{pin.length > 1 ? pin[1] : ''}</div>
                    <div className="pin-input">{pin.length > 2 ? pin[2] : ''}</div>
                    <div className="pin-input">{pin.length > 3 ? pin[3] : ''}</div>
                  </div>
                  <div className="pin-numpad-container">
                    <div className="pin-numpad-line">
                      <div className="pin-numpad-num" onClick={() => addToPin(1)}>
                        1
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(2)}>
                        2
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(3)}>
                        3
                      </div>
                    </div>
                    <div className="pin-numpad-line">
                      <div className="pin-numpad-num" onClick={() => addToPin(4)}>
                        4
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(5)}>
                        5
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(6)}>
                        6
                      </div>
                    </div>
                    <div className="pin-numpad-line">
                      <div className="pin-numpad-num" onClick={() => addToPin(7)}>
                        7
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(8)}>
                        8
                      </div>
                      <div className="pin-numpad-num" onClick={() => addToPin(9)}>
                        9
                      </div>
                    </div>
                    <div className="pin-numpad-line">
                      <div className="pin-numpad-num" onClick={() => addToPin(0)}>
                        0
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                  <div
                    className={pin.length > 0 ? 'pin-delete-button' : 'pin-delete-button-disabled'}
                    onClick={() => {
                      if (pin.length > 0) {
                        setPin([]);
                      }
                    }}
                  >
                    {i18n.t('delete')}
                  </div>
                  <div
                    className={pin.length === 4 ? 'pin-send-button' : 'pin-send-button-disabled'}
                    onClick={() => {
                      if (pin.length === 4) {
                        onSendPin(pin.join(''));
                        setPin([]);
                        setIsLoading(true);
                      }
                    }}
                  >
                    {i18n.t('send')}
                  </div>
                </div>
              </>
            )}
          </Modal.Content>
        </AnimatedModal>
      </>
    );
  }
  return <></>;
};

export default PinNumPad;
