/* eslint-disable new-cap */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Input, Popup } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { updateStripeTerminal } from '../../../api/PaymentAPI';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const TerminalReaderModal = (props) => {
  const { onClose, open, terminal, categories } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isDataReset, setIsDataReset] = useState(false);
  const [terminalData, setTerminalData] = useState({
    description: '',
    registrationCode: '',
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (!terminal && !isDataReset) {
      setTerminalData({
        description: '',
        registrationCode: '',
      });
      setIsDataReset(true);
    }
    if (terminal) {
      setTerminalData({
        description: terminal.description,
        registrationCode: terminal.registrationCode,
      });
    }
  }, [terminal]);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    setIsLoading(true);
    if (terminalData.registrationCode && terminalData.description) {
      if (terminal) {
        const data = {};
        data.description = terminalData.description;
        data.registrationCode = terminalData.registrationCode;
        const res = await updateStripeTerminal(terminal.readerId, shop._id.toString(), data, token);
        if (res && res.success) {
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('regCardError'),
          });
        }
      }
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('regCardError2'),
      });
      setIsLoading(false);
    }
  };

  const closeModal = (refreshNeeded) => {
    const newTerminalData = {
      description: '',
      registrationCode: '',
    };
    setTerminalData({ ...newTerminalData });
    setIsLoading(false);
    setIsSaveDisabled(true);
    setIsDataReset(false);
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="mini" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none' }}>
          {terminal ? terminal.description : i18n.t('newCardReader')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ overflowX: 'hidden' }}>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600 }}>{i18n.t('newPRinterDescription')}</p>
              <Input
                placeholder={i18n.t('newPRinterDescription')}
                name="description"
                defaultValue={terminalData.description}
                onChange={(e) => {
                  setTerminalData({
                    description: e.target.value,
                    registrationCode: terminalData.registrationCode,
                  });
                  setIsSaveDisabled(false);
                }}
                className="printer-input"
              />
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600 }}>
                {i18n.t('regCode')}
                <Popup
                  on={['hover', 'click']}
                  // eslint-disable-next-line prettier/prettier
                  trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                  content={i18n.t('regCodeDesc')}
                  popper={<div style={{ filter: 'none' }} />}
                />
              </p>
              <Input
                placeholder={i18n.t('regCode')}
                name="regcode"
                defaultValue={terminalData.registrationCode}
                onChange={(e) => {
                  setTerminalData({
                    description: terminalData.description,
                    registrationCode: e.target.value,
                  });
                  setIsSaveDisabled(false);
                }}
                className="printer-input"
              />
            </div>
          </div>
          {terminal ? (
            <>
              <br />
              <p style={{ fontWeight: 600 }}>Location ID</p>
              <p>{terminal.locationId}</p>
              <p style={{ fontWeight: 600 }}>Reader ID</p>
              <p>{terminal.readerId}</p>
              <p style={{ fontWeight: 600 }}>{i18n.t('serialNo')}</p>
              <p>{terminal.serialNumber}</p>
              <p style={{ fontWeight: 600 }}>Configuration ID</p>
              <p>{terminal.configurationId}</p>
              <p style={{ fontWeight: 600 }}>{i18n.t('deviceType')}</p>
              <p>{terminal.deviceType}</p>
            </>
          ) : (
            <></>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default TerminalReaderModal;
