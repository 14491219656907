import i18n from 'i18next';
import bookingsTodayIcon from '../../shop/Dashboard/Icons/bookings-today.png';
import bookingsTotalIcon from '../../shop/Dashboard/Icons/bookings-total.png';
import revenueTodayIcon from '../../shop/Dashboard/Icons/revenue-today.png';
import revenueTotalIcon from '../../shop/Dashboard/Icons/revenue-total.png';
import revenueChartIcon from '../../shop/Dashboard/Icons/revenue-chart.png';
import busyTimesIcon from '../../shop/Dashboard/Icons/busy-times.png';
import cardvscashIcon from '../../shop/Dashboard/Icons/cardvscash.png';
import topTenIcon from '../../shop/Dashboard/Icons/top-ten.png';
import {
  getFranchiseBookingsOfThisMonth,
  getFranchiseBookingsOfToday,
  getFranchiseBookingTimes,
  getFranchiseCardVSCash,
  getFranchiseDailyPerMonth,
  getFranchiseRevenueOfThisMonth,
  getFranchiseRevenueOfToday,
  getFranchiseTopTenBookedItems,
} from '../../../api/FranchiseAPI';

export const bookingsTodayProps = {
  title: i18n.t('dashBoardBookingsTodayTitle'),
  imageBackground: '#2ECC71',
  image: bookingsTodayIcon,
  descriptor: i18n.t('dashBoardYesterday'),
};

export const bookingsThisMonthProps = {
  title: i18n.t('dashBoardBookingsThisMonthTitle'),
  imageBackground: '#3498DB',
  image: bookingsTotalIcon,
  descriptor: i18n.t('dashBoardLastMonth'),
};

export const revenueTodayProps = {
  title: i18n.t('dashBoardRevenueTodayTitle'),
  imageBackground: '#2ECC71',
  image: revenueTodayIcon,
  descriptor: i18n.t('dashBoardYesterday'),
};

export const revenueMonthProps = {
  title: i18n.t('dashBoardRevenueThisMonthTitle'),
  imageBackground: '#3498DB',
  image: revenueTotalIcon,
  descriptor: i18n.t('dashBoardLastMonth'),
};

export const dailyRevenuePerMonthProps = {
  title: i18n.t('dashBoardRevenueTitle'),
  imageBackground: '#f49080',
  image: revenueChartIcon,
};

export const timesOfBookingProps = {
  title: i18n.t('dashBoardBookingTimesTitle'),
  imageBackground: '#63a4ff',
  image: busyTimesIcon,
};

export const topTenProps = {
  title: i18n.t('dashBoardTopTenItemsTitle'),
  imageBackground: '#a291d9',
  image: topTenIcon,
};

export const cardVsCashProps = {
  title: i18n.t('dashBoardPaymentMethodsTitle'),
  imageBackground: '#97caef',
  image: cardvscashIcon,
};

export const getFranchiseBookingsToday = async (shop, token) => {
  try {
    const res = await getFranchiseBookingsOfToday(shop, token);
    if (res && res.success) {
      return { value: res.bookingsTodayCount, growth: res.difference };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getFranchiseBookingsThisMonth = async (shop, token) => {
  try {
    const res = await getFranchiseBookingsOfThisMonth(shop, token);
    if (res && res.success) {
      return { value: res.bookingsThisMonthCount, growth: res.difference };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getFranchiseRevenueToday = async (shop, token) => {
  try {
    const res = await getFranchiseRevenueOfToday(shop, token);
    if (res && res.success) {
      return { value: res.revenueToday / 100, growth: res.difference };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getFranchiseRevenueThisMonth = async (shop, token) => {
  try {
    const res = await getFranchiseRevenueOfThisMonth(shop, token);
    if (res && res.success) {
      return { value: res.revenueThisMonth / 100, growth: res.difference };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getTimesOfBooking = async (shop, token) => {
  try {
    const res = await getFranchiseBookingTimes(shop, token);
    if (res && res.success) {
      return {
        labels: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
        ],
        data: res.hourlyBookings,
        legendTitle: i18n.t('dashBoardBookingTimesSubtitle'),
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getTopTen = async (shop, token) => {
  try {
    const res = await getFranchiseTopTenBookedItems(shop, token);
    if (res && res.success) {
      return {
        labels: res.topTen.filter((array) => array[0] !== undefined).map((array) => array[0]),
        data: res.topTen,
        legendTitle: i18n.t('dashBoardTopTenItemsTitleSubTitle'),
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getCardvsCash = async (shop, token) => {
  try {
    const res = await getFranchiseCardVSCash(shop, token);
    if (res && res.success) {
      return {
        labels: [
          i18n.t('dashBoardPaymentMethodsSubTitleRight'),
          i18n.t('dashBoardPaymentMethodsSubTitleLeft'),
        ],
        data: [res.cashBookings, res.cardBookings],
        leftLegendTitle: i18n.t('dashBoardPaymentMethodsSubTitleRight'),
        rightLegendTitle: i18n.t('dashBoardPaymentMethodsSubTitleLeft'),
        leftLegendValue: res.cashBookingsAmount / 100,
        rightLegendValue: res.cardBookingsAmount / 100,
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getKPIs = async (token, shop) => {
  const bookingsToday = await getFranchiseBookingsToday(shop, token);
  const bookingsThisMonth = await getFranchiseBookingsThisMonth(shop, token);
  const revenueToday = await getFranchiseRevenueToday(shop, token);
  const revenueThisMonth = await getFranchiseRevenueThisMonth(shop, token);
  return { bookingsToday, bookingsThisMonth, revenueToday, revenueThisMonth };
};

export const getDailyRevenuePerMonth = async (shop, token) => {
  try {
    const res = await getFranchiseDailyPerMonth(shop, token);
    if (res && res.success) {
      return {
        data: [res.thisMonth, res.lastMonth],
        labels: Array.from({ length: 31 }, (_, i) => i + 1),
        gradientColors: '',
        leftLegendTitle: i18n.t('dashBoardRevenueSubTitleLeft'),
        leftLegendValue: res.revenueThisMonth / 100,
        rightLegendTitle: i18n.t('dashBoardRevenueSubTitleRight'),
        rightLegendValue: res.revenueLastMonth / 100,
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};
