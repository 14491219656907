/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown, Popup, Icon, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { isEqual, countBy, findLastIndex, cloneDeep, sortBy } from 'lodash';
import { asCurrency } from '../../../utils/NumberFormatter';
import './BookingsScreen.css';
import { useStateValue } from '../../../core/context/StateProvider';
import { Printer } from '../../../utils/Printer';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { showToast } from '../../../components/general/Toast/Toast';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import { getBookingHistory } from '../../../api/BookingAPI';
import Timeline from '../../../components/shop/TimeLine/TimeLine';

const BookingsScreenModal = (props) => {
  const [{ shop, user, token }, dispatch] = useStateValue();
  const {
    booking,
    open,
    onClose,
    onRefund,
    onChangeStatus,
    onSaveEditing,
    closeEditMode,
    onPayLater,
    onSaveSplitting,
    onSaveEditingTable,
    onAcceptingRequest,
    onDecliningRequest,
    logoCanvasContext,
    openMode, // main | edit | history | split
  } = props;
  const [isPrinterAvailable, setIsPrinterAvailable] = useState(
    !!(shop.printers && shop.printers.length !== 0)
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isSplitting, setIsSplitting] = useState(false);
  const [isEditingDisabledOnce, setIsEditingDisabledOnce] = useState(false);
  const [isEditingModeSaveEnabled, setIsEditingModeSaveEnabled] = useState(false);
  const [bookedItemsCopy, setBookedItemsCopy] = useState([]);
  const [refundedBookedItemIds, setRefundedBookedItemIds] = useState([]);
  const [modalBooking, setModalBooking] = useState(null);
  const [newBookedItems, setNewBookedItems] = useState([]);
  const [oldBookedItems, setOldBookedItems] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isSplittingModeSaveEnabled, setIsSplittingModeSaveEnabled] = useState(false);
  const [isShowingHistory, setIsShowingHistory] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const statusColors = {
    closed: 'green',
    open: 'orange',
    refund: 'crimson',
    cancelled: 'red',
  };

  useEffect(() => {
    if (booking && modalBooking == null) {
      setModalBooking(booking);
    }
    if (
      open === true &&
      booking &&
      booking.table &&
      booking.table.tableNumber &&
      selectedTable === null
    ) {
      setSelectedTable(booking.table.tableNumber);
    }
    if (closeEditMode === true && isEditing === true && isEditingDisabledOnce === false) {
      setIsEditing(false);
      setIsEditingDisabledOnce(true);
    }
    if (closeEditMode === true && refundedBookedItemIds.length !== 0) {
      setRefundedBookedItemIds([]);
      setIsEditing(false);
    }
    if (open === false && newBookedItems.length > 0) {
      setNewBookedItems([]);
      setIsSplittingModeSaveEnabled(false);
    }
    if (open === false && oldBookedItems.length > 0) {
      setOldBookedItems([]);
      setIsSplittingModeSaveEnabled(false);
    }
    if (open === false && isSplitting === true) {
      setIsSplitting(false);
      setIsSplittingModeSaveEnabled(false);
    }
    if (open === false && isEditing === true) {
      setIsEditing(false);
      setIsEditingModeSaveEnabled(false);
    }
    if (open === false && selectedTable !== null) {
      setSelectedTable(null);
    }
  });

  useEffect(() => {
    if (open === true && isShowingHistory === true) {
      loadHistory();
    }
  }, [isShowingHistory]);

  const closeModal = (refreshNeeded) => {
    setIsEditingDisabledOnce(false);
    setIsEditing(false);
    setIsSplitting(false);
    setIsEditingModeSaveEnabled(false);
    setBookedItemsCopy([]);
    setRefundedBookedItemIds([]);
    setModalBooking(null);
    setIsSplittingModeSaveEnabled(false);
    setSelectedTable(null);
    setIsShowingHistory(false);
    setIsLoadingHistory(false);
    setHistory([]);
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
    setErrorWarningModalOptions({ open: false, message: '' });
    if (isEditingModeSaveEnabled) {
      onClose(true);
    } else {
      onClose(refreshNeeded);
    }
  };

  const loadHistory = async () => {
    setIsLoadingHistory(true);
    try {
      const res = await getBookingHistory(token, booking._id);
      if (res && res.success) {
        setHistory(res.history);
      } else {
        setIsShowingHistory(false);
        setErrorWarningModalOptions({ open: true, message: i18n.t('historyLoadingError') });
      }
      setIsLoadingHistory(false);
    } catch (error) {
      setIsLoadingHistory(false);
      setIsShowingHistory(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('historyLoadingError') });
    }
  };

  const onSaveEdit = async () => {
    if (refundedBookedItemIds.length > 0) {
      const newBooking = { ...booking, bookedItems: refundedBookedItemIds };
      await onSaveEditing(newBooking);
    }
  };

  const onSaveEditTable = async () => {
    if (
      booking &&
      booking.table &&
      booking.table.tableNumber &&
      selectedTable &&
      selectedTable !== booking.table.tableNumber
    ) {
      await onSaveEditingTable(booking._id, selectedTable);
    }
  };

  const onSaveSplit = async () => {
    onSaveSplitting(booking._id, oldBookedItems, newBookedItems);
  };

  const onDeleteBookedItem = (index) => {
    const refunded = booking.bookedItems[index]._id;
    setRefundedBookedItemIds([...refundedBookedItemIds, refunded]);
    booking.bookedItems[index].isRefund = true;
    setBookedItemsCopy(...booking.bookedItems);
    setIsEditingModeSaveEnabled(true);
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printBookingsModalError${printerDevice.printer.description}`,
    });
  };

  const printBooking = async (booking) => {
    if (shop.printPerCategory === true) {
      const printerItemsMap = {};
      for (let i = 0; i < booking.bookedItems.length; i++) {
        const item = booking.bookedItems[i];
        const { categoryName } = item;
        if (
          shop.rooms &&
          shop.rooms.length > 0 &&
          booking.table != null &&
          booking.table.tableNumber != null
        ) {
          const room = shop.rooms.find((room) =>
            room.tables.find((table) => table.number === booking.table.tableNumber)
          );
          const shopPrinter = room
            ? shop.printers.find(
                (printer) =>
                  (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
                  (printer.categories.includes(categoryName) ||
                    (printer.categories.length === 1 && printer.categories[0] === 'all'))
              )
            : null;

          if (room && shopPrinter) {
            const keyName = shopPrinter.description;
            // eslint-disable-next-line no-prototype-builtins
            if (printerItemsMap.hasOwnProperty(keyName)) {
              printerItemsMap[keyName] = {
                items: [...printerItemsMap[keyName].items, item],
                room: room.name,
              };
            } else {
              printerItemsMap[keyName] = { items: [item], room: room.name };
            }
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: `${i18n.t('failedPrintJobsError3part1')}${booking.bookingId}${i18n.t(
                'failedPrintJobsError3part2'
              )}${categoryName}${i18n.t('failedPrintJobsError3part3')}`,
            });
          }
        } else {
          const shopPrinter = shop.printers.find(
            (printer) =>
              printer.categories.includes(categoryName) ||
              (printer.categories.length === 1 && printer.categories[0] === 'all')
          );
          if (shopPrinter) {
            const keyName = shopPrinter.description;
            // eslint-disable-next-line no-prototype-builtins
            if (printerItemsMap.hasOwnProperty(keyName)) {
              printerItemsMap[keyName] = {
                items: [...printerItemsMap[keyName].items, item],
                room: null,
              };
            } else {
              printerItemsMap[keyName] = { items: [item], room: null };
            }
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: `${i18n.t('failedPrintJobsError3part1')}${booking.bookingId}${i18n.t(
                'failedPrintJobsError3part2'
              )}${categoryName}${i18n.t('failedPrintJobsError3part3')}`,
            });
          }
        }
      }

      for (let i = 0; i < Object.keys(printerItemsMap).length; i++) {
        const printerDescription = Object.keys(printerItemsMap)[i];
        const { items, room } = printerItemsMap[printerDescription];
        const printer = shop.printers.find((printer) => printer.description === printerDescription);
        if (printer) {
          const printerDevice = new Printer(printer);
          printerDevice.epos.onerror = () => showPrinterError(printerDevice);
          printerDevice.printCategory(items, booking, room, !!shop.autoPrint);
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => setTimeout(resolve, 1500));
        }
      }
    } else if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      const shopPrinter = room
        ? shop.printers.find(
            (printer) => printer.rooms.includes(room.name) || printer.rooms.includes('all')
          )
        : null;
      if (room && shopPrinter) {
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const newBooking = cloneDeep(booking);
        printerDevice.printInternal(newBooking, room.name, !!shop.autoPrint);
        await new Promise((resolve) => setTimeout(resolve, 2500));
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('failedPrintJobsError2'),
        });
      }
    } else if (shop.printers[0]) {
      printInternal(booking);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('failedPrintJobsError1'),
      });
    }
  };

  const printInternal = async (booking, roomName) => {
    const printerDevice = new Printer(shop.printers[0]);
    printerDevice.epos.onerror = () => showPrinterError(printerDevice);
    printerDevice.printInternal(booking, roomName, !!shop.autoPrint);
    await new Promise((resolve) => setTimeout(resolve, 2500));
  };

  const printCustomerBon = (booking, isBew, isZwisch) => {
    if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      if (
        room &&
        shop.printers.find(
          (printer) =>
            (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
            printer.isMain === true
        )
      ) {
        const shopPrinter = shop.printers.find(
          (printer) =>
            (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
            printer.isMain === true
        );
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
        printerDevice.printCustomer(booking, shop, paperSize, isBew, isZwisch, logoCanvasContext);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('noReceiptPrinterForRoomError'),
        });
      }
    } else if (shop.printers.find((printer) => printer.isMain === true)) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      const paperSize =
        shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
      printerDevice.printCustomer(booking, shop, paperSize, isBew, isZwisch, logoCanvasContext);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('noReceiptPrinterError'),
      });
    }
  };

  const printCustomerRefundBon = (booking, isBew) => {
    if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      if (
        room &&
        shop.printers.find(
          (printer) =>
            (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
            printer.isMain === true
        )
      ) {
        const shopPrinter = shop.printers.find(
          (printer) =>
            (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
            printer.isMain === true
        );
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
        printerDevice.printRefundCustomer(booking, shop, paperSize, logoCanvasContext);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('noReceiptPrinterForRoomError'),
        });
      }
    } else if (shop.printers.find((printer) => printer.isMain === true)) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      const paperSize =
        shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
      printerDevice.printRefundCustomer(booking, shop, paperSize, logoCanvasContext);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('noReceiptPrinterError'),
      });
    }
  };

  const getBookedItemAmount = (item) => {
    if (item.discount && item.discount.amount > 0) {
      return Math.round(item.amount) - Math.round(item.discount.amount);
    }
    if (item.discount && item.discount.percentage > 0) {
      return Math.round(item.amount * (1 - item.discount.percentage));
    }
    return item.amount;
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const bookedItems = (bookedItems, paymentType) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return (
      <>
        {bookedItems.map((item, index) => (
          <Fragment key={`frag${index}`}>
            {index ===
            findLastIndex(cleanedUpBookedItems, (element) =>
              isEqual(element, cleanedUpBookedItems[index])
            ) ? (
              <>
                <li
                  key={`${item.itemId}-${index}`}
                  style={{
                    width: '100%',
                    fontWeight: 500,
                    marginBottom: 5,
                    whiteSpace: 'initial',
                    wordBreak: 'break-all',
                  }}
                >
                  <span style={{ fontWeight: 700 }}>
                    {`${countElement(bookedItems, index)} × `}
                  </span>
                  {item.itemTitle}
                  {item.isRefund && (item.isCancelled === true || paymentType === 'later') && (
                    <>
                      <span
                        style={{
                          marginLeft: 4,
                          color: 'red',
                          border: `solid 1px red`,
                          borderRadius: '8px',
                          padding: 2,
                          paddingBottom: 2,
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          textAlign: 'center',
                          fontWeight: '700',
                          fontSize: '12px',
                        }}
                      >
                        {i18n.t('bookingsStatusStorniert')}
                      </span>
                    </>
                  )}
                  {item.isRefund &&
                    paymentType !== 'later' &&
                    (item.isCancelled === false || item.isCancelled == null) && (
                      <>
                        <span
                          style={{
                            marginLeft: 4,
                            color: 'red',
                            border: `solid 1px red`,
                            borderRadius: '8px',
                            padding: 2,
                            paddingBottom: 2,
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            textAlign: 'center',
                            fontWeight: '700',
                            fontSize: '12px',
                          }}
                        >
                          {i18n.t('bookingsRefunded').toUpperCase()}
                        </span>
                      </>
                    )}
                  <span style={{ textAlign: 'right', float: 'right', fontWeight: 500 }}>
                    {`${asCurrency(
                      (getBookedItemAmount(item) * countElement(bookedItems, index)) / 100
                    )} €`}
                    {item.discount && (item.discount.percentage > 0 || item.discount.amount > 0) ? (
                      <Popup
                        on={['hover', 'click']}
                        basic
                        // eslint-disable-next-line prettier/prettier
                        trigger={<p style={{ marginLeft: 6, height: 18, width: 18, border: '1px solid #7268cd', display: 'inline-block', borderRadius: 250, textAlign: 'center', fontSize: 12, color: '#7268cd', fontWeight: 800}}>%</p>}
                        // eslint-disable-next-line prettier/prettier
                        content={(
                          <>
                            <p>
                              {i18n.t('originalPrice')}: {`${asCurrency(item.amount / 100)}`}€
                            </p>
                            <p>
                              {i18n.t('discount')}:
                              {item.discount.percentage > 0
                                ? ` ${parseInt(item.discount.percentage * 100, 10)}%`
                                : ` ${asCurrency(item.discount.amount / 100)}€`}
                            </p>
                          </>
                          // eslint-disable-next-line prettier/prettier
                        )}
                        position="bottom right"
                        popper={<div style={{ filter: 'none' }} />}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                </li>
                <ul style={{ position: 'relative', left: 15, width: '95%', marginBottom: 10 }}>
                  {item.notes ? (
                    <>
                      <li
                        style={{
                          fontStyle: 'italic',
                          listStyleType: 'none',
                          wordBreak: 'break-all',
                        }}
                        key={`${item.itemId}/${index}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">H</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${item.notes}`}
                          </p>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {item.options && item.options.length > 0 ? (
                    item.options.map((option, idx) => (
                      <li
                        style={{
                          fontStyle: 'italic',
                          listStyleType: 'none',
                          wordBreak: 'break-all',
                        }}
                        key={`${item.itemId}/${idx}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">O</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                  {item.specialSelections.map((specialSelection, tIndex) => (
                    <li
                      style={{
                        fontStyle: 'italic',
                        listStyleType: 'none',
                        wordBreak: 'break-all',
                      }}
                      key={`${item.itemId}/${tIndex}`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p className="bookings-extras-options-indicator">E</p>
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {` ${specialSelection.specialSelectionTitle}`}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </>
    );
  };

  const parseEditableBookedItems = (bookedItems, paymentType) => (
    <>
      {bookedItems.map((item, index) => (
        <Fragment key={`${index}-editable`}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 40,
              gap: 10,
            }}
          >
            {!item.isRefund ? (
              <>
                <div
                  onClick={() => onDeleteBookedItem(index)}
                  style={{
                    color: 'red',
                    fontSize: 21,
                    fontWeight: 500,
                    cursor: 'pointer',
                    border: '1px solid red',
                    borderRadius: 15,
                    width: 30,
                    height: 30,
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  ×
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={{
                marginTop: 2,
                flex: 1,
                fontSize: 17,
                fontWeight: 500,
                display: 'flex',
                gap: 5,
                wordBreak: 'break-all',
              }}
            >
              {item.itemTitle}
              {item.isRefund && (item.isCancelled === true || paymentType === 'later') && (
                <>
                  <div>
                    <span
                      style={{
                        color: 'red',
                        border: `solid 1px red`,
                        borderRadius: '8px',
                        padding: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '12px',
                        wordBreak: 'normal',
                      }}
                    >
                      {i18n.t('bookingsStatusStorniert')}
                    </span>
                  </div>
                </>
              )}
              {item.isRefund &&
                paymentType !== 'later' &&
                (item.isCancelled === false || item.isCancelled == null) && (
                  <>
                    <div>
                      <span
                        style={{
                          color: 'red',
                          border: `solid 1px red`,
                          borderRadius: '8px',
                          padding: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          textAlign: 'center',
                          fontWeight: '700',
                          fontSize: '12px',
                          wordBreak: 'normal',
                        }}
                      >
                        {i18n.t('bookingsRefunded').toUpperCase()}
                      </span>
                    </div>
                  </>
                )}
            </div>
            <p style={{ marginTop: 2, fontSize: 17, fontWeight: 500 }}>
              {asCurrency(getBookedItemAmount(item) / 100)} €
              {item.discount && (item.discount.percentage > 0 || item.discount.amount > 0) ? (
                <Popup
                  on={['hover', 'click']}
                  basic
                  // eslint-disable-next-line prettier/prettier
                  trigger={<p style={{ position: 'relative', bottom: 1, marginLeft: 6, height: 18, width: 18, border: '1px solid #7268cd', display: 'inline-block', borderRadius: 250, textAlign: 'center', fontSize: 12, color: '#7268cd', fontWeight: 800}}>%</p>}
                  // eslint-disable-next-line prettier/prettier
                  content={(
                    <>
                      <p>
                        {i18n.t('originalPrice')}: {`${asCurrency(item.amount / 100)}`}€
                      </p>
                      <p>
                        {i18n.t('discount')}:
                        {item.discount.percentage > 0
                          ? ` ${parseInt(item.discount.percentage * 100, 10)}%`
                          : ` ${asCurrency(item.discount.amount / 100)}€`}
                      </p>
                    </>
                    // eslint-disable-next-line prettier/prettier
                  )}
                  position="bottom right"
                  popper={<div style={{ filter: 'none' }} />}
                />
              ) : (
                ''
              )}
            </p>
          </div>
          <ul style={{ position: 'relative', left: 37, width: '95%', marginBottom: 10 }}>
            {item.notes ? (
              <>
                <li
                  style={{ fontStyle: 'italic', listStyleType: 'none', wordBreak: 'break-all' }}
                  key={`${item.itemId}/${index}`}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">H</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${item.notes}`}
                    </p>
                  </div>
                </li>
              </>
            ) : (
              <></>
            )}
            {item.options && item.options.length > 0 ? (
              item.options.map((option, idx) => (
                <li
                  style={{ fontStyle: 'italic', listStyleType: 'none', wordBreak: 'break-all' }}
                  key={`${item.itemId}/${idx}`}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">O</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${option.name}`}
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <></>
            )}
            {item.specialSelections.map((specialSelection, tidx) => (
              <li
                style={{ fontStyle: 'italic', listStyleType: 'none', wordBreak: 'break-all' }}
                key={`${item.itemId}/${tidx}`}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 4,
                    marginLeft: 2,
                    marginBottom: 4,
                    alignItems: 'center',
                  }}
                >
                  <p className="bookings-extras-options-indicator">E</p>
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {` ${specialSelection.specialSelectionTitle}`}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  );

  const parseSplitBookedItems = () => (
    <div
      style={{ display: user.role === 'employee' ? '' : 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ width: user.role === 'employee' ? '100%' : '45%' }}>
        <p style={{ fontWeight: 600, fontSize: 16 }}>
          {i18n.t('orderTitle')} {booking.bookingId}
        </p>
        <div
          style={{
            padding: 10,
            borderRadius: 14,
            width: '100%',
            height: 200,
            overflowY: 'scroll',
            backgroundColor: 'white',
            border: '1px solid #ccc',
          }}
          className="split-booking-no-scrollbar"
        >
          {oldBookedItems.map((item, index) => (
            <div
              style={{
                display: item.isRefund === false ? 'flex' : 'none',
                justifyContent: 'space-between',
                cursor: 'pointer',
                marginBottom: 12,
                padding: 4,
                paddingBottom: 8,
                borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
              }}
              onClick={() => {
                if (oldBookedItems.filter((item) => item.isRefund === false).length !== 1) {
                  oldBookedItems.splice(index, 1);
                  setNewBookedItems([...newBookedItems, item]);
                  setOldBookedItems([...oldBookedItems]);
                  setIsSplittingModeSaveEnabled(true);
                }
              }}
            >
              {item.isRefund === false ? (
                <>
                  <div>
                    <p style={{ fontWeight: 500, margin: 0 }}>{item.itemTitle}</p>
                    {item.options &&
                      item.options.length > 0 &&
                      item.options.map((option, opidx) => (
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                          key={`${opidx}op-idx`}
                        >
                          <p className="bookings-extras-options-indicator">O</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      ))}
                    {item.specialSelections &&
                      item.specialSelections.length > 0 &&
                      item.specialSelections.map((special, ssidx) => (
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                          key={`${ssidx}ss-idx`}
                        >
                          <p className="bookings-extras-options-indicator">E</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${special.specialSelectionTitle}`}
                          </p>
                        </div>
                      ))}
                  </div>
                  <p style={{ fontWeight: 500, textAlign: 'right' }}>
                    {`${asCurrency(item.amount / 100)} €`}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
        <p
          style={{
            textAlign: 'right',
            paddingTop: 10,
            fontSize: 17,
            paddingRight: 8,
            fontWeight: 600,
          }}
        >
          {`${asCurrency(
            oldBookedItems
              .filter((item) => item.isRefund === false)
              .reduce((sum, { amount }) => sum + amount, 0) / 100
          )}€`}
        </p>
      </div>
      <div style={{ alignSelf: 'center', fontSize: 36, color: 'black', textAlign: 'center' }}>
        {user.role === 'employee' ? <Icon name="arrow down" /> : <Icon name="arrow right" />}
      </div>
      <div style={{ width: user.role === 'employee' ? '100%' : '45%' }}>
        <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('newOrder')}</p>
        <div
          style={{
            padding: 10,
            borderRadius: 14,
            width: '100%',
            height: 200,
            overflowY: 'scroll',
            backgroundColor: 'white',
            border: '1px solid #ccc',
          }}
          className="split-booking-no-scrollbar"
        >
          {newBookedItems.map((item, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                marginBottom: 12,
                padding: 4,
                paddingBottom: 8,
                borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
              }}
              key={`splitt-1-${index}`}
              onClick={() => {
                newBookedItems.splice(index, 1);
                setNewBookedItems([...newBookedItems]);
                setOldBookedItems([...oldBookedItems, item]);
                if (newBookedItems.length === 0) {
                  setIsSplittingModeSaveEnabled(false);
                }
              }}
            >
              <div>
                <p style={{ fontWeight: 500, margin: 0 }}>{item.itemTitle}</p>
                {item.options &&
                  item.options.length > 0 &&
                  item.options.map((option, idxx) => (
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        marginLeft: 2,
                        marginBottom: 4,
                        alignItems: 'center',
                      }}
                      key={`splitt-op-${idxx}`}
                    >
                      <p className="bookings-extras-options-indicator">O</p>
                      <p
                        style={{
                          marginBottom: 0,
                          fontStyle: 'italic',
                        }}
                      >
                        {` ${option.name}`}
                      </p>
                    </div>
                  ))}
                {item.specialSelections &&
                  item.specialSelections.length > 0 &&
                  item.specialSelections.map((special, tidxx) => (
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        marginLeft: 2,
                        marginBottom: 4,
                        alignItems: 'center',
                      }}
                      key={`splitt-ss-${tidxx}`}
                    >
                      <p className="bookings-extras-options-indicator">E</p>
                      <p
                        style={{
                          marginBottom: 0,
                          fontStyle: 'italic',
                        }}
                      >
                        {` ${special.specialSelectionTitle}`}
                      </p>
                    </div>
                  ))}
              </div>
              <p style={{ fontWeight: 500, textAlign: 'right' }}>
                {`${asCurrency(item.amount / 100)} €`}
              </p>
            </div>
          ))}
        </div>
        <p
          style={{
            textAlign: 'right',
            paddingTop: 10,
            fontSize: 17,
            paddingRight: 8,
            fontWeight: 600,
          }}
        >
          {`${asCurrency(newBookedItems.reduce((sum, { amount }) => sum + amount, 0) / 100)}€`}
        </p>
      </div>
    </div>
  );

  const renderAmount = () => `${asCurrency(modalBooking ? modalBooking.amount / 100 : 0)}€`;

  const table = (table) => (
    <>
      {table.tableNumber ? (
        <li key={table.tableNumber} style={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          {table.tableDescription} ({table.tableNumber})
        </li>
      ) : (
        <></>
      )}
    </>
  );

  const tableOptions = () => {
    const tables =
      shop && shop.tables && shop.tables.length > 0
        ? [
            ...shop.tables.map((table, index) => ({
              key: `tableD${table.number}${index}`,
              text: `${table.description} ${table.number}`,
              value: table.number,
            })),
          ]
        : [];
    const tablesSort = sortBy(tables, 'value');
    return tablesSort;
  };

  if (booking) {
    return (
      <>
        <AnimatedModal
          open={open}
          dimmer="blurring"
          size={booking.isRequest === true ? 'tiny' : null}
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              paddingRight: '6px',
              paddingTop: 12,
              paddingBottom: 0,
            }}
            className="bookings-modal-header"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ width: '100%', marginBottom: 0 }}>
                {booking.isRequest === false
                  ? booking.paymentType === 'later'
                    ? `${i18n.t('orderTitle')} ${booking.bookingId}`
                    : `${i18n.t('bookingModalTitle')} ${booking.bookingId}`
                  : i18n.t('qrRequest')}
              </p>
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  width: '100%',
                  height: 36,
                  justifyContent: 'end',
                }}
              >
                {booking.isRequest === false &&
                booking.history != null &&
                booking.history.length > 0 ? (
                  <>
                    <Button
                      icon="history"
                      color={isShowingHistory ? 'teal' : 'black'}
                      basic={!isShowingHistory}
                      onClick={() => {
                        setIsSplitting(false);
                        setIsEditing(false);
                        setIsShowingHistory(!isShowingHistory);
                      }}
                      circular
                    />
                  </>
                ) : (
                  <></>
                )}
                {(user.isOrdering === true || user.isPaying === true) &&
                  booking.status !== 'refund' &&
                  booking.status !== 'cancelled' &&
                  booking.paid === false &&
                  booking.bookedItems.filter((item) => item.isRefund === false).length > 1 &&
                  booking.isRequest === false && (
                    <Button
                      icon="exchange"
                      color={isSplitting ? 'teal' : 'black'}
                      basic={!isSplitting}
                      onClick={() => {
                        if (!isSplitting) {
                          setOldBookedItems(cloneDeep(booking.bookedItems));
                        }
                        setIsSplitting(!isSplitting);
                        setIsEditing(false);
                        setIsShowingHistory(false);
                      }}
                      circular
                    />
                  )}
                {user.role !== 'employee' &&
                booking.status !== 'refund' &&
                booking.status !== 'cancelled' &&
                booking.isRequest === false ? (
                  <>
                    <Button
                      icon="edit"
                      color={isEditing ? 'teal' : 'black'}
                      basic={!isEditing}
                      onClick={() => {
                        setIsSplitting(false);
                        setIsShowingHistory(false);
                        setIsEditing(!isEditing);
                      }}
                      circular
                    />
                  </>
                ) : (
                  <></>
                )}
                <Button
                  icon="cancel"
                  color="black"
                  basic
                  onClick={() => closeModal(false)}
                  circular
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Content scrolling style={{ paddingTop: 2 }}>
            {booking.isRequest === false && (
              <>
                <div>
                  <span
                    style={{
                      color: statusColors[booking.status],
                      border: `solid 1px ${statusColors[booking.status]}`,
                      borderRadius: '8px',
                      boxSizing: 'border-box',
                      display: 'inline-block',
                      padding: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: '12px',
                      marginRight: '8px',
                      marginBottom: 6,
                    }}
                  >
                    {booking.status === 'open'
                      ? i18n.t('bookingModalChangeStatusOpen').toUpperCase()
                      : booking.status === 'closed'
                      ? i18n.t('bookingModalChangeStatusDone').toUpperCase()
                      : booking.status === 'refund'
                      ? i18n.t('bookingsRefunded').toUpperCase()
                      : 'STORNIERT'}
                  </span>
                  <span
                    style={{
                      color:
                        booking.paymentType === 'later'
                          ? 'maroon'
                          : booking.paymentType === 'cash'
                          ? 'steelblue'
                          : 'royalblue',
                      border: `solid 1px ${
                        booking.paymentType === 'later'
                          ? 'maroon'
                          : booking.paymentType === 'cash'
                          ? 'steelblue'
                          : 'royalblue'
                      }`,
                      borderRadius: '8px',
                      boxSizing: 'border-box',
                      display: 'inline-block',
                      padding: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: '12px',
                      marginRight: '8px',
                      marginBottom: 6,
                    }}
                  >
                    {booking.paymentType === 'later'
                      ? i18n.t('dailySummaryLater').toUpperCase()
                      : booking.paymentType === 'cash'
                      ? i18n.t('dailySummaryBar').toUpperCase()
                      : i18n.t('dailySummaryKarte').toUpperCase()}
                  </span>
                  <span
                    style={{
                      color: booking.paid ? 'mediumseagreen' : 'red',
                      border: `solid 1px ${booking.paid ? 'mediumseagreen' : 'red'}`,
                      borderRadius: '8px',
                      boxSizing: 'border-box',
                      display: 'inline-block',
                      padding: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: '12px',
                      marginRight: '8px',
                      marginBottom: 6,
                    }}
                  >
                    {booking.paid
                      ? i18n.t('bookingPaymentPaid').toUpperCase()
                      : i18n.t('bookingPaymentUnpaid').toUpperCase()}
                  </span>
                  {booking.table && (
                    <>
                      <span
                        style={{
                          color:
                            booking.table && booking.table.isTakeAway === 'true' ? 'pink' : 'plum',
                          border: `solid 1px ${
                            booking.table && booking.table.isTakeAway === 'true' ? 'pink' : 'plum'
                          }`,
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          display: 'inline-block',
                          padding: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          textAlign: 'center',
                          fontWeight: '700',
                          fontSize: '12px',
                          marginRight: '8px',
                          marginBottom: 6,
                        }}
                      >
                        {booking.table && booking.table.isTakeAway === 'true'
                          ? 'TAKE-AWAY'
                          : 'IN-HOUSE'}
                      </span>
                    </>
                  )}
                  {booking.isFromTerminal === true && (
                    <span
                      style={{
                        color: 'black',
                        border: `solid 1px black`,
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        display: 'inline-block',
                        padding: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '12px',
                        marginRight: '8px',
                        marginBottom: 6,
                      }}
                    >
                      TERMINAL
                    </span>
                  )}
                  {booking.isFromTerminal === false && (
                    <span
                      style={{
                        color: 'black',
                        border: `solid 1px black`,
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        display: 'inline-block',
                        padding: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '12px',
                        marginRight: '8px',
                        marginBottom: 6,
                      }}
                    >
                      QR
                    </span>
                  )}
                </div>
                <br />
              </>
            )}
            <p className="bookings-modal-title" style={{ fontSize: 15 }}>
              {`${moment(booking.created.toString()).format(
                `HH:mm[${i18n.t('uhrGerman')}], DD.MM.YYYY`
              )}`}
            </p>
            {booking.createdByName && booking.createdByName.length > 0 && (
              <p>
                {i18n.t('createdBy')}
                <span style={{ fontWeight: 700 }}>{booking.createdByName}</span>
              </p>
            )}
            {booking.lastChangedByName && booking.lastChangedByName.length > 0 && (
              <p>
                {i18n.t('lastChangedBy')}
                <span style={{ fontWeight: 700 }}>{booking.lastChangedByName}</span>
              </p>
            )}
            {booking.paidByName && booking.paidByName.length > 0 && (
              <p>
                {i18n.t('paidBy')}
                <span style={{ fontWeight: 700 }}>{booking.paidByName}</span>
              </p>
            )}
            {booking.mergedIds && booking.mergedIds.length > 0 && (
              <p>
                {i18n.t('combinedFrom')}
                <span style={{ fontWeight: 700 }}>{booking.mergedIds.join(', ')}</span>
              </p>
            )}
            {booking.splitIds && booking.splitIds.length > 0 && (
              <p>
                {i18n.t('splitFrom')}
                <span style={{ fontWeight: 700 }}>{booking.splitIds.join(', ')}</span>
              </p>
            )}
            {booking.tipAmount && booking.tipAmount > 0 ? (
              <p>
                {i18n.t('tips')}
                <span style={{ fontWeight: 700 }}>{`${asCurrency(booking.tipAmount / 100)}€`}</span>
              </p>
            ) : (
              <></>
            )}
            {booking.table && booking.table.tableId !== null ? (
              <>
                <p className="bookings-modal-title">{i18n.t('bookingsTable')}</p>
                {isEditing === true && booking.paid === false && booking.status === 'open' && (
                  <>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <Dropdown
                        selection
                        className="change-table-dropdown"
                        placeholder={i18n.t('changeTable')}
                        value={selectedTable}
                        options={tableOptions()}
                        onChange={(_, { value }) => {
                          setSelectedTable(value);
                        }}
                      />
                      <Button
                        content={i18n.t('changeTable')}
                        color="teal"
                        size="small"
                        style={{ borderRadius: 8 }}
                        disabled={
                          booking &&
                          booking.table &&
                          booking.table.tableNumber &&
                          selectedTable === booking.table.tableNumber
                        }
                        onClick={() => onSaveEditTable()}
                      />
                    </div>
                    <br />
                  </>
                )}
                {(isEditing === false || booking.paid === true) && (
                  <div>{table(booking.table)}</div>
                )}
              </>
            ) : (
              <></>
            )}
            <br />
            {isSplitting === false && isShowingHistory === false ? (
              <p className="bookings-modal-title">
                {booking.isRequest === false
                  ? i18n.t('bookingModalBookedItems')
                  : i18n.t('bookingsItems')}
              </p>
            ) : (
              <></>
            )}
            {isSplitting === true && isEditing === false && isShowingHistory === false ? (
              parseSplitBookedItems()
            ) : (
              <></>
            )}
            {isEditing && isSplitting === false && isShowingHistory === false ? (
              parseEditableBookedItems(
                bookedItemsCopy && bookedItemsCopy.length > 0
                  ? bookedItemsCopy
                  : booking.bookedItems,
                booking.paymentType
              )
            ) : (
              <></>
            )}
            {isShowingHistory === true && isEditing === false && isSplitting === false ? (
              <>
                <p className="bookings-modal-title">{i18n.t('history')}</p>
                {isLoadingHistory === true ? (
                  <Loader
                    inline="centered"
                    active={isLoadingHistory === true}
                    size="large"
                    className="bookings-create-loader"
                    style={{ margin: '0 auto' }}
                  />
                ) : (
                  <Timeline entries={history} />
                )}
              </>
            ) : (
              <></>
            )}
            {isEditing === false && isSplitting === false && isShowingHistory === false ? (
              <div>
                {bookedItems(booking.bookedItems, booking.paymentType)}
                <br />
                <div style={{ width: '100%', border: '1px #999 solid', borderTop: 'none' }} />
                <br />
                <p
                  style={{
                    fontSize: '22px',
                    fontWeight: '600',
                    marginTop: '-10px',
                    width: '100%',
                    textAlign: 'right',
                  }}
                >
                  {renderAmount()}
                </p>
              </div>
            ) : (
              <></>
            )}
          </Modal.Content>
          {booking.status !== 'refund' &&
          booking.status !== 'cancelled' &&
          isShowingHistory === false ? (
            <Modal.Actions
              className="bookings-actions"
              style={{ borderTop: 'none', backgroundColor: 'white' }}
            >
              <>
                {booking.isRequest === false && (
                  <>
                    {isEditing === true && isSplitting === false && (
                      <>
                        <Button
                          content={i18n.t('bookingModalEditSave')}
                          color="teal"
                          floated="right"
                          disabled={!isEditingModeSaveEnabled}
                          onClick={() => onSaveEdit()}
                        />
                      </>
                    )}
                    {isSplitting === true && isEditing === false && (
                      <>
                        <Button
                          content={i18n.t('bookingModalEditSave')}
                          color="teal"
                          floated="right"
                          disabled={!isSplittingModeSaveEnabled}
                          onClick={() => onSaveSplit()}
                        />
                      </>
                    )}
                    {isSplitting === false && isEditing === false && (
                      <>
                        {user.role !== 'employee' &&
                          booking.paymentType === 'cash' &&
                          booking.status === 'open' &&
                          booking.paid === false && (
                            <>
                              <Button
                                content={i18n.t('bookingCancel')}
                                floated="left"
                                negative
                                onClick={() => {
                                  onRefund(booking);
                                }}
                              />
                            </>
                          )}
                        {user.role !== 'employee' &&
                          booking.paymentType === 'later' &&
                          booking.status !== 'cancelled' && (
                            <>
                              <Button
                                content={i18n.t('bookingCancel')}
                                floated="left"
                                negative
                                onClick={() => {
                                  onRefund(booking);
                                }}
                              />
                            </>
                          )}
                        {user.role !== 'employee' &&
                          booking.paid === true &&
                          ((booking.paymentType === 'cash' &&
                            booking.tssTransactions &&
                            booking.tssTransactions.length > 0) ||
                            booking.paymentType === 'card') && (
                            <>
                              <Button
                                content={i18n.t('bookingsRefund')}
                                floated="left"
                                negative
                                onClick={() => {
                                  onRefund(booking);
                                }}
                              />
                            </>
                          )}
                        {booking.paymentType === 'later' &&
                          !booking.paid &&
                          (user.role === 'owner' ||
                            (user.role !== 'owner' &&
                              (user.isPaying === true || user.isOrdering === true))) && (
                            // eslint-disable-next-line react/jsx-indent
                            <Button
                              floated="right"
                              color="teal"
                              onClick={() => {
                                onPayLater(booking.table.tableId, booking.bookingId);
                                closeModal(false);
                              }}
                              content={i18n.t('bookingsModalPay')}
                            />
                          )}
                        {booking.paymentType !== 'later' &&
                          (user.isPaying === true || user.isOrdering === true) && (
                            <>
                              <Button
                                floated="left"
                                color={
                                  booking.status === 'closed'
                                    ? statusColors.open
                                    : statusColors.closed
                                }
                                // eslint-disable-next-line prettier/prettier
                                onClick={() => onChangeStatus(booking._id, booking.status === 'closed' ? 'open' : 'closed')}
                              >
                                <Button.Content visible>
                                  {`${i18n.t('bookingModalChangeStatus1')} ${
                                    booking.status === 'open'
                                      ? i18n.t('bookingModalChangeStatusDone').toUpperCase()
                                      : i18n.t('bookingModalChangeStatusOpen').toUpperCase()
                                  } ${i18n.t('bookingModalChangeStatus2')}`}
                                </Button.Content>
                              </Button>
                            </>
                          )}
                        {booking.status !== 'refund' &&
                          booking.status !== 'cancelled' &&
                          booking.paid === false && (
                            <Button
                              floated="right"
                              color="blue"
                              onClick={() => printCustomerBon(booking, false, true)}
                              icon="print"
                              content={i18n.t('zwischenbeleg')}
                              disabled={!isPrinterAvailable || user.isPrinting === false}
                            />
                          )}
                        {booking.status !== 'refund' && booking.status !== 'cancelled' && (
                          <Button
                            floated="right"
                            color="blue"
                            onClick={() => printBooking(booking)}
                            icon="print"
                            content={i18n.t('iBon')}
                            disabled={!isPrinterAvailable || user.isPrinting === false}
                          />
                        )}
                        {booking.paid && (
                          <>
                            <Button
                              style={{ borderRadius: 8 }}
                              floated="right"
                              color="blue"
                              onClick={() => printCustomerBon(booking, false, false)}
                              icon="print"
                              content={i18n.t('kBon')}
                              disabled={!isPrinterAvailable || user.isPrinting === false}
                            />
                            <Button
                              style={{ borderRadius: 8 }}
                              floated="right"
                              color="blue"
                              onClick={() => printCustomerBon(booking, true, false)}
                              icon="print"
                              content={i18n.t('bewirtung')}
                              disabled={!isPrinterAvailable || user.isPrinting === false}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {booking.isRequest === true && (
                  <>
                    <Button
                      content={i18n.t('ablehnen')}
                      floated="left"
                      negative
                      onClick={() => onDecliningRequest(booking._id)}
                    />
                    <Button
                      content={i18n.t('annehmen')}
                      floated="right"
                      color="teal"
                      onClick={() => onAcceptingRequest(booking._id)}
                    />
                  </>
                )}
              </>
            </Modal.Actions>
          ) : (
            <>
              {booking.status === 'refund' && isShowingHistory === false && (
                <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
                  <Button
                    floated="right"
                    color="blue"
                    onClick={() => printCustomerRefundBon(booking)}
                    icon="print"
                    content={i18n.t('bookingModalPrintRefund')}
                    disabled={!isPrinterAvailable || user.isPrinting === false}
                  />
                </Modal.Actions>
              )}
            </>
          )}
        </AnimatedModal>
        <ErrorModal
          open={errorWarningModalOptions.open}
          title={i18n.t('newPrinterErrorTitle')}
          message={errorWarningModalOptions.message}
          onOk={onErrorWarningModalOk}
        />
        <ErrorWarningModal
          open={warningModalOptions.open}
          title={warningModalOptions.title}
          message={warningModalOptions.message}
          ok={warningModalOptions.ok}
          onOk={warningModalOptions.onOk}
          yes={warningModalOptions.yes}
          onYes={warningModalOptions.onYes}
          no={warningModalOptions.no}
          onNo={warningModalOptions.onNo}
        />
      </>
    );
  }
  return <></>;
};

export default BookingsScreenModal;
