/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Loader, Dimmer } from 'semantic-ui-react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import { getZBon } from '../../../api/BookingAPI';
import { Printer } from '../../../utils/Printer';
import { asCurrency } from '../../../utils/NumberFormatter';
import { showToast } from '../../../components/general/Toast/Toast';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const XBonScreenModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [zBon, setZBon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [zBonDate, setZBonDate] = useState({ startTime: null, startDate: moment().toDate() });

  useEffect(() => {
    const momentStartTime = moment(zBonDate.startTime, 'HH:mm');
    if (
      moment().startOf('day').isSame(moment(zBonDate.startDate).startOf('day')) &&
      momentStartTime.isAfter(moment())
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('xBonTimeError'),
      });
      setZBonDate({
        ...zBonDate,
        startTime: '',
      });
    }
  }, [zBonDate.startTime]);

  const loadData = async () => {
    setShowDatePicker(false);
    setIsLoading(true);
    try {
      const res = await getZBon(
        token,
        shop._id,
        moment(zBonDate.startDate).startOf('day').add(moment.duration(zBonDate.startTime)).format(),
        moment().format(),
        true
      );
      if (res && res.success) {
        setZBon(res.zBon);
      } else {
        setErrorWarningModalOptions({ open: true, message: i18n.t('xBonLoadError') });
        setShowDatePicker(true);
        setZBonDate({ startTime: '' });
      }
    } catch (error) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('xBonLoadError') });
      setShowDatePicker(true);
      setZBonDate({ startTime: '' });
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setZBon(null);
    setIsLoading(false);
    setShowDatePicker(true);
    setZBonDate({ startTime: null, startDate: moment().toDate() });
    setStartDatePickerOpen(false);
    onClose();
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const parseZBon = () => (
    <>
      <div className="zBon-container">
        <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('general')}</p>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('startDate')}</p>
          <p>{moment(zBon.issueDateStart).format('DD.MM.YYYY')}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('startTime')}</p>
          <p>{moment(zBon.issueDateStart).format(`HH:mm [${i18n.t('uhrGerman')}]`)}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('endDate')}</p>
          <p>{moment(zBon.issueDateEnd).format('DD.MM.YYYY')}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('endTime')}</p>
          <p>{moment(zBon.issueDateEnd).format(`HH:mm [${i18n.t('uhrGerman')}]`)}</p>
        </div>
        <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('revenue')}</p>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('revenueExclTax')}</p>
          <p>{`${asCurrency(zBon.totalWithoutTaxes / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('7Tax')}</p>
          <p>{`${asCurrency(zBon.reducedTaxesTotal / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('19Tax')}</p>
          <p>{`${asCurrency(zBon.normalTaxesTotal / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('taxTotal')}</p>
          <p>{`${asCurrency(zBon.taxes / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('revenue7Tax')}</p>
          <p>{`${asCurrency(zBon.reducedTaxesTotalRevenue / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('revenue19Tax')}</p>
          <p>{`${asCurrency(zBon.normalTaxesTotalRevenue / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('revenueInclVAT')}</p>
          <p>{`${asCurrency(zBon.total / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('cashPayments')}</p>
          <p>{`${asCurrency(zBon.cash / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('cardPayments')}</p>
          <p>{`${asCurrency(zBon.card / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('unpaidOrders')}</p>
          <p>{`${asCurrency(zBon.totalOpenBookings / 100)}€`}</p>
        </div>
        {shop.tipsAllowed === true ? (
          <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('tips2')}</p>
        ) : (
          <></>
        )}
        {shop.tipsAllowed === true && (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('tipsTotal')}</p>
            <p>{`${asCurrency(zBon.totalTipAmount / 100)}€`}</p>
          </div>
        )}
        {shop.tipsAllowed === true && (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('tipsCash')}</p>
            <p>{`${asCurrency(zBon.totalTipCash / 100)}€`}</p>
          </div>
        )}
        {shop.tipsAllowed === true && (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('tipsCard')}</p>
            <p>{`${asCurrency(zBon.totalTipCard / 100)}€`}</p>
          </div>
        )}
        {shop.tipsAllowed === true && (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('tipsEmployer')}</p>
            <p>{`${asCurrency(zBon.ownerTipsAmount / 100)}€`}</p>
          </div>
        )}
        {shop.tipsAllowed === true && (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('tipsEmployee')}</p>
            <p>{`${asCurrency(zBon.employeeTipsAmount / 100)}€`}</p>
          </div>
        )}
        {shop.allowDiscounts === true && (
          <>
            <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('discount')}</p>
            <div style={{ display: 'flex' }}>
              <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('discountCount')}</p>
              <p>{zBon.discountsCount}</p>
            </div>
            <div style={{ display: 'flex' }}>
              <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('discountSum')}</p>
              <p>{`${asCurrency(zBon.totalDiscountsAmount / 100)}€`}</p>
            </div>
          </>
        )}
        <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('refund1')}</p>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('partlyRefundCount1')}</p>
          <p>{zBon.partlyRefundNumber}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('partlyRefundSum1')}</p>
          <p>{`${asCurrency(zBon.partlyRefund / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('fullRefundCount1')}</p>
          <p>{zBon.refundNumber}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('fullRefundSum1')}</p>
          <p>{`${asCurrency(zBon.refund / 100)}€`}</p>
        </div>
        <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('cancel1')}</p>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('partlyCancelCount')}</p>
          <p>{zBon.partlyCancelNumber || 0}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('partlyCancelSum')}</p>
          <p>{`${asCurrency((zBon.partlyCancel || 0) / 100)}€`}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('fullCancelCount')}</p>
          <p>{zBon.cancelNumber || 0}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: 500, width: 210 }}>{i18n.t('fullCancelSum')}</p>
          <p>{`${asCurrency((zBon.cancel || 0) / 100)}€`}</p>
        </div>
      </div>
    </>
  );

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorXBon${printerDevice.printer.description}`,
    });
  };

  const printZBon = () => {
    if (shop.printers && shop.printers.length > 0) {
      const printableZBon = {
        ...zBon,
      };
      if (shop.printers.find((printer) => printer.isMain === true)) {
        const shopPrinter = shop.printers.find((printer) => printer.isMain === true);
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
        printerDevice.printXBon(
          printableZBon,
          paperSize,
          shop.tipsAllowed === true,
          shop.allowDiscounts === true,
          shop.address,
          shop.phoneNumber,
          shop.taxId
        );
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('printerNoKundenbonError1'),
        });
      }
    }
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="mini" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('xBon')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ padding: showDatePicker ? 10 : 20 }}>
          {showDatePicker ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('dayStart')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{ border: 'none', width: 100 }}
                      onClick={() => setStartDatePickerOpen(true)}
                    >
                      <ReactDatePicker
                        className="day-picker-zbon"
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        showPopperArrow={false}
                        placeholderText="--"
                        locale="de"
                        calendarStartDay={1}
                        minDate={moment().subtract(1, 'days').startOf('day').toDate()}
                        maxDate={moment().endOf('day').toDate()}
                        selected={zBonDate.startDate}
                        onClickOutside={() => setStartDatePickerOpen(false)}
                        readOnly
                        open={startDatePickerOpen === true}
                        onChange={(date) => {
                          setStartDatePickerOpen(false);
                          setZBonDate({ ...zBonDate, startDate: date });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        placeholder="--"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setZBonDate({ ...zBonDate, startTime: e.target.value });
                          }
                        }}
                        value={zBonDate.startTime}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content="0:00 Uhr"
                    icon="clock"
                    style={{ marginTop: 12, marginLeft: 4 }}
                    onClick={() => {
                      setZBonDate({
                        ...zBonDate,
                        startTime: '00:00',
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {zBon === null ? (
                <>
                  <Loader
                    inline="centered"
                    size="medium"
                    active={isLoading}
                    className="bookings-create-loader"
                  />
                </>
              ) : (
                <>{parseZBon()}</>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          {showDatePicker ? (
            <>
              <Button
                content={i18n.t('createXBon')}
                color="teal"
                disabled={zBonDate.startTime == null || zBonDate.startTime === ''}
                onClick={async () => {
                  await loadData();
                }}
              />
            </>
          ) : (
            <>
              {zBon != null ? (
                <Button
                  icon="print"
                  content={i18n.t('xBon')}
                  color="blue"
                  onClick={() => printZBon()}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default XBonScreenModal;
