/* eslint-disable global-require */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import './ItemsScreen.css';
import { getExcelTemplate, uploadExcel } from '../../../api/ItemAPI';
import i18n from '../../../i18n/config';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const ItemsScreenUploadModal = (props) => {
  const { open, onClose } = props;
  const [{ token, shop, config }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const closeModal = (refreshNeeded) => {
    setErrorWarningModalOptions({ open: false, message: '' });
    setIsLoading(false);
    onClose(refreshNeeded);
  };

  const onDownloadTemplate = async () => {
    try {
      await getExcelTemplate(token);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('templateDownloadError'),
      });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onFileUpload = async (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 500000) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('fileTooBig'),
        });
      } else {
        setIsLoading(true);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = async (upload) => {
          try {
            const res = await uploadExcel(token, upload.target.result);
            if (res && res.success) {
              closeModal(true);
            } else {
              setIsLoading(false);
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('templateUploadError'),
              });
            }
          } catch (error) {
            setIsLoading(false);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('templateUploadError'),
            });
          }
        };
      }
    }
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="mini" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none' }}>
          {i18n.t('uploadProducts')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            className="items-close-btn"
            circular
            disabled={isLoading}
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            <p>{i18n.t('uploadProductsDescription')}</p>
            <p style={{ fontWeight: 600 }}>{i18n.t('uploadProductsWarning')}</p>
            <Button
              icon="download"
              content="Excel Template"
              basic
              fluid
              style={{ border: '1px solid #7268cd' }}
              disabled={isLoading}
              onClick={onDownloadTemplate}
            />
            <br />
            <Button
              as="label"
              htmlFor="file"
              type="button"
              color="teal"
              fluid
              style={{ backgroundColor: 'black' }}
              size="big"
              disabled={isLoading}
              loading={isLoading}
            >
              {i18n.t('upload')}
            </Button>
            <input
              type="file"
              id="file"
              onChange={onFileUpload}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
        </Modal.Content>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default ItemsScreenUploadModal;
