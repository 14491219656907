/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React from 'react';
import i18n from 'i18next';
import {
  getBookingsPage,
  getBookingsStatusFilter,
  searchBookings,
  refundCardBooking,
  refundCashBooking,
  updateBookingStatus,
  getBookingsPaymentTypeFilter,
  refundLaterBooking,
  refundCashBookingPin,
  refundLaterBookingPin,
  refundCardBookingPin,
  updateBookingStatusPin,
  getBookingsPageEmployee,
  getBookingsTypeTypeFilter,
  getBookingsByTableFilter,
  getBookingsByTableFilterEmployee,
  getBookingsTypeTypeFilterEmployee,
  getBookingsPaymentTypeFilterEmployee,
  getBookingsStatusFilterEmployee,
  searchBookingsEmployee,
} from '../../../api/BookingAPI';

export let dropdownOptions = [
  {
    key: 'statusTitle',
    text: (
      <div>
        <strong>Status</strong>
      </div>
    ),
    value: 'statusTitle',
    disabled: true,
  },
  {
    key: 'open',
    text: i18n.t('bookingsOpen'),
    value: 'open',
  },
  {
    key: 'closed',
    text: i18n.t('bookingsClosed'),
    value: 'closed',
  },
  {
    key: 'refunded',
    text: i18n.t('bookingsRefunded'),
    value: 'refund',
  },
  {
    key: 'cancelled',
    text: i18n.t('bookingsStorniert'),
    value: 'cancelled',
  },
  {
    key: 'request',
    text: i18n.t('request'),
    value: 'request',
  },
  {
    key: 'paymentTypeTitle',
    text: (
      <div>
        <strong>{i18n.t('paymentMethodsTitle')}</strong>
      </div>
    ),
    value: 'paymentTypeTitle',
    disabled: true,
  },
  {
    key: 'card',
    text: i18n.t('dailySummaryKarte'),
    value: 'card',
  },
  {
    key: 'cash',
    text: i18n.t('dailySummaryBar'),
    value: 'cash',
  },
  {
    key: 'later',
    text: i18n.t('bookingsSaved'),
    value: 'later',
  },
  {
    key: 'unpaid',
    text: i18n.t('bookingPaymentUnpaid'),
    value: 'unpaid',
  },
  {
    key: 'typeTypeTitle',
    text: (
      <div>
        <strong>{i18n.t('type')}</strong>
      </div>
    ),
    value: 'typeTypeTitle',
    disabled: true,
  },
  {
    key: 'terminal',
    text: 'Terminal',
    value: 'terminal',
  },
  {
    key: 'qr',
    text: 'QR',
    value: 'qr',
  },
];

export const getBookingsData = async (token, page, entity, direction, selectedColumn) => {
  try {
    const res = await getBookingsPage(token, page, entity, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: false,
        isSearching: false,
        searchTerm: '',
        rooms: res.rooms,
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsDataEmployee = async (token, page, entity, direction, selectedColumn) => {
  try {
    const res = await getBookingsPageEmployee(token, page, entity, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: false,
        isSearching: false,
        searchTerm: '',
        rooms: res.rooms,
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getSearchData = async (token, searchTerm, page) => {
  try {
    const res = await searchBookings(token, searchTerm, page);
    if (res && res.success) {
      return {
        bookings: res.results,
        selectedColumn: null,
        sortDirection: 'descending',
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: false,
        isSearching: true,
        searchTerm,
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getSearchDataEmployee = async (token, searchTerm, page) => {
  try {
    const res = await searchBookingsEmployee(token, searchTerm, page);
    if (res && res.success) {
      return {
        bookings: res.results,
        selectedColumn: null,
        sortDirection: 'descending',
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: false,
        isSearching: true,
        searchTerm,
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsStatusData = async (token, status, page, direction) => {
  try {
    const res = await getBookingsStatusFilter(token, status, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsStatusDataEmployee = async (token, status, page, direction) => {
  try {
    const res = await getBookingsStatusFilterEmployee(token, status, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsPaymentTypeData = async (token, paymentType, page, direction) => {
  try {
    const res = await getBookingsPaymentTypeFilter(token, paymentType, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsPaymentTypeDataEmployee = async (token, paymentType, page, direction) => {
  try {
    const res = await getBookingsPaymentTypeFilterEmployee(token, paymentType, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsTypeTypeData = async (token, type, page, direction) => {
  try {
    const res = await getBookingsTypeTypeFilter(token, type, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsTypeTypeDataEmployee = async (token, type, page, direction) => {
  try {
    const res = await getBookingsTypeTypeFilterEmployee(token, type, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsByTableData = async (token, type, page, direction) => {
  try {
    const res = await getBookingsByTableFilter(token, type, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const getBookingsByTableDataEmployee = async (token, type, page, direction) => {
  try {
    const res = await getBookingsByTableFilterEmployee(token, type, page, direction);
    if (res && res.success) {
      return {
        bookings: res.bookings,
        selectedColumn: null,
        sortDirection: direction,
        totalPages: res.totalPages,
        currentPage: page,
        isFiltering: true,
        isSearching: false,
        searchTerm: '',
        rooms: [],
      };
    }
    return null;
  } catch (_) {
    return null;
  }
};

export const refundCard = async (token, bookingId) => {
  try {
    const res = await refundCardBooking(bookingId, token);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const refundCardPin = async (token, bookingId, pin) => {
  try {
    const res = await refundCardBookingPin(bookingId, token, pin);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const refundCash = async (token, bookingId) => {
  try {
    const res = await refundCashBooking(bookingId, token);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const refundCashPin = async (token, bookingId, pin) => {
  try {
    const res = await refundCashBookingPin(bookingId, token, pin);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const refundLater = async (token, bookingId) => {
  try {
    const res = await refundLaterBooking(bookingId, token);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const refundLaterPin = async (token, bookingId, pin) => {
  try {
    const res = await refundLaterBookingPin(bookingId, token, pin);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const changeStatus = async (token, status, bookingId) => {
  try {
    const res = await updateBookingStatus(token, status, bookingId);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};

export const changeStatusPin = async (token, status, bookingId, pin) => {
  try {
    const res = await updateBookingStatusPin(token, status, bookingId, pin);
    if (res && res.success) {
      return res;
    }
    if (res && res.error) {
      const error = {};
      error.error = `${i18n.t('noConnectionError')}`;
      return error;
    }
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  } catch (_) {
    const error = {};
    error.error = i18n.t('noConnectionError');
    return error;
  }
};
