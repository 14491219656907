import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const ErrorModal = (props) => {
  const { open, title, message, onOk } = props;
  return (
    <Modal
      dimmer="blurring"
      size="tiny"
      open={open}
      className={`modal-bounce ${open ? 'modal-bounce-in' : 'modal-bounce-out'}`}
    >
      <Modal.Header style={{ borderBottom: 'none' }}>
        <p>{title}</p>
      </Modal.Header>
      <Modal.Content>
        <p style={{ fontWeight: 600 }}>{message}</p>
      </Modal.Content>
      <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
        <Button content="OK" color="teal" onClick={onOk} />
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
