/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import i18n from 'i18next';
import { Button, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import { HorizontalBar } from 'react-chartjs-2';
import { isEmpty } from 'lodash';
import './VerticalBarChartTile.css';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/de';
import { useStateValue } from '../../../core/context/StateProvider';
import { getBookedItemsSummaryPDF, getBookedItemsSummaryPrintable } from '../../../api/BookingAPI';
import { showToast } from '../../general/Toast/Toast';
import { Printer } from '../../../utils/Printer';

const VerticalBarChartTile = (props) => {
  const { data, loading, details } = props;
  const [{ shop, token }] = useStateValue();
  const [selectedMonth, setSelectedMonth] = useState(moment().format());
  const [selectedDay, setSelectedDay] = useState(moment().format());
  const [isDownloading, setIsDownloading] = useState(false);
  let chartData;
  let topValue;
  let dataset;
  let topValueIndex;
  let valueSet;
  if (loading === false && data != null) {
    dataset = data.data.filter((array) => array[1] !== undefined).map((array) => array[1]);
    valueSet = data.data.filter((array) => array[0] != null).map((array) => array[0]);
    topValue = Math.max(...dataset);
    topValueIndex = dataset.indexOf(Math.max(...dataset));
    chartData = (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 350);
      gradient.addColorStop(0, '#a291d9');
      gradient.addColorStop(1, 'rgba(250, 172, 168, 0.9)');
      return {
        labels: data.labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: gradient,
            borderColor: gradient,
          },
        ],
      };
    };
  }
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          barPercentage: 0.7,
          gridLines: {
            display: false,
          },
          ticks: {
            callback: (value) =>
              value ? (value.length > 23 ? `${value.substr(0, 23)}...` : value) : value,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.1,
          gridLines: {
            display: false,
          },
          ticks: {
            max: topValue,
            callback: () => '',
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const getSummaryMonth = async () => {
    try {
      setIsDownloading(true);
      await getBookedItemsSummaryPDF(token, selectedMonth, true);
      setIsDownloading(false);
    } catch (error) {
      showToast({
        error: true,
        message: i18n.t('prodSumError1'),
        title: i18n.t('newPrinterErrorTitle'),
      });
      setIsDownloading(false);
    }
  };

  const getSummaryDay = async () => {
    try {
      setIsDownloading(true);
      await getBookedItemsSummaryPDF(token, selectedDay, false);
      setIsDownloading(false);
    } catch (error) {
      showToast({
        error: true,
        message: i18n.t('prodSumError1'),
        title: i18n.t('newPrinterErrorTitle'),
      });
      setIsDownloading(false);
    }
  };

  const printSummaryMonth = async () => {
    try {
      setIsDownloading(true);
      const res = await getBookedItemsSummaryPrintable(token, selectedMonth, true);
      if (res && res.success) {
        if (shop.printers.find((printer) => printer.isMain === true)) {
          const printerDevice = new Printer(
            shop.printers.find((printer) => printer.isMain === true)
          );
          printerDevice.epos.onerror = () => {
            showToast({
              error: true,
              message: i18n.t('prodSumError2'),
              title: i18n.t('newPrinterErrorTitle'),
            });
          };
          const paperSize =
            shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
          printerDevice.printTopProducts(shop, res.titles, paperSize, true, selectedMonth);
        } else {
          showToast({
            error: true,
            message: i18n.t('noReceiptPrinterError'),
            title: i18n.t('newPrinterErrorTitle'),
          });
        }
      } else {
        showToast({
          error: true,
          message: i18n.t('prodSumError2'),
          title: i18n.t('newPrinterErrorTitle'),
        });
      }
      setIsDownloading(false);
    } catch (error) {
      showToast({
        error: true,
        message: i18n.t('prodSumError2'),
        title: i18n.t('newPrinterErrorTitle'),
      });
      setIsDownloading(false);
    }
  };

  const printSummaryDay = async () => {
    try {
      setIsDownloading(true);
      const res = await getBookedItemsSummaryPrintable(token, selectedDay, false);
      if (res && res.success) {
        if (shop.printers.find((printer) => printer.isMain === true)) {
          const printerDevice = new Printer(
            shop.printers.find((printer) => printer.isMain === true)
          );
          printerDevice.epos.onerror = () => {
            showToast({
              error: true,
              message: i18n.t('prodSumError2'),
              title: i18n.t('newPrinterErrorTitle'),
            });
          };
          const paperSize =
            shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
          printerDevice.printTopProducts(shop, res.titles, paperSize, false, selectedDay);
        } else {
          showToast({
            error: true,
            message: i18n.t('noReceiptPrinterError'),
            title: i18n.t('newPrinterErrorTitle'),
          });
        }
      } else {
        showToast({
          error: true,
          message: i18n.t('prodSumError2'),
          title: i18n.t('newPrinterErrorTitle'),
        });
      }
      setIsDownloading(false);
    } catch (error) {
      showToast({
        error: true,
        message: i18n.t('prodSumError2'),
        title: i18n.t('newPrinterErrorTitle'),
      });
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div className="vertical-bar-chart-container">
        <div className="vertical-bar-content-container">
          <div className="vertical-bar-chart-header">
            <div style={{ display: 'flex' }}>
              <p className="vertical-bar-chart-title">{details.title}</p>
              <Popup
                on="click"
                trigger={<Icon name="chevron down" style={{ color: '#a291d9', marginTop: 5 }} />}
                flowing
                hoverable
                position="bottom center"
              >
                <Grid centered divided columns={2}>
                  <Grid.Column textAlign="center">
                    <p style={{ fontWeight: 600 }}>{i18n.t('monthlyReport')}</p>
                    <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                      <ReactDatePicker
                        inline
                        locale="de"
                        container
                        wrapperClassName="datepicker"
                        showMonthYearPicker
                        selected={moment().toDate()}
                        minDate={moment(shop && shop.created ? shop.created : null)
                          .startOf('month')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={(selectedDate) => {
                          const date = moment(selectedDate).format();
                          setSelectedMonth(date);
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                      <Button
                        icon="download"
                        color="teal"
                        fluid
                        onClick={() => getSummaryMonth()}
                        disabled={isDownloading}
                      />
                      <Button
                        icon="print"
                        color="blue"
                        fluid
                        onClick={() => printSummaryMonth()}
                        disabled={isDownloading}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    <p style={{ fontWeight: 600 }}>{i18n.t('dailyReport')}</p>
                    <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                      <ReactDatePicker
                        inline
                        locale="de"
                        container
                        calendarStartDay={1}
                        wrapperClassName="datepicker"
                        selected={moment().toDate()}
                        minDate={moment(shop && shop.created ? shop.created : null)
                          .startOf('month')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={(selectedDate) => {
                          const date = moment(selectedDate).format();
                          setSelectedDay(date);
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                      <Button
                        icon="download"
                        color="teal"
                        fluid
                        onClick={() => getSummaryDay()}
                        disabled={isDownloading}
                      />
                      <Button
                        icon="print"
                        color="blue"
                        fluid
                        onClick={() => printSummaryDay()}
                        disabled={isDownloading}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Popup>
            </div>
            <div
              className="vertical-bar-chart-image-container"
              style={{
                backgroundColor: `${details.imageBackground}1F`,
              }}
            >
              <img className="vertical-bar-chart-image" src={details.image} alt="" />
            </div>
          </div>
          {loading ? (
            <Loader size="massive" active={loading} inverted className="bookings-create-loader" />
          ) : (
            <>
              {data ? (
                <>
                  <div className="vertical-bar-chart-legend">
                    <p className="vertical-bar-chart-legend-title">{data.legendTitle}</p>
                    <p className="vertical-bar-chart-legend-value">
                      {`${
                        !isEmpty(valueSet) && topValueIndex !== -1
                          ? valueSet[topValueIndex]
                          : i18n.t('dashBoardTopTenItemsNoData')
                      }`}
                    </p>
                  </div>
                  <div className="vertical-bar-chart-bar">
                    <HorizontalBar data={chartData} height={310} options={options} />
                  </div>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: 600, fontSize: 15, marginTop: 35 }}>
                    {`${i18n.t('dataError')}.`}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VerticalBarChartTile;
