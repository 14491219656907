/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import i18n from '../../../i18n/config';
import KPITile from '../../../components/shop/KPITile/KPITile';
import LineChartTile from '../../../components/shop/LineChartTile/LineChartTile';
import HorizontalBarChartTile from '../../../components/shop/HorizontalBarChartTile/HorizontalBarChartTile';
import VerticalBarChartTile from '../../../components/shop/VerticalBarChartTile/VerticalBarChartTile';
import DoughnutChartTile from '../../../components/shop/DoughnutChartTile/DoughnutChartTile';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import { useStateValue } from '../../../core/context/StateProvider';
import {
  getKPIs,
  getDailyRevenuePerMonth,
  getTimesOfBooking,
  getTopTen,
  getCardvsCash,
  bookingsTodayProps,
  bookingsThisMonthProps,
  revenueTodayProps,
  revenueMonthProps,
  dailyRevenuePerMonthProps,
  timesOfBookingProps,
  topTenProps,
  cardVsCashProps,
} from './FranchiseDashboardScreenData';
import './FranchiseDashboardScreen.css';
import { getShops } from '../../../api/FranchiseAPI';

const FranchiseDashboardScreen = (props) => {
  const [{ token, user }] = useStateValue();
  const [kpiData, setKpiData] = useState({
    bookingsToday: {},
    bookingsThisMonth: {},
    revenueToday: {},
    revenueThisMonth: {},
  });
  const [lineChartData, setLineChartData] = useState({});
  const [horBarChartData, setHorBarChartData] = useState({});
  const [verBarChartData, setVerBarChartData] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('all');
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const isEmpty = (value) =>
    Boolean(value && typeof value === 'object') && !Object.keys(value).length;

  useEffect(() => {
    if (dropdownOptions.length === 0) {
      getFranchiseShops();
    }
  }, []);

  useEffect(() => {
    if (
      isEmpty(kpiData.bookingsToday) &&
      isEmpty(kpiData.bookingsThisMonth) &&
      isEmpty(kpiData.revenueToday) &&
      isEmpty(kpiData.revenueThisMonth)
    ) {
      const shop = selectedShop().id;
      getKPIData(shop);
    }
  }, [kpiData, dropdownOptions]);

  useEffect(() => {
    if (isEmpty(lineChartData)) {
      const shop = selectedShop().id;
      getLineChartData(shop);
    }
  }, [lineChartData, dropdownOptions]);

  useEffect(() => {
    if (isEmpty(horBarChartData)) {
      const shop = selectedShop().id;
      getHorBarChartData(shop);
    }
  }, [horBarChartData, dropdownOptions]);

  useEffect(() => {
    if (isEmpty(verBarChartData)) {
      const shop = selectedShop().id;
      getVerBarChartData(shop);
    }
  }, [verBarChartData, dropdownOptions]);

  useEffect(() => {
    if (isEmpty(doughnutChartData)) {
      const shop = selectedShop().id;
      getDoughnutChartData(shop);
    }
  }, [doughnutChartData, dropdownOptions]);

  const getFranchiseShops = async () => {
    try {
      const res = await getShops(token);
      if (res && res.success) {
        const shops = res.shops || [];
        setDropdownOptions([
          { key: 'all', text: i18n.t('allShopsDropdown'), value: 'all' },
          ...shops.map((shop) => ({ key: shop._id, text: shop.name, value: shop._id })),
        ]);
      } else {
        // TODO: error message
        setErrorWarningModalOptions();
      }
    } catch (error) {
      // TODO: error message
    }
  };

  const getKPIData = async (shop) => {
    const kpis = await getKPIs(token, shop);
    setKpiData(kpis);
  };

  const getLineChartData = async (shop) => {
    const lineChartData = await getDailyRevenuePerMonth(shop, token);
    setLineChartData(lineChartData);
  };

  const getHorBarChartData = async (shop) => {
    const horBarChartData = await getTimesOfBooking(shop, token);
    setHorBarChartData(horBarChartData);
  };

  const getVerBarChartData = async (shop) => {
    const verBarChartData = await getTopTen(shop, token);
    setVerBarChartData(verBarChartData);
  };

  const getDoughnutChartData = async (shop) => {
    const doughnutChartData = await getCardvsCash(shop, token);
    setDoughnutChartData(doughnutChartData);
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const selectedShop = () => {
    if (dropdownOptions.length === 0) {
      return { id: null, name: i18n.t('allShopsDropdown') };
    }
    const shop = dropdownOptions.find((option) => option.key === selectedDropdownOption);
    return { id: shop.key === 'all' ? null : shop.key, name: shop.text };
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('dashBoardTitle')}</p>
      </div>
      <div className="franchise-dashboard-container" style={{ overflowY: 'unset' }}>
        <Dropdown
          style={{
            fontSize: 26,
            fontFamily: 'Montserrat',
            fontWeight: 600,
            height: 30,
            marginTop: 10,
            marginBottom: 6,
            paddingLeft: 4,
          }}
          loading={dropdownOptions.length === 0}
          onChange={(_, data) => {
            if (data.value) {
              setSelectedDropdownOption(data.value);
              setKpiData({
                bookingsToday: {},
                bookingsThisMonth: {},
                revenueToday: {},
                revenueThisMonth: {},
              });
              setLineChartData({});
              setDoughnutChartData({});
              setHorBarChartData({});
              setVerBarChartData({});
            }
          }}
          className="icon"
          labeled
          options={dropdownOptions}
          text={dropdownOptions.length === 0 ? null : selectedShop().name}
        />
        <div
          style={{
            height: 1,
            borderTop: '1px solid #7268cd',
            marginBottom: 10,
            marginLeft: -2,
            marginRight: 0,
          }}
        />
        <div
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 220px)',
            paddingBottom: 100,
            paddingTop: 10,
            paddingLeft: 4,
            paddingRight: 4,
          }}
          className="franchise-dashboard-content"
        >
          <Grid doubling stackable columns={4}>
            <Grid.Column>
              <KPITile
                loading={isEmpty(kpiData.bookingsToday)}
                data={kpiData.bookingsToday}
                details={bookingsTodayProps}
                isCurrency={false}
              />
            </Grid.Column>
            <Grid.Column>
              <KPITile
                loading={isEmpty(kpiData.bookingsThisMonth)}
                data={kpiData.bookingsThisMonth}
                isCurrency={false}
                details={bookingsThisMonthProps}
              />
            </Grid.Column>
            <Grid.Column>
              <KPITile
                loading={isEmpty(kpiData.revenueToday)}
                data={kpiData.revenueToday}
                details={revenueTodayProps}
                isCurrency
              />
            </Grid.Column>
            <Grid.Column>
              <KPITile
                loading={isEmpty(kpiData.revenueThisMonth)}
                data={kpiData.revenueThisMonth}
                details={revenueMonthProps}
                isCurrency
              />
            </Grid.Column>
          </Grid>
          <Grid doubling stackable columns={2}>
            <Grid.Column width={10}>
              <LineChartTile
                loading={isEmpty(lineChartData)}
                data={lineChartData}
                details={dailyRevenuePerMonthProps}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <HorizontalBarChartTile
                loading={isEmpty(horBarChartData)}
                data={horBarChartData}
                details={timesOfBookingProps}
              />
            </Grid.Column>
          </Grid>
          <Grid doubling stackable columns={2}>
            <Grid.Column>
              <VerticalBarChartTile
                loading={isEmpty(verBarChartData)}
                data={verBarChartData}
                details={topTenProps}
              />
            </Grid.Column>
            <Grid.Column>
              <DoughnutChartTile
                loading={isEmpty(doughnutChartData)}
                data={doughnutChartData}
                details={cardVsCashProps}
              />
            </Grid.Column>
          </Grid>
        </div>
      </div>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default FranchiseDashboardScreen;
