/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button, Table } from 'semantic-ui-react';
import './BookingsScreen.css';
import i18n from '../../../i18n/config';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const BookingsTerminalReaderModal = (props) => {
  const { onClose, open, terminals, connectedReader, onReconnect, isConnectingToTerminal } = props;
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeModal = () => {
    onClose();
  };

  const connectToTerminalReader = (terminal) => {
    onReconnect(terminal);
  };

  const checkTerminalConnection = (terminal) =>
    connectedReader != null &&
    connectedReader.jackRabbitService &&
    connectedReader.jackRabbitService.connectionMgr &&
    connectedReader.jackRabbitService.connectionMgr &&
    connectedReader.jackRabbitService.connectionMgr.activeConnection &&
    connectedReader.jackRabbitService.connectionMgr.activeConnection.reader &&
    connectedReader.jackRabbitService.connectionMgr.activeConnection.reader.id === terminal.id;

  const renderTerminalConnection = (terminal) => {
    const styleForNotConnected = {
      verticalAlign: 'middle',
      fontWeight: 700,
      textAlign: 'center',
      padding: 5,
      height: 30,
      border: terminal.status === 'online' ? `2px solid red` : `2px solid #ff000070`,
      borderRadius: 8,
      color: terminal.status === 'online' ? `red` : `#ff000070`,
    };
    if (checkTerminalConnection(terminal)) {
      return (
        <p
          style={{
            verticalAlign: 'middle',
            fontWeight: 700,
            textAlign: 'center',
            padding: 5,
            height: 30,
            border: `2px solid mediumseagreen`,
            borderRadius: 8,
            color: 'mediumseagreen',
          }}
        >
          {i18n.t('connected')}
        </p>
      );
    }
    return (
      <>
        <p style={styleForNotConnected}>{i18n.t('notConnected')}</p>
      </>
    );
  };

  return (
    <>
      <AnimatedModal
        key={124323}
        open={open}
        dimmer="blurring"
        size="small"
        closeOnEscape
        closeOnDimmerClick
      >
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('cardReaders')}
          <Button
            key={12345666}
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content key={12345434} scrolling>
          <div style={{ marginTop: '10px' }}>
            <Table className="bookings-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{i18n.t('description')}</Table.HeaderCell>
                  <Table.HeaderCell>{i18n.t('status')}</Table.HeaderCell>
                  <Table.HeaderCell>{i18n.t('connectionStatus')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {terminals.map((terminal) => (
                  <>
                    <Table.Row
                      style={{ fontWeight: 600, borderTop: 'none' }}
                      disabled={terminal.status === 'offline'}
                    >
                      <Table.Cell key={130}>{terminal.label}</Table.Cell>
                      <Table.Cell key={131}>
                        <p
                          style={{
                            verticalAlign: 'middle',
                            fontWeight: 700,
                            textAlign: 'center',
                            padding: 5,
                            height: 30,
                            border: `2px solid ${
                              terminal.status === 'online' ? 'mediumseagreen' : '#ff000070'
                            }`,
                            borderRadius: 8,
                            color: terminal.status === 'online' ? 'mediumseagreen' : '#ff000070',
                          }}
                        >
                          {terminal.status === 'online' ? 'ONLINE' : 'OFFLINE'}
                        </p>
                      </Table.Cell>
                      <Table.Cell>{renderTerminalConnection(terminal)}</Table.Cell>
                      <Table.Cell>
                        <Button
                          disabled={
                            terminal.status === 'offline' || isConnectingToTerminal === true
                          }
                          key={12345666}
                          content={
                            checkTerminalConnection(terminal)
                              ? i18n.t('connect')
                              : i18n.t('reconnect')
                          }
                          color="teal"
                          onClick={() => connectToTerminalReader(terminal)}
                          fluid
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default BookingsTerminalReaderModal;
