/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Loader, Modal, Table } from 'semantic-ui-react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import {
  getBookingsByEmployee,
  getEmployeesReportPDF,
  getExistingZBon,
} from '../../../api/BookingAPI';
import { asCurrency } from '../../../utils/NumberFormatter';
import { Printer } from '../../../utils/Printer';
import { showToast } from '../../../components/general/Toast/Toast';
import i18n from '../../../i18n/config';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const EmployeeSummaryModal = (props) => {
  const { onClose, open, date } = props;
  const [{ token, shop }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [checkedZBonExistence, setCheckedZBonExistence] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [employeeSummaryDate, setEmployeeSummaryDate] = useState({
    startTime: null,
    endTime: null,
    endDate: null,
  });

  useEffect(() => {
    const momentDate = moment(date).startOf('day');
    const momentEndTime = moment(employeeSummaryDate.endTime, 'HH:mm');
    const momentStartTime = moment(employeeSummaryDate.startTime, 'HH:mm');
    if (
      momentDate.isSame(moment(employeeSummaryDate.endDate).startOf('day')) &&
      momentStartTime.isAfter(momentEndTime)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('startTimeError'),
      });
      setEmployeeSummaryDate({
        ...employeeSummaryDate,
        startTime: '',
      });
    }
  }, [employeeSummaryDate.startTime]);

  useEffect(() => {
    const momentDate = moment(date).startOf('day');
    const momentEndTime = moment(employeeSummaryDate.endTime, 'HH:mm');
    const momentStartTime = moment(employeeSummaryDate.startTime, 'HH:mm');
    if (
      momentDate.isSame(moment(employeeSummaryDate.endDate).startOf('day')) &&
      momentEndTime.isBefore(momentStartTime)
    ) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('endTimeError'),
      });
      setEmployeeSummaryDate({
        ...employeeSummaryDate,
        endTime: '',
      });
    }
  }, [employeeSummaryDate.endTime]);

  useEffect(() => {
    if (open === true && checkedZBonExistence === false) {
      checkForExistingZBon();
    }
  });

  const checkForExistingZBon = async () => {
    try {
      setCheckedZBonExistence(true);
      setShowDatePicker(false);
      setIsLoading(true);
      const res = await getExistingZBon(shop._id, moment(date).format(), token);
      if (res.success) {
        const startDate = res.zBon.issueDateStart;
        const endDate = res.zBon.issueDateEnd;
        setEmployeeSummaryDate({
          startTime: moment(startDate).format('HH:mm'),
          endDate: moment(endDate),
          endTime: moment(endDate).format('HH:mm'),
        });
        const res2 = await getBookingsByEmployee(
          token,
          moment(startDate).format(),
          moment(endDate).format(),
          shop._id
        );
        if (res2 && res2.success) {
          setEmployeeData(res2.bookings);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('employeeSumError'),
          });
        }
      } else {
        setShowDatePicker(true);
      }
    } catch (error) {
      console.log(error);
      setShowDatePicker(true);
      setIsLoading(false);
    }
  };

  const loadData = async () => {
    setShowDatePicker(false);
    setIsLoading(true);
    try {
      const res = await getBookingsByEmployee(
        token,
        moment(date).startOf('day').add(moment.duration(employeeSummaryDate.startTime)).format(),
        moment(employeeSummaryDate.endDate)
          .startOf('day')
          .add(moment.duration(employeeSummaryDate.endTime))
          .format(),
        shop._id
      );
      if (res && res.success) {
        setEmployeeData(res.bookings);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('employeeSumError'),
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('employeeSumError'),
      });
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setEmployeeData(null);
    setIsLoading(false);
    setShowDatePicker(true);
    setEndDatePickerOpen(false);
    setIsDownloading(false);
    setEmployeeSummaryDate({ startTime: null, endTime: null, endDate: null });
    setCheckedZBonExistence(false);
    onClose();
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorEmployeeSummary${printerDevice.printer.description}`,
    });
  };

  const printEmployeeSummary = () => {
    if (
      shop &&
      shop.printers &&
      shop.printers.length > 0 &&
      shop.printers.find((printer) => printer.isMain === true)
    ) {
      const shopPrinter = shop.printers.find((printer) => printer.isMain === true);
      const printerDevice = new Printer(shopPrinter);
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
      printerDevice.printEmployeeSummary(
        employeeData,
        shop.name,
        moment(date).startOf('day').add(moment.duration(employeeSummaryDate.startTime)).format(),
        moment(employeeSummaryDate.endDate)
          .startOf('day')
          .add(moment.duration(employeeSummaryDate.endTime))
          .format(),
        paperSize,
        shop.tipsAllowed === true
      );
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('printerNoKundenbonError1'),
      });
    }
  };

  const downloadEmployeeReportPDF = async () => {
    try {
      setIsDownloading(true);
      await getEmployeesReportPDF(
        token,
        shop._id,
        moment(date).startOf('day').add(moment.duration(employeeSummaryDate.startTime)).format(),
        moment(employeeSummaryDate.endDate)
          .startOf('day')
          .add(moment.duration(employeeSummaryDate.endTime))
          .format()
      );
      setIsDownloading(false);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('employeeSumPDFError'),
      });
      setIsDownloading(false);
    }
  };

  const parseData = () =>
    employeeData &&
    employeeData.length > 0 &&
    employeeData.map((employee, index) => (
      <>
        <Table.Row>
          <Table.Cell>
            <p style={{ fontWeight: '600' }}>{Object.keys(employee)[0]}</p>
          </Table.Cell>
          <Table.Cell collapsing>
            {`${asCurrency(Object.values(employee)[0].amount / 100)}€`}
          </Table.Cell>
          {shop.tipsAllowed && shop.tipsAllowed === true && (
            <Table.Cell collapsing>
              {`${asCurrency(Object.values(employee)[0].tipAmount / 100)}€`}
            </Table.Cell>
          )}
        </Table.Row>
      </>
    ));

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size={showDatePicker ? 'mini' : 'tiny'}
        closeOnEscape
        closeOnDimmerClick
      >
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('employeeSummary')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ paddingTop: 0 }}>
          {showDatePicker ? (
            <>
              <div
                style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
              >
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('begin')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', width: 100 }}>
                      <ReactDatePicker
                        className="day-picker-zbon"
                        locale="de"
                        disabledKeyboardNavigation
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        selected={date}
                        readOnly
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        placeholder="--"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setEmployeeSummaryDate({
                              ...employeeSummaryDate,
                              startTime: e.target.value,
                            });
                          }
                        }}
                        value={employeeSummaryDate.startTime}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={`0:00${i18n.t('uhrGerman')}`}
                    icon="undo"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setEmployeeSummaryDate({ ...employeeSummaryDate, startTime: '00:00' });
                    }}
                  />
                </div>
                <div style={{ width: 1, height: 80, marginTop: 20, backgroundColor: '#bbb' }} />
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('end')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none' }} onClick={() => setEndDatePickerOpen(true)}>
                      <ReactDatePicker
                        className="day-picker-zbon"
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        showPopperArrow={false}
                        placeholderText="--"
                        locale="de"
                        calendarStartDay={1}
                        minDate={moment(date).startOf('day').toDate()}
                        maxDate={moment(date).startOf('day').add(1, 'days').endOf('day').toDate()}
                        selected={employeeSummaryDate.endDate}
                        onClickOutside={() => setEndDatePickerOpen(false)}
                        readOnly
                        open={endDatePickerOpen === true}
                        onChange={(date) => {
                          setEndDatePickerOpen(false);
                          setEmployeeSummaryDate({
                            ...employeeSummaryDate,
                            endDate: date,
                            endTime: '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        placeholder="--"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setEmployeeSummaryDate({
                              ...employeeSummaryDate,
                              endTime: e.target.value,
                            });
                          }
                        }}
                        value={employeeSummaryDate.endTime}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={i18n.t('now')}
                    icon="clock"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setEmployeeSummaryDate({
                        ...employeeSummaryDate,
                        endDate: moment().toDate(),
                        endTime: moment().format('HH:mm'),
                      });
                    }}
                    disabled={moment().isAfter(moment(date).endOf('day').add(1, 'days'))}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {employeeData === null ? (
                <>
                  <Loader
                    inline="centered"
                    size="medium"
                    active={isLoading}
                    className="bookings-create-loader"
                  />
                </>
              ) : (
                <>
                  <div style={{ display: 'flex' }}>
                    <p style={{ fontWeight: 600, width: '100px' }}>{i18n.t('begin')}:</p>
                    <p>
                      {`${moment(date)
                        .startOf('day')
                        .add(moment.duration(employeeSummaryDate.startTime))
                        .format(`DD.MM.YYYY, HH:mm[${i18n.t('uhrGerman')}]`)}`}
                    </p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <p style={{ fontWeight: 600, width: '100px' }}>{i18n.t('end')}:</p>
                    <p>
                      {`${moment(employeeSummaryDate.endDate)
                        .startOf('day')
                        .add(moment.duration(employeeSummaryDate.endTime))
                        .format(`DD.MM.YYYY, HH:mm[${i18n.t('uhrGerman')}]`)}`}
                    </p>
                  </div>
                  <div
                    className="bookings-table-container"
                    style={{ marginTop: '10px', height: 'unset' }}
                  >
                    <Table fixed celled className="bookings-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width="2">{i18n.t('employees')}</Table.HeaderCell>
                          <Table.HeaderCell width="2">{i18n.t('sum')}</Table.HeaderCell>
                          {shop.tipsAllowed && shop.tipsAllowed === true && (
                            <Table.HeaderCell width="2">{i18n.t('tips2')}</Table.HeaderCell>
                          )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>{employeeData === null ? [] : parseData()}</Table.Body>
                    </Table>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          {showDatePicker ? (
            <>
              <Button
                content={i18n.t('createReport')}
                color="teal"
                disabled={
                  moment(employeeSummaryDate.endDate).isValid() === false ||
                  employeeSummaryDate.endTime == null ||
                  employeeSummaryDate.endTime === '' ||
                  employeeSummaryDate.startTime == null ||
                  employeeSummaryDate.startTime === ''
                }
                onClick={async () => {
                  await loadData();
                }}
              />
            </>
          ) : (
            <>
              {employeeData != null ? (
                <>
                  <Button
                    icon="print"
                    content={i18n.t('print')}
                    color="blue"
                    onClick={printEmployeeSummary}
                    disabled={employeeData && employeeData.length === 0}
                  />
                  <Button
                    floated="left"
                    icon="download"
                    content={i18n.t('appPaySuccess7')}
                    color="teal"
                    onClick={downloadEmployeeReportPDF}
                    loading={isDownloading}
                    disabled={(employeeData && employeeData.length === 0) || isDownloading}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default EmployeeSummaryModal;
