/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import KPITile from '../../../components/shop/KPITile/KPITile';
import LineChartTile from '../../../components/shop/LineChartTile/LineChartTile';
import HorizontalBarChartTile from '../../../components/shop/HorizontalBarChartTile/HorizontalBarChartTile';
import {
  getKPIs,
  getDailyRevenuePerMonth,
  getTimesOfBooking,
  getTopTen,
  getCardvsCash,
  bookingsTodayProps,
  bookingsThisMonthProps,
  revenueTodayProps,
  revenueMonthProps,
  dailyRevenuePerMonthProps,
  timesOfBookingProps,
  topTenProps,
  cardVsCashProps,
} from './DashboardScreenData';
import './DashboardScreen.css';
import VerticalBarChartTile from '../../../components/shop/VerticalBarChartTile/VerticalBarChartTile';
import DoughnutChartTile from '../../../components/shop/DoughnutChartTile/DoughnutChartTile';
import { updateShop } from '../../../api/ShopAPI';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const DashboardScreen = () => {
  const [{ token, shop }] = useStateValue();
  const [kpiData, setKpiData] = useState({
    bookingsToday: {},
    bookingsThisMonth: {},
    revenueToday: {},
    revenueThisMonth: {},
  });
  const [lineChartData, setLineChartData] = useState({});
  const [horBarChartData, setHorBarChartData] = useState({});
  const [verBarChartData, setVerBarChartData] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState({});
  const [isUsingLastZbonTime, setIsUsingLastZbonTime] = useState(
    !!(shop && shop.isUsingLastZbonTime === true)
  );
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const isEmpty = (value) =>
    Boolean(value && typeof value === 'object') && !Object.keys(value).length;

  useEffect(() => {
    if (
      isEmpty(kpiData.bookingsToday) &&
      isEmpty(kpiData.bookingsThisMonth) &&
      isEmpty(kpiData.revenueToday) &&
      isEmpty(kpiData.revenueThisMonth)
    ) {
      getKPIData();
    }
  }, [kpiData]);

  useEffect(() => {
    if (isEmpty(lineChartData)) {
      getLineChartData();
    }
  }, [lineChartData]);

  useEffect(() => {
    if (isEmpty(horBarChartData)) {
      getHorBarChartData();
    }
  }, [horBarChartData]);

  useEffect(() => {
    if (isEmpty(verBarChartData)) {
      getVerBarChartData();
    }
  }, [verBarChartData]);

  useEffect(() => {
    if (isEmpty(doughnutChartData)) {
      getDoughnutChartData();
    }
  }, [doughnutChartData]);

  const getKPIData = async (isFromToggle) => {
    const kpis = await getKPIs(token, isFromToggle);
    if (isFromToggle) {
      setKpiData({
        ...kpiData,
        revenueToday: kpis.revenueToday,
        bookingsToday: kpis.bookingsToday,
      });
      return;
    }
    setKpiData(kpis);
  };

  const getLineChartData = async () => {
    const lineChartData = await getDailyRevenuePerMonth(token);
    setLineChartData(lineChartData);
  };

  const getHorBarChartData = async () => {
    const horBarChartData = await getTimesOfBooking(token);
    setHorBarChartData(horBarChartData);
  };

  const getVerBarChartData = async () => {
    const verBarChartData = await getTopTen(token);
    setVerBarChartData(verBarChartData);
  };

  const getDoughnutChartData = async () => {
    const doughnutChartData = await getCardvsCash(token);
    setDoughnutChartData(doughnutChartData);
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const changeTimeForKPI = async (usingLastZbon) => {
    if (usingLastZbon === isUsingLastZbonTime) return;
    try {
      setKpiData({
        ...kpiData,
        bookingsToday: {},
        revenueToday: {},
      });
      setIsUsingLastZbonTime(!isUsingLastZbonTime);
      const res = await updateShop(token, { isUsingLastZbonTime: usingLastZbon });
      if (res && res.success) {
        await getKPIData(true);
      } else {
        setIsUsingLastZbonTime(isUsingLastZbonTime);
        setErrorWarningModalOptions({ open: true, message: i18n.t('lastZbonKpiError') });
      }
    } catch (error) {
      setIsUsingLastZbonTime(isUsingLastZbonTime);
      setErrorWarningModalOptions({ open: true, message: i18n.t('lastZbonKpiError') });
    }
  };

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('dashBoardTitle')}</p>
        <p className="cockpit-subtitle">{i18n.t('dashBoardSubTitle')}</p>
      </div>
      <div className="dashboard-container">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="dashboard-toggle">
          <Button.Group
            style={{
              fontFamily: 'Montserrat',
              border: '1px solid #d7d3f6',
              borderRadius: 6,
              height: 28,
              overflow: 'hidden',
              marginBottom: 8,
            }}
          >
            <Button
              style={{
                fontFamily: 'Montserrat',
                fontSize: 12,
                borderRadiusLeft: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 7,
                height: 27,
                borderRight: '1px solid #d7d3f6',
                backgroundColor: isUsingLastZbonTime ? 'white' : '#e6e3fa',
                color: isUsingLastZbonTime ? '#7268cdc2' : '#7268cd',
              }}
              onClick={() => {
                changeTimeForKPI(false);
              }}
            >
              {i18n.t('today')}
            </Button>
            <Button
              style={{
                fontFamily: 'Montserrat',
                fontSize: 12,
                borderRadiusRight: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 7,
                height: 27,
                backgroundColor: isUsingLastZbonTime ? '#e6e3fa' : 'white',
                color: isUsingLastZbonTime ? '#7268cd' : '#7268cdc2',
              }}
              onClick={() => {
                changeTimeForKPI(true);
              }}
            >
              {i18n.t('lastZBon')}
            </Button>
          </Button.Group>
        </div>
        <Grid doubling stackable columns={4}>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.bookingsToday)}
              data={kpiData.bookingsToday}
              details={bookingsTodayProps}
              isCurrency={false}
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.bookingsThisMonth)}
              data={kpiData.bookingsThisMonth}
              isCurrency={false}
              details={bookingsThisMonthProps}
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.revenueToday)}
              data={kpiData.revenueToday}
              details={revenueTodayProps}
              isCurrency
            />
          </Grid.Column>
          <Grid.Column>
            <KPITile
              loading={isEmpty(kpiData.revenueThisMonth)}
              data={kpiData.revenueThisMonth}
              details={revenueMonthProps}
              isCurrency
            />
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column width={10}>
            <LineChartTile
              loading={isEmpty(lineChartData)}
              data={lineChartData}
              details={dailyRevenuePerMonthProps}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <HorizontalBarChartTile
              loading={isEmpty(horBarChartData)}
              data={horBarChartData}
              details={timesOfBookingProps}
            />
          </Grid.Column>
        </Grid>
        <Grid doubling stackable columns={2}>
          <Grid.Column>
            <VerticalBarChartTile
              loading={isEmpty(verBarChartData)}
              data={verBarChartData}
              details={topTenProps}
            />
          </Grid.Column>
          <Grid.Column>
            <DoughnutChartTile
              loading={isEmpty(doughnutChartData)}
              data={doughnutChartData}
              details={cardVsCashProps}
            />
          </Grid.Column>
        </Grid>
      </div>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default DashboardScreen;
