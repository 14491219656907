/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, Dropdown, Form, Image, Input, Modal } from 'semantic-ui-react';
import { createShop, getAllShops } from '../../../api/ShopAPI';
import { createFranchisorUser, createOwnerUser } from '../../../api/UserAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import './CreateShopScreen.css';

const CreateShopScreen = () => {
  const [{ token }, dispatch] = useStateValue();
  const [shopOwnerEmail, setShopOwnerEmail] = useState('');
  const [shopOwnerPw, setShopOwnerPw] = useState('');
  const [isShopOwnerCreated, setIsShopOwnerCreated] = useState(false);
  const [shopName, setShopName] = useState('');
  const [street, setStreet] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('Deutschland');
  const [shopEmail, setShopEmail] = useState('');
  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [iban, setIban] = useState('');
  const [bankCountry, setBankCountry] = useState('DE');
  const [bankCurrency, setBankCurrency] = useState('eur');
  const [imageForth, setImageForth] = useState(null);
  const [imageForthBase64, setImageForthBase64] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [imageBackBase64, setImageBackBase64] = useState(null);
  const [imageVerification, setImageVerification] = useState(null);
  const [imageVerificationBase64, setImageVerificationBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ownerUser, setOwnerUser] = useState(null);
  const [taxId, setTaxId] = useState('');
  const [rentedHardware, setRentedHardware] = useState({
    iPads: [],
    posPrinters: [],
    terminalReaders: [],
  });
  const [newIpad, setNewIpad] = useState({
    model: '',
    pricePerMonth: 0,
    serialNumber: '',
  });
  const [newPosPrinter, setNewPosPrinter] = useState({
    model: '',
    pricePerMonth: 0,
    serialNumber: '',
  });
  const [pricing, setPricing] = useState({
    monthly: null,
    yearly: null,
    cashAppMultiplier: null,
    cashAppAdder: null,
    cashTerminalMultiplier: null,
    cashTerminalAdder: null,
    cardAppMultiplier: null,
    cardAppAdder: null,
    cardTerminalMultiplier: null,
    cardTerminalAdder: null,
  });
  const [monthly, setMonthly] = useState('');
  const [yearly, setYearly] = useState('');
  const [cashAppMultiplier, setCashAppMultiplier] = useState('');
  const [cashAppAdder, setCashAppAdder] = useState('');
  const [cashTerminalMultiplier, setCashTerminalMultiplier] = useState('');
  const [cashTerminalAdder, setCashTerminalAdder] = useState('');
  const [cardAppMultiplier, setCardAppMultiplier] = useState('');
  const [cardAppAdder, setCardAppAdder] = useState('');
  const [cardTerminalMultiplier, setCardTerminalMultiplier] = useState('');
  const [cardTerminalAdder, setCardTerminalAdder] = useState('');
  const [successModal, setSuccessModal] = useState({ open: false, message: '' });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [franchisorShopIds, setFranchisorShopIds] = useState([]);
  const [franchisorEmail, setFranchisorEmail] = useState('');
  const [franchisorPw, setFranchisorPw] = useState('');
  const [allShops, setAllShops] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);

  const reset = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (dropDownOptions.length === 0) {
      getShops();
    }
  }, []);

  const getShops = async () => {
    const res = await getAllShops(token);
    if (res && res.success === true) {
      setAllShops(res.shops);
      setDropDownOptions(
        res.shops.map((shop) => ({
          key: shop._id.toString(),
          text: `${shop.name} ${shop._id.toString()}`,
          value: shop._id.toString(),
        }))
      );
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.replaceAll(/\s/g, ''));
  };

  const onCreateFranchiseUser = async () => {
    if (franchisorShopIds.length === 0) {
      setErrorWarningModalOptions({ open: true, message: 'Bitte shops auswählen pls.' });
    }
    if (validateEmail(franchisorEmail)) {
      if (validatePassword(franchisorPw)) {
        setIsLoading(true);
        const res = await createFranchisorUser(
          franchisorEmail,
          franchisorPw,
          franchisorShopIds,
          token
        );
        if (res && res.success) {
          setIsLoading(false);
          setSuccessModal({ open: true, message: 'Franchisor creation was successful!' });
          setFranchisorShopIds([]);
          setFranchisorEmail('');
          setFranchisorPw('');
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: 'Could not create franchiser user.',
          });
          setIsLoading(false);
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message:
            'Password has to be at least 8 characters long, contain 1 uppercase, 1 lowercase and at least 1 number.',
        });
      }
    } else {
      setErrorWarningModalOptions({ open: true, message: 'Not a valid E-mail Address.' });
    }
  };

  const onCreateUser = async () => {
    if (validateEmail(shopOwnerEmail)) {
      if (validatePassword(shopOwnerPw)) {
        setIsLoading(true);
        const res = await createOwnerUser(shopOwnerEmail, shopOwnerPw, token);
        if (res && res.success) {
          setOwnerUser(res.user);
          setIsShopOwnerCreated(true);
          setIsLoading(false);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: 'Could not create user.',
          });
          setIsLoading(false);
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message:
            'Password has to be at least 8 characters long, contain 1 uppercase, 1 lowercase and at least 1 number.',
        });
      }
    } else {
      setErrorWarningModalOptions({ open: true, message: 'Not a valid E-mail Address.' });
    }
  };

  const onCreateShop = async () => {
    if (
      shopName &&
      street &&
      city &&
      zip &&
      city &&
      country &&
      shopEmail &&
      validateEmail(shopEmail) &&
      ownerFirstName &&
      ownerLastName &&
      shopOwnerEmail &&
      ownerPhone &&
      idNumber &&
      imageForth &&
      imageForthBase64 &&
      imageBack &&
      imageBackBase64 &&
      imageVerification &&
      imageVerificationBase64 &&
      bankName &&
      bankCurrency &&
      bankCountry &&
      iban &&
      taxId &&
      pricing
    ) {
      const data = {};
      data.shop = {};
      data.shop.name = shopName;
      data.shop.address = {};
      data.shop.address.street = street;
      data.shop.address.city = city;
      data.shop.address.zip = zip;
      data.shop.address.country = country;
      data.shop.email = shopEmail;
      data.shop.taxId = taxId;
      data.shop.shopOwner = {};
      data.shop.shopOwner.firstName = ownerFirstName;
      data.shop.shopOwner.lastName = ownerLastName;
      data.shop.shopOwner.email = shopOwnerEmail;
      data.shop.shopOwner.phoneNumber = ownerPhone;
      data.shop.shopOwner.idNumber = idNumber;
      data.shop.shopOwner.idPhotos = {};
      data.shop.shopOwner.idPhotos.forth = imageForthBase64;
      data.shop.shopOwner.idPhotos.back = imageBackBase64;
      data.shop.shopOwner.verification = imageVerificationBase64;
      data.shop.bankAccount = {};
      data.shop.bankAccount.country = bankCountry;
      data.shop.bankAccount.currency = bankCurrency;
      data.shop.bankAccount.name = bankName;
      data.shop.bankAccount.iban = iban;
      data.shop.rentedHardware = rentedHardware;
      data.shop.pricing = pricing;
      setIsLoading(true);
      const res = await createShop(ownerUser.tokens[0].token, data);
      if (res && res.success) {
        setIsLoading(false);
        setSuccessModal({ open: true, message: 'Shop creation was successful!' });
      } else {
        setIsLoading(false);
        setErrorWarningModalOptions({ open: true, message: 'Shop could not be created.' });
      }
    } else {
      setErrorWarningModalOptions({ open: true, message: 'All fields must be filled out. ' });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSuccessModalOk = () => {
    setSuccessModal({ open: false, message: '' });
    reset();
  };

  const onIDForthUpload = (e) => {
    if (e.target.files[0].size > 5000000) {
      setErrorWarningModalOptions({
        open: true,
        message: 'Image is too big. Please choose an image up to 500 kB.',
      });
    } else {
      setImageForth(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.onload = (upload) => setImageForthBase64(upload.target.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onIDBackUpload = (e) => {
    if (e.target.files[0].size > 5000000) {
      setErrorWarningModalOptions({
        open: true,
        message: 'Image is too big. Please choose an image up to 500 kB.',
      });
    } else {
      setImageBack(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.onload = (upload) => setImageBackBase64(upload.target.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onVerificationUpload = (e) => {
    if (e.target.files[0].size > 5000000) {
      setErrorWarningModalOptions({
        open: true,
        message: 'Image is too big. Please choose an image up to 500 kB.',
      });
    } else {
      setImageVerification(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.onload = (upload) => setImageVerificationBase64(upload.target.result);
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const validatePassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password.replaceAll(/\s/g, ''));
  };

  const parseRentalHardwareIPad = () => (
    <Form>
      {rentedHardware.iPads.length > 0 ? (
        rentedHardware.iPads.map((iPad, index) => (
          <>
            <Form.Group className="items-specialSelections-group">
              <Form.Input
                placeholder="Modell"
                name={`${iPad.model}-${index}`}
                key={`${iPad.model}-${index}`}
                id={`${iPad.model}-${index}`}
                defaultValue={iPad.model}
                onChange={(e) => {
                  rentedHardware.iPads[index].model = e.target.value;
                }}
              />
              <CurrencyInput
                className="items-specialSelections-price"
                placeholder="Price Per Month"
                disableGroupSeparators
                allowNegativeValue={false}
                id={`${iPad.pricePerMonth}-${index}`}
                name={`${iPad.pricePerMonth}-${index}`}
                key={`${iPad.pricePerMonth}-${index}`}
                intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                defaultValue={`${iPad.pricePerMonth / 100}`}
                onValueChange={(value) => {
                  if (value) {
                    const val = parseFloat(value.replace(',', '.'));
                    rentedHardware.iPads[index].pricePerMonth = val * 100;
                  }
                }}
              />
              <Form.Input
                placeholder="Serial Number"
                name={`${iPad.serialNumber}-${index}`}
                key={`${iPad.serialNumber}-${index}`}
                id={`${iPad.serialNumber}-${index}`}
                defaultValue={iPad.serialNumber}
                onChange={(e) => {
                  rentedHardware.iPads[index].serialNumber = e.target.value;
                }}
              />
              <Form.Button
                basic
                color="red"
                icon="delete"
                key={`${iPad.model}-${index}3`}
                onClick={() => {
                  rentedHardware.iPads.splice(index, 1);
                  setRentedHardware(rentedHardware);
                  setNewIpad({ model: '', pricePerMonth: 0, serialNumber: '' });
                }}
              />
            </Form.Group>
          </>
        ))
      ) : (
        <></>
      )}
      <Form.Group className="items-specialSelections-group" key="specialSelection2">
        <Form.Input
          placeholder="Model"
          key="specialSelection3"
          name="model"
          value={newIpad.model}
          onChange={(e) => {
            setNewIpad({
              model: e.target.value,
              pricePerMonth: newIpad.pricePerMonth,
              serialNumber: newIpad.serialNumber,
            });
          }}
        />
        <CurrencyInput
          className="items-specialSelections-price"
          placeholder="Price per Month"
          name="price"
          key="specialSelection4"
          disableGroupSeparators
          allowNegativeValue={false}
          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
          onValueChange={(value) => {
            setNewIpad({
              model: newIpad.model,
              pricePerMonth: value,
              serialNumber: newIpad.serialNumber,
            });
          }}
          value={newIpad.pricePerMonth}
        />
        <Form.Input
          placeholder="Serial Number"
          key="specialSelection5"
          name="model"
          value={newIpad.serialNumber}
          onChange={(e) => {
            setNewIpad({
              serialNumber: e.target.value,
              pricePerMonth: newIpad.pricePerMonth,
              model: newIpad.model,
            });
          }}
        />
        <Form.Button
          color="green"
          basic
          key="specialSelection6"
          icon="add"
          disabled={!(newIpad.model && newIpad.pricePerMonth && newIpad.serialNumber)}
          onClick={() => {
            let { pricePerMonth } = newIpad;
            if (pricePerMonth.includes(',')) {
              pricePerMonth = pricePerMonth.replace(',', '.');
            }
            rentedHardware.iPads.push({
              model: newIpad.model,
              pricePerMonth: parseFloat(pricePerMonth) * 100,
              serialNumber: newIpad.serialNumber,
            });
            setRentedHardware(rentedHardware);
            setNewIpad({ model: '', pricePerMonth: 0, serialNumber: '' });
          }}
        />
      </Form.Group>
    </Form>
  );

  const parsePricing = () => (
    <>
      <Form>
        <p>{`Yearly ${pricing.yearly ? `- ${pricing.yearly / 100} €` : ''}`}</p>
        <Form.Group className="items-specialSelections-group" key="yearly1">
          <CurrencyInput
            className="items-specialSelections-price"
            placeholder="Jährlich"
            name="yearly2"
            key="yearly2"
            disableGroupSeparators
            allowNegativeValue={false}
            intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
            onValueChange={(value) => {
              setYearly(value);
            }}
            value={yearly}
          />
          <Form.Button
            color="green"
            basic
            key="yearly3"
            icon="add"
            disabled={yearly === ''}
            onClick={() => {
              let tmpYearly = yearly;
              if (tmpYearly.includes(',')) {
                tmpYearly = yearly.replace(',', '.');
              }
              setPricing({ ...pricing, yearly: parseFloat(tmpYearly) * 100 });
            }}
          />
        </Form.Group>
        <p>{`Monthly ${pricing.monthly ? `- ${pricing.monthly / 100} €` : ''}`}</p>
        <Form.Group className="items-specialSelections-group" key="monthly1">
          <CurrencyInput
            className="items-specialSelections-price"
            placeholder="Monatlich"
            name="monthly2"
            key="monthly2"
            disableGroupSeparators
            allowNegativeValue={false}
            intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
            onValueChange={(value) => {
              setMonthly(value);
            }}
            value={monthly}
          />
          <Form.Button
            color="green"
            basic
            key="monthly3"
            icon="add"
            disabled={monthly === ''}
            onClick={() => {
              let tmp = monthly;
              if (tmp.includes(',')) {
                tmp = monthly.replace(',', '.');
              }
              setPricing({ ...pricing, monthly: parseFloat(tmp) * 100 });
            }}
          />
        </Form.Group>
        <p>
          {`Cash App Costs ${
            pricing.cashAppAdder && pricing.cashAppMultiplier
              ? `- Add.: ${pricing.cashAppAdder}, Mult.: ${pricing.cashAppMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="cac1">
          <Form.Input
            placeholder="Multiplier"
            key="cac2"
            name="cac2"
            value={cashAppMultiplier}
            type="number"
            onChange={(e) => {
              setCashAppMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="cac3"
            name="cac3"
            type="number"
            value={cashAppAdder}
            onChange={(e) => {
              setCashAppAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="cac4"
            icon="add"
            disabled={cashAppAdder === '' || cashAppMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cashAppMultiplier, cashAppAdder });
            }}
          />
        </Form.Group>
        <p>
          {`Cash Terminal Costs ${
            pricing.cashTerminalAdder && pricing.cashTerminalMultiplier
              ? `- Add.: ${pricing.cashTerminalAdder}, Mult.: ${pricing.cashTerminalMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="ctc1">
          <Form.Input
            placeholder="Multiplier"
            key="ctc2"
            name="ctc2"
            value={cashTerminalMultiplier}
            type="number"
            onChange={(e) => {
              setCashTerminalMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="ctc3"
            name="ctc3"
            type="number"
            value={cashTerminalAdder}
            onChange={(e) => {
              setCashTerminalAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="ctc4"
            icon="add"
            disabled={cashTerminalAdder === '' || cashTerminalMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cashTerminalAdder, cashTerminalMultiplier });
            }}
          />
        </Form.Group>
        <p>
          {`Card App Costs ${
            pricing.cardAppAdder && pricing.cardAppMultiplier
              ? `- Add.: ${pricing.cardAppAdder}, Mult.: ${pricing.cardAppMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="caac1">
          <Form.Input
            placeholder="Multiplier"
            key="caac2"
            name="caac2"
            value={cardAppMultiplier}
            type="number"
            onChange={(e) => {
              setCardAppMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="caac3"
            name="caac3"
            type="number"
            value={cardAppAdder}
            onChange={(e) => {
              setCardAppAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="caac4"
            icon="add"
            disabled={cardAppAdder === '' || cardAppMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cardAppMultiplier, cardAppAdder });
            }}
          />
        </Form.Group>
        <p>
          {`Card Terminal Costs ${
            pricing.cardTerminalAdder && pricing.cardTerminalMultiplier
              ? `- Add.: ${pricing.cardTerminalAdder}, Mult.: ${pricing.cardTerminalMultiplier}`
              : ''
          }`}
        </p>
        <Form.Group className="items-specialSelections-group" key="catc1">
          <Form.Input
            placeholder="Multiplier"
            key="catc2"
            name="catc2"
            value={cardTerminalMultiplier}
            type="number"
            onChange={(e) => {
              setCardTerminalMultiplier(e.target.value);
            }}
          />
          <Form.Input
            placeholder="Adder"
            key="catc3"
            name="catc3"
            type="number"
            value={cardTerminalAdder}
            onChange={(e) => {
              setCardTerminalAdder(e.target.value);
            }}
          />
          <Form.Button
            color="green"
            basic
            key="catc4"
            icon="add"
            disabled={cardTerminalAdder === '' || cardTerminalMultiplier === ''}
            onClick={() => {
              setPricing({ ...pricing, cardTerminalMultiplier, cardTerminalAdder });
            }}
          />
        </Form.Group>
      </Form>
    </>
  );

  const parseRentalHardwarePosPrinter = () => (
    <Form>
      {rentedHardware.posPrinters.length > 0 ? (
        rentedHardware.posPrinters.map((posPrinter, index) => (
          <>
            <Form.Group className="items-specialSelections-group">
              <Form.Input
                placeholder="Modell"
                name={`${posPrinter.model}-${index}`}
                key={`${posPrinter.model}-${index}`}
                id={`${posPrinter.model}-${index}`}
                defaultValue={posPrinter.model}
                onChange={(e) => {
                  rentedHardware.posPrinters[index].model = e.target.value;
                }}
              />
              <CurrencyInput
                className="items-specialSelections-price"
                placeholder="Price Per Month"
                disableGroupSeparators
                allowNegativeValue={false}
                id={`${posPrinter.pricePerMonth}-${index}`}
                name={`${posPrinter.pricePerMonth}-${index}`}
                key={`${posPrinter.pricePerMonth}-${index}`}
                intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                defaultValue={`${posPrinter.pricePerMonth / 100}`}
                onValueChange={(value) => {
                  if (value) {
                    const val = parseFloat(value.replace(',', '.'));
                    rentedHardware.posPrinters[index].pricePerMonth = val * 100;
                  }
                }}
              />
              <Form.Input
                placeholder="Serial Number"
                name={`${posPrinter.serialNumber}-${index}`}
                key={`${posPrinter.serialNumber}-${index}`}
                id={`${posPrinter.serialNumber}-${index}`}
                defaultValue={posPrinter.serialNumber}
                onChange={(e) => {
                  rentedHardware.posPrinters[index].serialNumber = e.target.value;
                }}
              />
              <Form.Button
                basic
                color="red"
                icon="delete"
                key={`${posPrinter.model}-${index}3`}
                onClick={() => {
                  rentedHardware.posPrinters.splice(index, 1);
                  setRentedHardware(rentedHardware);
                  setNewPosPrinter({ model: '', pricePerMonth: 0, serialNumber: '' });
                }}
              />
            </Form.Group>
          </>
        ))
      ) : (
        <></>
      )}
      <Form.Group className="items-specialSelections-group" key="specialSelection2">
        <Form.Input
          placeholder="Model"
          key="specialSelection99"
          name="model1"
          value={newPosPrinter.model}
          onChange={(e) => {
            setNewPosPrinter({
              model: e.target.value,
              pricePerMonth: newPosPrinter.pricePerMonth,
              serialNumber: newPosPrinter.serialNumber,
            });
          }}
        />
        <CurrencyInput
          className="items-specialSelections-price"
          placeholder="Price per Month"
          name="price1"
          key="specialSelection91"
          disableGroupSeparators
          allowNegativeValue={false}
          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
          onValueChange={(value) => {
            setNewPosPrinter({
              model: newPosPrinter.model,
              pricePerMonth: value,
              serialNumber: newPosPrinter.serialNumber,
            });
          }}
          value={newPosPrinter.pricePerMonth}
        />
        <Form.Input
          placeholder="Serial Number"
          key="specialSelection109"
          name="serialno1"
          value={newPosPrinter.serialNumber}
          onChange={(e) => {
            setNewPosPrinter({
              serialNumber: e.target.value,
              pricePerMonth: newPosPrinter.pricePerMonth,
              model: newPosPrinter.model,
            });
          }}
        />
        <Form.Button
          color="green"
          basic
          key="specialSelection119"
          icon="add"
          disabled={
            !(newPosPrinter.model && newPosPrinter.pricePerMonth && newPosPrinter.serialNumber)
          }
          onClick={() => {
            let { pricePerMonth } = newPosPrinter;
            if (pricePerMonth.includes(',')) {
              pricePerMonth = pricePerMonth.replace(',', '.');
            }
            rentedHardware.posPrinters.push({
              model: newPosPrinter.model,
              pricePerMonth: parseFloat(pricePerMonth) * 100,
              serialNumber: newPosPrinter.serialNumber,
            });
            setRentedHardware(rentedHardware);
            setNewPosPrinter({ model: '', pricePerMonth: 0, serialNumber: '' });
          }}
        />
      </Form.Group>
    </Form>
  );

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">Create Shop</p>
      </div>
      {isShopOwnerCreated ? (
        <>
          <div className="create-shop-container">
            <p style={{ fontSize: '16px', fontWeight: '600' }}>Create Shop</p>
            <p style={{ fontWeight: '500' }}>Shop Name</p>
            <Input
              fluid
              placeholder="Shop Name"
              value={shopName}
              onChange={(e) => {
                setShopName(e.target.value);
              }}
            />
            <br />
            <p style={{ fontWeight: '500' }}>Shop Address</p>
            <Input
              placeholder="Street"
              fluid
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
            />
            <br />
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: '50%' }}
                placeholder="Zip Code"
                value={zip}
                type="number"
                onChange={(e) => {
                  setZip(e.target.value);
                }}
              />
              <p style={{ width: '10px' }} />
              <Input
                style={{ width: '50%' }}
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
            <br />
            <Input
              placeholder="Country"
              fluid
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            <br />
            <p style={{ fontWeight: '500' }}>Shop E-mail</p>
            <Input
              placeholder="Shop E-mail"
              fluid
              value={shopEmail}
              onChange={(e) => {
                setShopEmail(e.target.value);
              }}
            />
            <br />
            <p style={{ fontWeight: '500' }}>Tax ID</p>
            <Input
              placeholder="Tax ID (Umsatzsteuernummer)"
              fluid
              value={taxId}
              onChange={(e) => {
                setTaxId(e.target.value);
              }}
            />
            <br />
            <p style={{ fontWeight: '500' }}>Shop Owner</p>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: '50%' }}
                placeholder="First Name"
                value={ownerFirstName}
                onChange={(e) => {
                  setOwnerFirstName(e.target.value);
                }}
              />
              <p style={{ width: '10px' }} />
              <Input
                style={{ width: '50%' }}
                placeholder="Last Name"
                value={ownerLastName}
                onChange={(e) => {
                  setOwnerLastName(e.target.value);
                }}
              />
            </div>
            <br />
            <Input placeholder="E-mail" fluid value={shopOwnerEmail} />
            <br />
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: '50%' }}
                placeholder="Phone Number"
                value={ownerPhone}
                onChange={(e) => {
                  setOwnerPhone(e.target.value);
                }}
              />
              <p style={{ width: '10px' }} />
              <Input
                style={{ width: '50%' }}
                placeholder="ID Number"
                value={idNumber}
                onChange={(e) => {
                  setIdNumber(e.target.value);
                }}
              />
            </div>
            <br />
            <p style={{ fontWeight: '500' }}>Bank Account</p>
            <Input
              placeholder="Account Name"
              fluid
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
              }}
            />
            <br />
            <Input
              placeholder="IBAN"
              fluid
              value={iban}
              onChange={(e) => {
                setIban(e.target.value);
              }}
            />
            <br />
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Country"
                  value={bankCountry}
                  onChange={(e) => {
                    setBankCountry(e.target.value);
                  }}
                />
                <p style={{ fontSize: 12 }}>e.g. DE, US, FR, ...</p>
              </div>
              <p style={{ width: '10px' }} />
              <div style={{ width: '50%' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Currency"
                  value={bankCurrency}
                  onChange={(e) => {
                    setBankCurrency(e.target.value);
                  }}
                />
                <p style={{ fontSize: 12 }}>e.g. eur, usd, ...</p>
              </div>
            </div>
            <br />
            <p style={{ fontWeight: '500' }}>ID Photos</p>
            {imageForth ? (
              <>
                <p>Forth</p>
                <Image src={imageForth} rounded size="small" wrapped />
                <br />
              </>
            ) : (
              <>
                <input
                  className="create-file-input withBorder"
                  type="file"
                  id="forth"
                  onChange={onIDForthUpload}
                  accept="image/*"
                />
                <label htmlFor="forth" style={{ width: '100%', height: 40, position: 'relative' }}>
                  Upload Forth ID Picture
                </label>
              </>
            )}
            <br />
            {imageBack ? (
              <>
                <p>Back</p>
                <Image src={imageBack} rounded size="small" wrapped />
                <br />
              </>
            ) : (
              <>
                <input
                  className="create-file-input withBorder"
                  type="file"
                  id="back"
                  onChange={onIDBackUpload}
                  accept="image/*"
                />
                <label htmlFor="back" style={{ width: '100%', height: 40, position: 'relative' }}>
                  Upload Forth ID Picture
                </label>
              </>
            )}
            <br />
            <p style={{ fontWeight: '500' }}>Verification</p>
            {imageVerification ? (
              <>
                <Image src={imageVerification} rounded size="small" wrapped />
                <br />
              </>
            ) : (
              <>
                <input
                  className="create-file-input withBorder"
                  type="file"
                  id="verification"
                  onChange={onVerificationUpload}
                  accept="image/*"
                />
                <label
                  htmlFor="verification"
                  style={{ width: '100%', height: 40, position: 'relative' }}
                >
                  Upload Verification Picture
                </label>
              </>
            )}
            <br />
            <p style={{ fontWeight: 500 }}>Rental iPads</p>
            {parseRentalHardwareIPad()}
            <br />
            <p style={{ fontWeight: 500 }}>Rental POS Printers</p>
            {parseRentalHardwarePosPrinter()}
            <br />
            {parsePricing()}
            <br />
            <Button
              loading={isLoading}
              content="Create Shop"
              color="teal"
              onClick={onCreateShop}
              fluid
              disabled={isLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className="create-user-container">
            <p style={{ fontSize: '16px', fontWeight: '600' }}>Create Shop Owner User</p>
            <p>E-mail</p>
            <Input
              fluid
              placeholder="E-mail"
              value={shopOwnerEmail}
              onChange={(e) => {
                setShopOwnerEmail(e.target.value.replaceAll(/\s/g, ''));
              }}
            />
            <br />
            <p>Password</p>
            <Input
              fluid
              placeholder="Password"
              value={shopOwnerPw}
              onChange={(e) => {
                setShopOwnerPw(e.target.value.replaceAll(/\s/g, ''));
              }}
            />
            <p style={{ fontSize: 12, marginTop: 10 }}>
              Password has to be at least 8 characters long, contain 1 uppercase, 1 lowercase and at
              least 1 number.
            </p>
            <Button
              loading={isLoading}
              fluid
              content="Create User"
              color="teal"
              onClick={onCreateUser}
              disabled={isLoading}
            />
          </div>
          <div className="create-user-container">
            <p style={{ fontSize: '16px', fontWeight: '600' }}>Create Franchisor</p>
            <p>E-mail</p>
            <Input
              fluid
              placeholder="E-mail"
              value={franchisorEmail}
              onChange={(e) => {
                setFranchisorEmail(e.target.value.replaceAll(/\s/g, ''));
              }}
            />
            <br />
            <p>Password</p>
            <Input
              fluid
              placeholder="Password"
              value={franchisorPw}
              onChange={(e) => {
                setFranchisorPw(e.target.value.replaceAll(/\s/g, ''));
              }}
            />
            <p style={{ fontSize: 12, marginTop: 10 }}>
              Password has to be at least 8 characters long, contain 1 uppercase, 1 lowercase and at
              least 1 number.
            </p>
            <Dropdown
              selection
              clearable
              fluid
              multiple
              placeholder="Select Shops"
              options={dropDownOptions}
              onChange={(_, data) => {
                setFranchisorShopIds([...data.value]);
              }}
            />
            <br />
            <Button
              loading={isLoading}
              fluid
              content="Create Franchisor"
              color="teal"
              onClick={onCreateFranchiseUser}
              disabled={isLoading}
            />
          </div>
        </>
      )}
      <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>Error</p>
        </Modal.Header>
        <Modal.Content>
          <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={successModal.open} dimmer="blurring">
        <Modal.Header>
          <p>Congratulations!</p>
        </Modal.Header>
        <Modal.Content>
          <p>{successModal.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Great" onClick={onSuccessModalOk} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CreateShopScreen;
