/* eslint-disable new-cap */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Input, Dropdown, Popup } from 'semantic-ui-react';
import { updatePrinter, deletePrinter, createPrinter } from '../../../api/ShopAPI';
import { useStateValue } from '../../../core/context/StateProvider';
import { Printer } from '../../../utils/Printer';
import './SettingsScreen.css';
import { showToast } from '../../../components/general/Toast/Toast';
import Toggle from '../../../components/general/Toggle/Toggle';
import MultiInput from '../../../components/shop/MultiInput/MultiInput';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const PrintersModal = (props) => {
  const { onClose, open, printer, categories } = props;
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isDataReset, setIsDataReset] = useState(false);
  const [printerData, setPrinterData] = useState({
    description: '',
    ipAddress: '',
    deviceId: '',
    categories: [],
    paperSize: 80,
    rooms: [],
    isMain: false,
    isUsingIpAddress: false,
    serialNumber: '',
    hasCustomerDisplay: false,
    firstLine: '',
    secondLine: '',
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (categories != null) {
      const category = categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (categories !== null) {
      const category = categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  useEffect(() => {
    if (!printer && !isDataReset) {
      setPrinterData({
        description: '',
        ipAddress: '',
        deviceId: '',
        categories: [],
        paperSize: 80,
        rooms: [],
        isMain: false,
        isUsingIpAddress: true,
        serialNumber: '',
        hasCustomerDisplay: false,
        firstLine: '',
        secondLine: '',
      });
      setIsDataReset(true);
    }
    if (printer) {
      setPrinterData({
        description: printer.description,
        ipAddress: printer.ipAddress,
        deviceId: printer.deviceId,
        categories:
          printer.categories.length === 1 && printer.categories[0] === 'all'
            ? categories.map((category) => getCategoryTranslation(category))
            : printer.categories && printer.categories.length > 0
            ? printer.categories.map((category) => getCategoryTranslationForName(category))
            : printer.categories,
        paperSize: printer.paperSize,
        rooms:
          printer.rooms.length === 1 && printer.rooms[0] === 'all'
            ? shop.rooms.map((room) => room.name)
            : printer.rooms,
        isMain: printer.isMain,
        isUsingIpAddress: printer.isUsingIpAddress,
        serialNumber: printer.serialNumber,
        hasCustomerDisplay: printer.hasCustomerDisplay || false,
        firstLine: printer.firstLine || '',
        secondLine: printer.secondLine || '',
      });
    }
  }, [printer]);

  useEffect(() => {
    if (
      (printerData.isUsingIpAddress !== true &&
        printerData.serialNumber &&
        printerData.description) ||
      (printerData.isUsingIpAddress && printerData.ipAddress && printerData.description)
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [printerData]);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onDropDownChange = (value) => {
    setPrinterData({
      ...printerData,
      categories: value,
    });
  };

  const onDropDownChangeRooms = (value) => {
    setPrinterData({
      ...printerData,
      rooms: value,
    });
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const translatedCategories = categories.map((category) => getCategoryTranslation(category));
      translatedCategories.sort();
      translatedCategories.forEach((category) => {
        const object = {};
        object.key = category.key;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const dropdownOptionsRooms = (rooms) => {
    if (rooms) {
      const options = [];
      shop.rooms.forEach((room, index) => {
        const object = {};
        object.key = room.name + index;
        object.value = room.name;
        object.text = room.name;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorSettingsPrinter${printerDevice.printer.description}`,
    });
  };

  const onTestPrint = () => {
    const printerDevice = new Printer(printer);
    printerDevice.epos.onerror = () => showPrinterError(printerDevice);
    printerDevice.testPrint();
    // printerDevice.printBarcode();
  };

  const onDelete = async () => {
    if (printer) {
      setIsLoading(true);
      const res = await deletePrinter(token, printer._id);
      if (res && res.success) {
        closeModal(true);
      } else {
        setIsLoading(false);
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('printerDeleteError'),
        });
      }
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    if (
      printerData.isUsingIpAddress !== true &&
      (printerData.serialNumber == null || printerData.serialNumber.length === 0) &&
      (printerData.description == null || printerData.description.length === 0)
    ) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('newPrinterErrorMessage') });
      setIsLoading(false);
      return;
    }
    if (
      printerData.isUsingIpAddress === true &&
      (printerData.ipAddress == null || printerData.ipAddress.length === 0) &&
      (printerData.description == null || printerData.description.length === 0)
    ) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('newPrinterErrorMessage') });
      setIsLoading(false);
      return;
    }
    if (printer) {
      const data = {};
      data.ipAddress = printerData.ipAddress;
      data.deviceId = printerData.deviceId;
      data.description = printerData.description;
      data.categories =
        printerData.categories.length === categories.length
          ? ['all']
          : printerData.categories && printerData.categories.length > 0
          ? printerData.categories.map((category) => getOriginalCategoryFromName(category))
          : printerData.categories;
      data.paperSize = printerData.paperSize;
      data.rooms = printerData.rooms.length === shop.rooms.length ? ['all'] : printerData.rooms;
      data.isMain = printerData.isMain;
      data.isUsingIpAddress = printerData.isUsingIpAddress;
      data.serialNumber = printerData.serialNumber;
      data.hasCustomerDisplay = printerData.hasCustomerDisplay;
      data.firstLine = printerData.firstLine;
      data.secondLine = printerData.secondLine;
      const res = await updatePrinter(token, printer._id, data);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('printerUpdateError'),
        });
        setIsLoading(false);
      }
    } else {
      const data = { printer: {} };
      data.printer.ipAddress = printerData.ipAddress;
      data.printer.deviceId = printerData.deviceId;
      data.printer.description = printerData.description;
      data.printer.categories =
        printerData.categories.length === categories.length
          ? ['all']
          : printerData.categories && printerData.categories.length > 0
          ? printerData.categories.map((category) => getOriginalCategoryFromName(category))
          : printerData.categories;
      data.printer.paperSize = printerData.paperSize;
      data.printer.rooms =
        printerData.rooms.length === shop.rooms.length ? ['all'] : printerData.rooms;
      data.printer.isMain = printerData.isMain;
      data.printer.isUsingIpAddress = printerData.isUsingIpAddress;
      data.printer.serialNumber = printerData.serialNumber;
      data.printer.hasCustomerDisplay = printerData.hasCustomerDisplay;
      data.printer.firstLine = printerData.firstLine;
      data.printer.secondLine = printerData.secondLine;
      const res = await createPrinter(token, data);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('printerCreateError'),
        });
        setIsLoading(false);
      }
    }
  };

  const closeModal = (refreshNeeded) => {
    if (printer && printerData.hasCustomerDisplay) {
      const printerDevice = new Printer(printer);
      if (
        (printerData.firstLine &&
          printerData.firstLine.length > 0 &&
          printerData.firstLine.replaceAll(' ', '').length > 0) ||
        (printerData.secondLine &&
          printerData.secondLine.length > 0 &&
          printerData.secondLine.replaceAll(' ', '').length > 0)
      ) {
        printerDevice.display2Lines(printerData.firstLine, printerData.secondLine);
      } else {
        printerDevice.display2Lines('Herzlich Willkommen!', null);
      }
    }
    const newPrinterData = {
      description: '',
      ipAddress: '',
      deviceId: '',
      categories: [],
      rooms: [],
      isMain: false,
      isUsingIpAddress: true,
      serialNumber: '',
      hasCustomerDisplay: false,
      firstLine: '',
      secondLine: '',
    };
    setPrinterData({ ...newPrinterData });
    setIsLoading(false);
    setIsSaveDisabled(true);
    setIsDataReset(false);
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="small" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none' }}>
          {printer ? printer.description : i18n.t('newPrinterTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ overflowX: 'hidden', paddingTop: 10 }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('newPRinterDescription')}</p>
          <Input
            placeholder={i18n.t('newPRinterDescription')}
            name="description"
            value={printerData.description}
            onChange={(e) => {
              setPrinterData({
                ...printerData,
                description: e.target.value,
              });
            }}
            className="printer-input"
            style={{ marginBottom: 14 }}
          />
          <p style={{ fontWeight: 600, marginBottom: 4 }}>
            {i18n.t('networkOptions')}{' '}
            <Popup
              on={['hover', 'click']}
              // eslint-disable-next-line prettier/prettier
              trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
              content={i18n.t('infoNetworkOptions')}
              popper={<div style={{ filter: 'none' }} />}
            />
          </p>
          <div style={{ marginBottom: 14 }}>
            <Button.Group
              fluid
              style={{
                fontFamily: 'Montserrat',
                border: '1px solid #7268cd',
                borderRadius: 8,
                height: 36,
                overflow: 'hidden',
                marginTop: 2,
              }}
            >
              <Button
                style={{
                  fontFamily: 'Montserrat',
                  borderRadiusLeft: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 10,
                  height: 34,
                  backgroundColor: printerData.isUsingIpAddress ? '#7268cd' : 'white',
                  color: printerData.isUsingIpAddress ? 'white' : '#7268cd',
                }}
                onClick={() => {
                  setPrinterData({
                    ...printerData,
                    isUsingIpAddress: true,
                    ipAddress: '',
                    serialNumber: '',
                  });
                }}
              >
                {i18n.t('newPrinterIpAddress')}
              </Button>
              <Button
                style={{
                  fontFamily: 'Montserrat',
                  borderRadiusRight: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 10,
                  height: 34,
                  backgroundColor: printerData.isUsingIpAddress ? 'white' : '#7268cd',
                  color: printerData.isUsingIpAddress ? '#7268cd' : 'white',
                }}
                onClick={() => {
                  setPrinterData({
                    ...printerData,
                    isUsingIpAddress: false,
                    ipAddress: '',
                    serialNumber: '',
                  });
                }}
              >
                {i18n.t('hostAddress')}
              </Button>
            </Button.Group>
          </div>
          <div
            style={{ display: 'flex', width: '100%', gap: 10, marginBottom: 4 }}
            className="settings-printer-mobile-breakpoint"
          >
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600 }}>
                {printerData.isUsingIpAddress
                  ? i18n.t('newPrinterIpAddress')
                  : i18n.t('hostAddress')}
              </p>
              <Input
                placeholder={
                  printerData.isUsingIpAddress
                    ? i18n.t('newPrinterIpAddress')
                    : i18n.t('hostAddressPlaceholder')
                }
                name="ipAddress"
                readOnly={printerData.isUsingIpAddress !== true}
                value={printerData.ipAddress}
                onChange={(e) => {
                  setPrinterData({
                    ...printerData,
                    ipAddress: e.target.value,
                  });
                }}
                className="printer-input"
              />
            </div>
            {printerData.isUsingIpAddress !== true ? (
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600 }}>{i18n.t('serialNo')}</p>
                <Input
                  placeholder={i18n.t('serialNo')}
                  name="serialNumber"
                  value={printerData.serialNumber}
                  onChange={(e) => {
                    setPrinterData({
                      ...printerData,
                      serialNumber: e.target.value,
                    });
                  }}
                  className="printer-input"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <p style={{ fontWeight: 600, marginBottom: 4 }}>{`${i18n.t('paperSize')}`}</p>
          <div style={{ marginBottom: 14 }}>
            <Button.Group
              fluid
              style={{
                fontFamily: 'Montserrat',
                border: '1px solid #7268cd',
                borderRadius: 8,
                height: 36,
                overflow: 'hidden',
                marginTop: 2,
              }}
            >
              <Button
                style={{
                  fontFamily: 'Montserrat',
                  borderRadiusLeft: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 10,
                  height: 34,
                  backgroundColor: printerData.paperSize === 80 ? '#7268cd' : 'white',
                  color: printerData.paperSize === 80 ? 'white' : '#7268cd',
                }}
                onClick={() => {
                  setPrinterData({ ...printerData, paperSize: 80 });
                }}
              >
                80 mm
              </Button>
              <Button
                style={{
                  fontFamily: 'Montserrat',
                  borderRadiusRight: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 10,
                  height: 34,
                  backgroundColor: printerData.paperSize !== 58 ? 'white' : '#7268cd',
                  color: printerData.paperSize !== 58 ? '#7268cd' : 'white',
                }}
                onClick={() => {
                  setPrinterData({ ...printerData, paperSize: 58 });
                }}
              >
                58 mm
              </Button>
            </Button.Group>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 4,
              marginTop: 28,
            }}
          >
            <p style={{ fontWeight: '600', color: '#333', fontSize: 15 }}>
              {i18n.t('kBonDrucker')}
              <Popup
                on={['hover', 'click']}
                // eslint-disable-next-line prettier/prettier
                trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                content={i18n.t('infoCustomerReceipt')}
                popper={<div style={{ filter: 'none' }} />}
              />
            </p>
            <Toggle
              style={{ position: 'relative', right: 0 }}
              checked={printerData.isMain === true}
              onChange={() => {
                setPrinterData({
                  ...printerData,
                  isMain: printerData.isMain === true ? false : true,
                });
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 0,
            }}
          >
            <p style={{ fontWeight: '600', color: '#333', fontSize: 15 }}>
              {i18n.t('hasCustomerDisplay')}
            </p>
            <Toggle
              style={{ position: 'relative', right: 0 }}
              checked={printerData.hasCustomerDisplay === true}
              onChange={() => {
                setPrinterData({
                  ...printerData,
                  hasCustomerDisplay: printerData.hasCustomerDisplay === true ? false : true,
                });
              }}
            />
          </div>
          {printerData.hasCustomerDisplay === true ? (
            <>
              <div style={{ marginBottom: 14 }}>
                <p style={{ fontWeight: '600', color: '#333', fontSize: 15, marginBottom: 6 }}>
                  {i18n.t('1stLineDisplay')}
                </p>
                <MultiInput
                  numberOfDigits={20}
                  defaultValue={printerData.firstLine}
                  onValueChange={(val) => {
                    setPrinterData({
                      ...printerData,
                      firstLine: val.map((v) => (v === '' ? v.replace('', ' ') : v)).join(''),
                    });
                  }}
                />
              </div>
              <div style={{ marginBottom: 18 }}>
                <p style={{ fontWeight: '600', color: '#333', fontSize: 15, marginBottom: 6 }}>
                  {i18n.t('2stLineDisplay')}
                </p>
                <MultiInput
                  numberOfDigits={20}
                  defaultValue={printerData.secondLine}
                  onValueChange={(val) => {
                    setPrinterData({
                      ...printerData,
                      secondLine: val.map((v) => (v === '' ? v.replace('', ' ') : v)).join(''),
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            style={{ display: 'flex', gap: 14, width: '100%' }}
            className="settings-printer-mobile-breakpoint"
          >
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600 }}>
                {`${i18n.t('rooms')} `}
                <Popup
                  on={['hover', 'click']}
                  // eslint-disable-next-line prettier/prettier
                  trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                  content={i18n.t('infoRoomPrinter')}
                  popper={<div style={{ filter: 'none' }} />}
                />
              </p>
              <Dropdown
                selection
                placeholder={i18n.t('selectRoom')}
                fluid
                multiple
                options={dropdownOptionsRooms(shop.rooms)}
                onChange={(_, data) => {
                  const roomName = data.value[data.value.length - 1];
                  const printersWithThatRoomName = shop.printers.filter((printer) =>
                    printer.rooms.includes(roomName)
                  );
                  if (
                    printersWithThatRoomName.length > 0 &&
                    printerData.rooms.includes(roomName) === false
                  ) {
                    setErrorWarningModalOptions({
                      open: true,
                      message: i18n.t('roomAlreadyInUseWarning'),
                    });
                  }
                  onDropDownChangeRooms(data.value);
                }}
                style={{ marginBottom: 18 }}
                value={printerData.rooms}
                className="items-dropdown"
                id="items-dropdown"
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className="settings-btn-cat1"
                  disabled={shop && shop.rooms && shop.rooms.length === printerData.rooms.length}
                  content={i18n.t('newPrinterSelectAll')}
                  onClick={() => {
                    onDropDownChangeRooms(shop.rooms.map((room) => room.name));
                  }}
                  style={{
                    fontFamily: 'Montserrat',
                    padding: 6,
                    outline: '1px solid #7268cd',
                    color: '#7268cd',
                    fontSize: 12,
                  }}
                />
                <Button
                  className="settings-btn-cat1"
                  content="X"
                  disabled={printerData.rooms.length === 0}
                  onClick={() => {
                    onDropDownChangeRooms([]);
                  }}
                  style={{
                    fontFamily: 'Montserrat',
                    padding: 8,
                    outline: '1px solid red',
                    color: 'red',
                    fontSize: 12,
                  }}
                />
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ fontWeight: 600 }}>
                {i18n.t('newPrinterCategories')}{' '}
                <Popup
                  on={['hover', 'click']}
                  // eslint-disable-next-line prettier/prettier
                  trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                  content={i18n.t('infoCategoryPrinter')}
                  popper={<div style={{ filter: 'none' }} />}
                />
              </p>
              <Dropdown
                selection
                placeholder={i18n.t('newPrinterCategories')}
                fluid
                multiple
                options={dropdownOptions(categories)}
                onChange={(_, data) => {
                  onDropDownChange(data.value);
                }}
                value={printerData.categories}
                className="items-dropdown"
                id="items-dropdown"
                style={{ marginBottom: 18 }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className="settings-btn-cat1"
                  content={i18n.t('newPrinterSelectAll')}
                  disabled={categories.length === printerData.categories.length}
                  onClick={() => {
                    onDropDownChange(
                      categories.map((category) => getCategoryTranslation(category))
                    );
                  }}
                  style={{
                    fontSize: 12,
                    fontFamily: 'Montserrat',
                    padding: 6,
                    outline: '1px solid #7268cd',
                    color: '#7268cd',
                  }}
                />
                <Button
                  className="settings-btn-cat1"
                  content="X"
                  disabled={printerData.categories.length === 0}
                  onClick={() => {
                    onDropDownChange([]);
                  }}
                  style={{
                    fontSize: 12,
                    fontFamily: 'Montserrat',
                    padding: 8,
                    outline: '1px solid red',
                    color: 'red',
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          {printer ? (
            <>
              <Button
                loading={isLoading}
                content={i18n.t('employeeModalDelete')}
                disabled={printer ? false : true}
                floated="left"
                onClick={onDelete}
                color="red"
              />
              <Button
                content={i18n.t('newPrinterTestPrint')}
                disabled={printer ? false : true}
                onClick={onTestPrint}
                color="blue"
              />
            </>
          ) : (
            <></>
          )}
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default PrintersModal;
