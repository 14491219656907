/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useStateValue } from '../../../core/context/StateProvider';
import { hexToRgb } from '../../../utils/ColorFormatter';
import './Navbar.css';

/// Navbar component.
/// Takes props as an array of NavbarItem objects, used as 'items'
const Navbar = (props) => {
  const [click, setClick] = useState(false);
  const [{ token, user }, dispatch] = useStateValue();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const { items, isShop, isLogin, color, title } = props;
  const rgbShopColor = color ? hexToRgb(color) : null;
  let menuIcon = 'menu-icon';
  let navbar = isShop ? (isLogin ? 'navbar-cockpit-login' : 'navbar-cockpit') : 'navbar';
  if (user && (user.role === 'employee' || user.role === 'manager' || user.role === 'tax')) {
    navbar = 'navbar-cockpit-employee';
  }
  if (color && title) {
    navbar = 'navbar-customer';
    menuIcon = 'menu-icon-customer';
  }
  const navmenu = isShop
    ? user && user.role !== 'owner'
      ? 'nav-menu-cockpit-manager'
      : 'nav-menu-cockpit'
    : 'nav-menu';
  const navlinks = isShop ? 'nav-links-cockpit' : 'nav-links';
  const navbarContainer = isLogin ? 'navbar-container-login' : 'navbar-container';
  const navbarlogo = isShop
    ? isLogin
      ? 'navbar-logo-cockpit-login'
      : user && (user.role === 'employee' || user.role === 'manager' || user.role === 'tax')
      ? 'navbar-logo-cockpit-employee'
      : 'navbar-logo-cockpit'
    : 'navbar-logo';

  return (
    <>
      <nav
        className={navbar}
        style={{
          backgroundColor: title
            ? `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`
            : '',
        }}
      >
        <div className={navbarContainer}>
          <Link
            to="/"
            className={title ? 'navbar-logo-customer' : navbarlogo}
            onClick={closeMobileMenu}
          >
            <div
              className={title ? 'logo-font-customer' : 'logo-font'}
              style={{ color: title ? '#333' : '' }}
            >
              {title || 'moaby'}
            </div>
            {isShop ? (
              <>
                <div className="vertical-line" />
                <div className="nav-subtitle">
                  {user && user.role === 'tax'
                    ? 'Reports'
                    : user.role === 'franchisor'
                    ? 'Shop of Shops'
                    : 'Shop Manager'}
                </div>
              </>
            ) : (
              <></>
            )}
          </Link>
        </div>
        {!isShop ? (
          <>
            <div className={menuIcon} onClick={handleClick}>
              <Icon name={click ? 'close' : 'bars'} style={{ color: title ? '#333' : '' }} />
            </div>
          </>
        ) : (
          <></>
        )}
        {color && title ? (
          <>
            <div className={click ? 'nav-menu-customer active' : 'nav-menu-customer'}>
              <div
                className="nav-menu-customer active"
                style={{
                  backgroundColor: `rgba(${rgbShopColor.r}, ${rgbShopColor.g}, ${rgbShopColor.b}, 0.07)`,
                  height: 'calc(100vh - 64px)',
                  position: 'absolute',
                  top: 0,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <ul className={click ? 'nav-menu active' : navmenu}>
              {items.map((item) => (
                <li key={item.title} className="nav-item" style={item.style}>
                  {item.icon.length > 0 && (
                    <img src={item.icon} alt={item.title} className="nav-icon" />
                  )}
                  <Link
                    to={item.linkTo}
                    className={navlinks}
                    onClick={
                      item.onClick
                        ? () => {
                            closeMobileMenu();
                            item.onClick();
                          }
                        : closeMobileMenu
                    }
                    style={{ fontSize: 14 }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </nav>
    </>
  );
};

export default Navbar;
