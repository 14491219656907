import { post, put, get, del } from './Fetch';
import { useStateValue } from '../core/context/StateProvider';

// POST

export const forgotPassword = async (email) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/passwordForgot`;
  const headers = {};
  const body = { email };
  return post(url, headers, body);
};

export const addTablesToUser = async (tables, userId, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/tables`;
  const headers = { Authorization: token };
  const body = { tables, userId };
  return post(url, headers, body);
};

export const forgotPasswordComplete = async (token, password) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/passwordForgot/complete`;
  const headers = {};
  const body = { token, password };
  return post(url, headers, body);
};

export const createOwnerUser = async (email, password, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users`;
  const headers = { Authorization: token };
  const body = {
    email,
    password,
  };
  return post(url, headers, body);
};

export const createFranchisorUser = async (email, password, shopIds, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/franchisor`;
  const headers = { Authorization: token };
  const body = {
    email,
    password,
    shopIds,
  };
  return post(url, headers, body);
};

export const createManagerUser = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/manager`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

export const createEmployeeUser = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/employee`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

export const createTaxUser = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/tax`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

export const login = async (email, password) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/login`;
  const headers = {};
  const body = { email, password };
  return post(url, headers, body);
};

export const logout = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/logout`;
  const [{ token }] = useStateValue();
  const headers = { Authorization: token };
  const body = {};
  return post(url, headers, body);
};

// PUT

export const updateEmail = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/email`;
  const headers = { Authorization: token };
  const body = data;
  return put(url, headers, body);
};

export const updatePassword = async (token, oldPassword, newPassword, userId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/password/${userId}`;
  const headers = { Authorization: token };
  const body = { oldPassword, newPassword };
  return put(url, headers, body);
};

export const updatePin = async (token, pin, userId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/pin/${userId}/${pin}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateName = async (token, name, userId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/name/${userId}/${name}`;
  const headers = { Authorization: token };
  const body = {};
  return put(url, headers, body);
};

export const updateIsPrinting = async (token, userId, canPrint) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/printing/${userId}/${canPrint}`;
  const headers = { Authorization: token };
  return put(url, headers, {});
};

export const updateIsPaying = async (token, userId, canPay) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/paying/${userId}/${canPay}`;
  const headers = { Authorization: token };
  return put(url, headers, {});
};

export const updateIsOrdering = async (token, userId, canOrder) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/ordering/${userId}/${canOrder}`;
  const headers = { Authorization: token };
  return put(url, headers, {});
};

export const updateIsDiscounting = async (token, userId, canDiscount) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/discounting/${userId}/${canDiscount}`;
  const headers = { Authorization: token };
  return put(url, headers, {});
};

// GET

export const getAllEmployees = async (token, shopId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/shop/${shopId}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const updatePasswordComplete = async (token, hash) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/passwordReset/?hash=${hash}&token=${token}`;
  return get(url, {});
};

export const checkPin = async (token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/pin`;
  const headers = { Authorization: token, Pin: pin };
  return get(url, headers);
};

export const checkPinOnly = async (token, pin) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/pin/check`;
  const headers = { Authorization: token, Pin: pin };
  return get(url, headers);
};

// DELETE

export const deleteEmployee = async (token, employeeId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}users/shop/employee/${employeeId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};
