/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import './TimeLine.css';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { useMediaQuery } from '@react-hook/media-query';
import addIcon from './Icons/add.png';
import cancelIcon from './Icons/cancel.png';
import cardIcon from './Icons/card.png';
import cashIcon from './Icons/cash.png';
import editIcon from './Icons/edit.png';
import mergeIcon from './Icons/merge.png';
import refundIcon from './Icons/refund.png';
import requestIcon from './Icons/request.png';
import splitIcon from './Icons/split.png';
import statusIcon from './Icons/status.png';
import updateIcon from './Icons/update.png';
import i18n from '../../../i18n/config';
import { asCurrency } from '../../../utils/NumberFormatter';

const Timeline = ({ entries }) => {
  const mobileMatches = useMediaQuery('only screen and (min-width: 768px)');

  const renderHistoryEntry = (entry) => {
    let icon;
    let title;
    let subtitle;
    const popupLines = [];
    if (entry.type === 'payment') {
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('amount')}</p>);
      popupLines.push(<p>{`${asCurrency(entry.amount / 100)}€`}</p>);
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('bookingsItems')}</p>);
      popupLines.push(
        <ul>
          {entry.items.map((item, index) => (
            <li style={{ marginLeft: 14 }} key={`${item}i ${index}`}>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      );
      if (entry.isCash) {
        icon = cashIcon;
        title = i18n.t('timeLineCashPayment1');
        subtitle = i18n.t('timeLineCashPayment2');
      } else {
        icon = cardIcon;
        title = i18n.t('timeLineCardPayment1');
        subtitle = i18n.t('timeLineCardPayment2');
      }
    }
    if (entry.type === 'edit') {
      icon = editIcon;
      title = i18n.t('timeLineEditTable1');
      subtitle = i18n.t('timeLineEditTable2');
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('from')}</p>);
      popupLines.push(<p>{entry.table.fromNumber}</p>);
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('to')}</p>);
      popupLines.push(<p>{entry.table.toNumber}</p>);
    }
    if (entry.type === 'split') {
      icon = splitIcon;
      title = i18n.t('timeLineSplit1');
      subtitle = i18n.t('timeLineSplit2');
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('toBookingId')}</p>);
      popupLines.push(<p>{entry.toBookingId}</p>);
    }
    if (entry.type === 'merge') {
      icon = mergeIcon;
      title = i18n.t('timeLineMerg1');
      subtitle = i18n.t('timeLineMerg2');
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('fromBookingIds')}</p>);
      popupLines.push(
        <ul>
          {entry.fromBookingIds.map((item, index) => (
            <li style={{ marginLeft: 14 }} key={`${item}m ${index}`}>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      );
    }
    if (entry.type === 'request') {
      icon = requestIcon;
      title = i18n.t('timeLineRequest1');
      subtitle = i18n.t('timeLineRequest2');
    }
    if (entry.type === 'refund') {
      icon = refundIcon;
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('amount')}</p>);
      popupLines.push(<p>{`${asCurrency(entry.amount / 100)}€`}</p>);
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('isFullRefund')}</p>);
      if (entry.isFullRefund) {
        title = i18n.t('timeLineRefundBooking1');
        subtitle = i18n.t('timeLineRefundBooking2');
        popupLines.push(<p>{i18n.t('bookingEditBookingWarningYes')}</p>);
      } else {
        title = i18n.t('timeLineRefundItem1');
        subtitle = i18n.t('timeLineRefundItem2');
        popupLines.push(<p>{i18n.t('bookingEditBookingWarningNo')}</p>);
      }
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('bookingsItems')}</p>);
      popupLines.push(
        <ul>
          {entry.items.map((item, index) => (
            <li style={{ marginLeft: 14 }} key={`${item}r ${index}`}>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      );
    }
    if (entry.type === 'cancel') {
      icon = cancelIcon;
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('amount')}</p>);
      popupLines.push(<p>{`${asCurrency(entry.amount / 100)}€`}</p>);
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('isFullCancel')}</p>);
      if (entry.isFullCancel) {
        title = i18n.t('timeLineCancelOrder1');
        subtitle = i18n.t('timeLineCancelOrder2');
        popupLines.push(<p>{i18n.t('bookingEditBookingWarningYes')}</p>);
      } else {
        title = i18n.t('timeLineCancelItem1');
        subtitle = i18n.t('timeLineCancelItem2');
        popupLines.push(<p>{i18n.t('bookingEditBookingWarningNo')}</p>);
      }
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('bookingsItems')}</p>);
      popupLines.push(
        <ul>
          {entry.items.map((item, index) => (
            <li style={{ marginLeft: 14 }} key={`${item}c ${index}`}>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      );
    }
    if (entry.type === 'create') {
      icon = addIcon;
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('amount')}</p>);
      popupLines.push(<p>{`${asCurrency(entry.amount / 100)}€`}</p>);
      if (entry.request) {
        title = i18n.t('timeLineCreateQRRequest1');
        subtitle = i18n.t('timeLineCreateQRRequest2');
      } else if (entry.order) {
        if (entry.fromQR) {
          title = i18n.t('timeLineCreateOrderQR1');
          subtitle = i18n.t('timeLineCreateOrderQR2');
        } else if (entry.fromMerge) {
          title = i18n.t('timeLineCreateBookingMerge1');
          subtitle = i18n.t('timeLineCreateBookingMerge2');
        } else if (entry.fromSplit) {
          title = i18n.t('timeLineCreateBookingSplit1');
          subtitle = i18n.t('timeLineCreateBookingSplit2');
          popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('originalOrder')}</p>);
          popupLines.push(<p>{entry.originBookingId}</p>);
        } else {
          title = i18n.t('timeLineCreateOrder1');
          subtitle = i18n.t('timeLineCreateOrder2');
        }
      } else if (entry.booking) {
        if (entry.fromQR) {
          title = i18n.t('timeLineCreateBookingQR1');
          subtitle = i18n.t('timeLineCreateBookingQR2');
        } else {
          title = i18n.t('timeLineCreateBooking1');
          subtitle = i18n.t('timeLineCreateBooking2');
        }
      }
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('bookingsItems')}</p>);
      popupLines.push(
        <ul>
          {entry.items.map((item, index) => (
            <li style={{ marginLeft: 14 }} key={`${item}cr ${index}`}>
              <p>{item}</p>
            </li>
          ))}
        </ul>
      );
    }
    if (entry.type === 'updateOrder') {
      icon = updateIcon;
      title = i18n.t('timeLineUpdateOrder1');
      subtitle = i18n.t('timeLineUpdateOrder2');
      if (entry.addedItems && entry.addedItems.length > 0) {
        popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('addedItems')}</p>);
        popupLines.push(
          <ul>
            {entry.items.map((item, index) => (
              <li style={{ marginLeft: 14 }} key={`${item.item}uo ${index}`}>
                <p>{item.item}</p>
              </li>
            ))}
          </ul>
        );
      }
      if (entry.cancelledItems && entry.cancelledItems.length > 0) {
        popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('cancelledItems')}</p>);
        popupLines.push(
          <ul>
            {entry.items.map((item, index) => (
              <li style={{ marginLeft: 14 }} key={`${item.item}uoc ${index}`}>
                <p>{item.item}</p>
              </li>
            ))}
          </ul>
        );
      }
    }
    if (entry.type === 'status') {
      icon = statusIcon;
      title = i18n.t('timeLineChangeStatus1');
      subtitle = i18n.t('timeLineChangeStatus2');
      const from = entry.fromStatus === 'open' ? i18n.t('bookingsOpen') : i18n.t('bookingsClosed');
      const to = entry.toStatus === 'open' ? i18n.t('bookingsOpen') : i18n.t('bookingsClosed');
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('from')}</p>);
      popupLines.push(<p>{from}</p>);
      popupLines.push(<p style={{ fontWeight: 600 }}>{i18n.t('to')}</p>);
      popupLines.push(<p>{to}</p>);
    }
    return (
      <Popup
        on={['hover', 'click']}
        basic
        // eslint-disable-next-line prettier/prettier
        trigger={(
          <div className="timeline-entry">
            <div className="timeline-title">
              <p>{title}</p>
              <p>{subtitle}</p>
            </div>
            <div className="timeline-icon">
              <img src={icon} alt={title} style={{ height: 19, width: 19 }} />
            </div>
            <div className="timeline-date">
              <p>{moment(entry.date).format(`HH:mm[${i18n.t('uhrGerman')}]`)}</p>
              <p className="timeline-date-subtitle">{moment(entry.date).format('DD.MM.YYYY')}</p>
            </div>
          </div>
          // eslint-disable-next-line prettier/prettier
        )}
        // eslint-disable-next-line prettier/prettier
        content={(
          <>
            {entry.user && entry.user.name ? (
              <>
                <p key="changeF" style={{ fontWeight: 600 }}>{`${i18n.t('changeFrom')}`}</p>
                <p key="userN">{entry.user.name}</p>
              </>
            ) : (
              <></>
            )}
            {popupLines.map((line, index) => (
              <Fragment key={`fragline${index}`}>{line}</Fragment>
            ))}
          </>
          // eslint-disable-next-line prettier/prettier
      )}
        position={mobileMatches ? 'right center' : 'top center'}
        popper={<div style={{ filter: 'none' }} />}
      />
    );
  };

  return (
    <div className="timeline-wrapper">
      <div
        className="timeline-container"
        style={{ justifyContent: entries && entries.length === 1 ? 'center' : '' }}
      >
        {entries && entries.length > 0 ? (
          <>
            {entries.map((entry, index) => (
              <Fragment key={`${entry.type} ${index}`}>
                {renderHistoryEntry(entry)}
                {entries[index + 1] != null ? <div className="timeline-connector" /> : <></>}
              </Fragment>
            ))}
          </>
        ) : (
          <p>{i18n.t('noHistory')}</p>
        )}
      </div>
    </div>
  );
};

export default Timeline;
