import React from 'react';
import ReactDOM from 'react-dom';
import App from './core/App';
import { StateProvider } from './core/context/StateProvider';
import { initialState, reducer } from './core/context/Reducer';
import 'semantic-ui-css/semantic.min.css';
import '@fontsource/montserrat';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/100-italic.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/200-italic.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/300-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/800-italic.css';
import '@fontsource/montserrat/900.css';
import '@fontsource/montserrat/900-italic.css';
import '@fontsource-variable/vollkorn';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

const app = (
  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>
);

ReactDOM.render(app, document.getElementById('root'));
