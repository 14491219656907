/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable new-cap */

import moment from 'moment';
import { isEqual, countBy, findLastIndex } from 'lodash';
import { asCurrency } from './NumberFormatter';
import { chunkString } from './StringUtils';

export class Printer {
  constructor(printer) {
    this.printer = printer;
    this.address =
      process.env.REACT_APP_ENV === 'dev'
        ? `http://${printer.ipAddress}/cgi-bin/epos/service.cgi?devid=${printer.deviceId}&timeout=10000`
        : `https://${printer.ipAddress}/cgi-bin/epos/service.cgi?devid=${printer.deviceId}&timeout=10000`;
    this.displayAddress =
      process.env.REACT_APP_ENV === 'dev'
        ? `http://${printer.ipAddress}/cgi-bin/eposDisp/service.cgi?devid=local_display&timeout=10000`
        : `https://${printer.ipAddress}/cgi-bin/eposDisp/service.cgi?devid=local_display&timeout=10000`;
    if (window.epson) {
      this.builder = new window.epson.ePOSBuilder();
      this.epos = new window.epson.ePOSPrint(this.address);
      this.epos.onreceive = (res) => {
        console.log(`Printer success: ${JSON.stringify(res)}`);
      };
      this.epos.onerror = (err) => {
        console.log(`Printer error: ${err.status}`);
        alert(`Error while printing! Printer ${printer.description} could not print.`);
      };
    }
  }

  display2Lines(firstLine, secondLine, firstLineLeftAligned = true, secondLineLeftAligned = true) {
    const formatLine = (line, leftAligned) => {
      if (!line) return ''.padEnd(20, ' ');
      if (line.length > 20) return line.substring(0, 20);
      return leftAligned ? line.padEnd(20, ' ') : line.padStart(20, ' ');
    };

    const escapeXml = (unsafe) =>
      unsafe
        .replaceAll('&', '&amp;')
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('"', '&quot;')
        .replaceAll("'", '&apos;');

    let text = formatLine(firstLine, firstLineLeftAligned);
    if (secondLine) {
      text += formatLine(secondLine, secondLineLeftAligned);
    }

    this.sendToDisplay(escapeXml(text));
  }

  sendToDisplay(text) {
    const xmlData = `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body><epos-display xmlns="http://www.epson-pos.com/schemas/2012/09/epos-display"><reset /><text>${text}</text></epos-display></s:Body></s:Envelope>`;

    fetch(this.displayAddress, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/xml',
        Accept: 'application/xml',
      },
      body: xmlData,
    })
      .then((res) => {
        if (res.ok) {
          res.text().then((t) => console.log('Response from server:', t));
        } else {
          console.error('Failed to send XML. Status:', res.status);
        }
      })
      .catch((err) => console.error('Error: Text could not be sent to display. ', err));
  }

  testPrint() {
    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('moaby\n');
    this.builder.addTextSize(1, 1);
    this.builder.addText('Testprint\n');
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addFeedLine(1);
    this.builder.addText(`Printer:\t${this.printer.description}\n`);
    this.builder.addText(`Network:\t${this.printer.ipAddress}\n`);
    this.builder.addText(
      `Categories:\t${
        this.printer.categories.length === 1 && this.printer.categories[0] === 'all'
          ? 'Alle\n'
          : this.printer.categories.join(', ')
      }\n`
    );
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.builder.addPulse();
    this.epos.send(this.builder.toString());
  }

  printEmployeeDailyReport(
    total,
    totalCard,
    totalCash,
    startDate,
    endDate,
    name,
    count,
    shopName,
    totalTipAmount,
    totalTipAmountCard,
    totalTipAmountCash,
    totalOpens,
    openTables,
    paperSize
  ) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };

    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(1);
    this.builder.addText(`${shopName}\n`);
    this.builder.addFeedLine(1);
    this.builder.addText(`Tagesbericht\n`);
    this.builder.addText(`${moment().format('DD.MM.YYYY HH:mm [Uhr]')}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText('----------------------------');
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('Mitarbeiter:', `${name}`));
    this.builder.addText(
      printLine2Parts('Beginn:', `${moment(startDate).format('DD.MM.YYYY HH:mm [Uhr]')}`)
    );
    this.builder.addText(
      printLine2Parts('Ende:', `${moment(endDate).format('DD.MM.YYYY HH:mm [Uhr]')}`)
    );
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('Anzahl Buchungen:', `${count}`));
    this.builder.addText(printLine2Parts('Gesamtumsatz:', `${asCurrency(total / 100)}€`));
    this.builder.addText(printLine2Parts('Zahlungen BAR:', `${asCurrency(totalCash / 100)}€`));
    this.builder.addText(printLine2Parts('Zahlungen Karte:', `${asCurrency(totalCard / 100)}€`));
    if (totalTipAmount !== null) {
      this.builder.addText(
        printLine2Parts('Trinkgeld Gesamt:', `${asCurrency(totalTipAmount / 100)}€`)
      );
    }
    if (totalTipAmountCash !== null) {
      this.builder.addText(
        printLine2Parts('Trinkgeld BAR:', `${asCurrency(totalTipAmountCash / 100)}€`)
      );
    }
    if (totalTipAmountCard !== null) {
      this.builder.addText(
        printLine2Parts('Trinkgeld Karte:', `${asCurrency(totalTipAmountCard / 100)}€`)
      );
    }
    this.builder.addText(
      printLine2Parts('Unbez. Bestellungen:', `${asCurrency(totalOpens / 100)}€`)
    );
    this.builder.addText('Unbez. Tische:\n');
    this.builder.addText(openTables.join(','));
    this.builder.addFeedLine(3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    // this.builder.addText('moaby sagt danke!');
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printCategory(bookedItems, booking, roomName, isRepeatedPrint) {
    if (bookedItems.find((item) => item.isRefund === false)) {
      const cleanedUpBookedItems = bookedItems.map((item) => {
        const newItem = {
          itemTitle: item.itemTitle,
          categoryName: item.categoryName,
          specialSelections: item.specialSelections.map((special) => ({
            specialSelectionId: special.specialSelectionId,
            specialSelectionTitle: special.specialSelectionTitle,
            specialSelectionPrice: special.specialSelectionPrice,
          })),
          options:
            item.options && item.options.length > 0
              ? item.options.map((option) => ({ name: option.name, price: option.price }))
              : null,
          amount: item.amount,
          taxRate: item.taxRate,
          isRefund: item.isRefund,
        };
        if (item.notes) newItem.notes = item.notes;
        return newItem;
      });
      const countElement = (index) =>
        countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
          .true;
      if (booking.table) {
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        if (booking.table.tableNumber) {
          this.builder.addText(`Tisch\n`);
          this.builder.addTextSize(4, 4);
          this.builder.addText(`${booking.table.tableNumber}\n`);
        }
        this.builder.addTextSize(1, 1);
        this.builder.addText(`${booking.table.tableDescription}\n\n`);
      }
      this.builder.addTextSize(1, 1);
      if (isRepeatedPrint) {
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addTextStyle(undefined, undefined, true, undefined);
        this.builder.addText(`*** Wiederholungsdruck ***\n`);
      }
      this.builder.addTextStyle(undefined, undefined, false, undefined);
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      if (roomName != null) {
        this.builder.addText(`Raum:\t\t${roomName}\n`);
      }
      this.builder.addText(`Buchung:\t${booking.bookingId}\n`);
      this.builder.addText(
        `Datum:\t\t${moment(booking.created.toString()).format('DD.MM.YYYY')}\n`
      );
      this.builder.addText(
        `Uhrzeit:\t${moment(booking.created.toString()).format('HH:mm [Uhr]')}\n`
      );
      this.builder.addText(`Gesamtsumme:\t${asCurrency(booking.amount / 100)} €\n`);
      if (booking.createdByName && booking.createdByName.length > 0) {
        this.builder.addText(`Erstellt von:\t${booking.createdByName}\n`);
      }
      if (booking.lastChangedByName && booking.lastChangedByName.length > 0) {
        this.builder.addText(`Geändert von:\t${booking.lastChangedByName}\n`);
      }
      if (booking.paidByName && booking.paidByName.length > 0) {
        this.builder.addText(`Bezahlt von:\t${booking.paidByName}\n`);
      }
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('------------------------------------');
      this.builder.addFeedLine(1);
      this.builder.addTextSize(2, 2);
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      for (let i = 0; i < bookedItems.length; i++) {
        const item = bookedItems[i];
        if (item.isRefund === false) {
          if (
            i ===
            findLastIndex(cleanedUpBookedItems, (element) =>
              isEqual(element, cleanedUpBookedItems[i])
            )
          ) {
            this.builder.addText(`- ${countElement(i)}x ${item.itemTitle}\n`);
            if (item.options && item.options.length > 0) {
              for (let j = 0; j < item.options.length; j++) {
                this.builder.addText(`-- O: ${item.options[j].name}\n`);
              }
            }
            if (item.specialSelections && item.specialSelections.length > 0) {
              for (let j = 0; j < item.specialSelections.length; j++) {
                this.builder.addText(`-- E: ${item.specialSelections[j].specialSelectionTitle}\n`);
              }
            }
            if (item.notes) {
              const notesChunks = chunkString(item.notes, 32);
              for (let k = 0; k < notesChunks.length; k++) {
                this.builder.addText(`-- H: ${notesChunks[k]}\n`);
              }
            }
          }
        }
      }
      if (booking.notes) {
        this.builder.addText(`Notiz:\t${booking.notes}\n`);
      }
      this.builder.addFeedLine(2);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addTextStyle(undefined, undefined, true, undefined);
      this.builder.addTextSize(1, 1);
      // this.builder.addText('moaby\n');
      this.builder.addFeedLine(2);
      this.builder.addCut(this.builder.CUT_FEED);
      this.epos.send(this.builder.toString());
    }
  }

  printInternal(booking, roomName, isRepeatedPrint) {
    const cleanedUpBookedItems = booking.bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    const countElement = (index) =>
      countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
        .true;
    if (booking.table) {
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      if (booking.table.tableNumber) {
        this.builder.addText(`Tisch\n`);
        this.builder.addTextSize(4, 4);
        this.builder.addText(`${booking.table.tableNumber}\n`);
      }
      this.builder.addTextSize(1, 1);
      this.builder.addText(`${booking.table.tableDescription}\n\n`);
    }
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(1);
    if (isRepeatedPrint) {
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addTextStyle(undefined, undefined, true, undefined);
      this.builder.addText(`*** Wiederholungsdruck ***\n`);
    }
    this.builder.addTextStyle(undefined, undefined, false, undefined);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    if (roomName != null) {
      this.builder.addText(`Raum:\t\t${roomName}\n`);
    }
    this.builder.addText(`Buchung:\t${booking.bookingId}\n`);
    this.builder.addText(`Datum:\t\t${moment(booking.created.toString()).format('DD.MM.YYYY')}\n`);
    this.builder.addText(`Uhrzeit:\t${moment(booking.created.toString()).format('HH:mm [Uhr]')}\n`);
    this.builder.addText(`Gesamtsumme:\t${asCurrency(booking.amount / 100)} €\n`);
    if (booking.createdByName && booking.createdByName.length > 0) {
      this.builder.addText(`Erstellt von:\t${booking.createdByName}\n`);
    }
    if (booking.lastChangedByName && booking.lastChangedByName.length > 0) {
      this.builder.addText(`Geändert von:\t${booking.lastChangedByName}\n`);
    }
    if (booking.paidByName && booking.paidByName.length > 0) {
      this.builder.addText(`Bezahlt von:\t${booking.paidByName}\n`);
    }
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('------------------------------------');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    for (let i = 0; i < booking.bookedItems.length; i++) {
      const item = booking.bookedItems[i];
      if (item.isRefund === false) {
        if (
          i ===
          findLastIndex(cleanedUpBookedItems, (element) =>
            isEqual(element, cleanedUpBookedItems[i])
          )
        ) {
          this.builder.addText(`- ${countElement(i)}x ${item.itemTitle}\n`);
          if (item.options && item.options.length > 0) {
            for (let j = 0; j < item.options.length; j++) {
              this.builder.addText(`-- O: ${item.options[j].name}\n`);
            }
          }
          if (item.specialSelections && item.specialSelections.length > 0) {
            for (let j = 0; j < item.specialSelections.length; j++) {
              this.builder.addText(`-- E: ${item.specialSelections[j].specialSelectionTitle}\n`);
            }
          }
          if (item.notes) {
            const notesChunks = chunkString(item.notes, 32);
            for (let k = 0; k < notesChunks.length; k++) {
              this.builder.addText(`-- H: ${notesChunks[k]}\n`);
            }
          }
        }
      }
    }
    if (booking.notes) {
      this.builder.addText(`Notiz:\t${booking.notes}\n`);
    }
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    this.builder.addTextSize(1, 1);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(2);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printCustomer(booking, shop, paperSize, isBew, isZwisch, logo) {
    const cleanedUpBookedItems = booking.bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    const countElement = (index) =>
      countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
        .true;
    const printSpecialSelectionLine = (title) => {
      const leftSideSpace = 2 + title.length;
      const availableSpace = paperSize - 8;

      let leftString;
      if (availableSpace < leftSideSpace) {
        leftString = ` - ${title}`.substr(0, availableSpace - 4).padEnd(availableSpace, '.');
      } else {
        leftString = ` - ${title}`.padEnd(availableSpace, ' ');
      }
      return `    ${leftString}`;
    };
    const printLine = (count, title, price, taxRate) => {
      const countMaxSize = 3;
      const priceSize =
        taxRate.length === 0 ? asCurrency(price).length + 2 : asCurrency(price).length + 3; // 3 because €A or €B and some space
      const titleMaxSize = paperSize - countMaxSize - priceSize - 2; // because of space between title and price
      const titleMaxString = title.substring(0, titleMaxSize);
      const countMaxString =
        count === 0 ? ''.padStart(countMaxSize, ' ') : count.toString().padStart(countMaxSize, ' ');
      const result = `${countMaxString} ${titleMaxString}${''.padEnd(
        titleMaxSize - title.length,
        ' '
      )} ${asCurrency(price)}€${taxRate}`;
      return result;
    };
    const printDiscount = (discountAmount) => {
      const left = `Rabatt`;
      const rightSize = `-${asCurrency(discountAmount)}€`.length + 2;
      const padding = paperSize - left.length - rightSize - 7;
      const result = `     - ${left}${''.padEnd(padding, ' ')} -${asCurrency(discountAmount)}€`;
      return result;
    };
    const printLine2Parts = (left, right) => {
      const availableSpace = 17 - right.length + 3;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };
    const printTSE = () => {
      if (booking.tssTransactions && booking.tssTransactions.length > 0) {
        this.builder.addText(
          `TSE-Start: ${moment(booking.tssTransactions[0].timeStart * 1000).format(
            'DD.MM.YYYY HH:mm:ss [Uhr]'
          )}\n`
        );
      } else {
        this.builder.addText(
          `TSE-Start: ${moment(booking.created).format('DD.MM.YYYY HH:mm:ss [Uhr]')}\n`
        );
      }
      const tssReceipts = booking.tssTransactions
        ? booking.tssTransactions.filter(
            (tss) =>
              tss.qrCodeData &&
              tss.qrCodeData.length > 0 &&
              tss.qrCodeData.includes('Kassenbeleg-V1')
          )
        : [];
      if (
        (booking.tssTransactions &&
          booking.tssTransactions.length > 0 &&
          booking.tssTransactions[booking.tssTransactions.length - 1].error.isError === true) ||
        (booking.tssTransactions && booking.tssTransactions.length === 0) ||
        booking.tssTransactions == null
      ) {
        this.builder.addTextSize(1, 1);
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText('TSE ist ausgefallen.');
      } else {
        this.builder.addFeedLine(2);
        this.builder.addTextSize(3, 3);
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        tssReceipts.forEach((tss) => {
          this.builder.addSymbol(
            tss.qrCodeData,
            this.builder.SYMBOL_QRCODE_MODEL_2,
            this.builder.LEVEL_Q,
            3
          );
          this.builder.addFeedLine(2);
        });
      }
    };
    const calculateTaxForBookedItem = (item, taxRate) => {
      if (item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)) {
        if (item.discount.amount > 0) {
          return ((item.amount - item.discount.amount) / (1 + taxRate)) * taxRate;
        }
        if (item.discount.percentage > 0) {
          return ((item.amount * (1 - item.discount.percentage)) / (1 + taxRate)) * taxRate;
        }
      }
      return (item.amount / (1 + taxRate)) * taxRate;
    };
    const reducedTaxes = booking.bookedItems
      .filter((item) => item.isRefund === false && item.taxRate === 0.07)
      .reduce((summ, bookedItem) => summ + calculateTaxForBookedItem(bookedItem, 0.07), 0);
    const normalTaxes = booking.bookedItems
      .filter((item) => item.isRefund === false && item.taxRate === 0.19)
      .reduce((summ, bookedItem) => summ + calculateTaxForBookedItem(bookedItem, 0.19), 0);
    const totalTaxes = normalTaxes + reducedTaxes;
    this.builder.addTextSize(1, 1);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addFeedLine(1);
    if (logo) {
      this.builder.addImage(logo, 0, 0, logo.canvas.width, logo.canvas.height);
      this.builder.addFeedLine(1);
      this.builder.addTextSize(1, 1);
      this.builder.addText(`${shop.name}\n`);
      this.builder.addText(`${shop.address.street}\n`);
      this.builder.addText(`${shop.address.zip} ${shop.address.city}\n`);
      if (shop.phoneNumber) {
        this.builder.addText(`${shop.phoneNumber}\n\n`);
      }
      this.builder.addFeedLine(1);
    } else {
      this.builder.addTextSize(2, 2);
      this.builder.addText(`${shop.name}\n`);
      this.builder.addFeedLine(2);
      this.builder.addTextSize(1, 1);
      this.builder.addText(`${shop.address.street}\n`);
      this.builder.addText(`${shop.address.zip} ${shop.address.city}\n`);
      if (shop.phoneNumber) {
        this.builder.addText(`${shop.phoneNumber}\n\n`);
      }
      this.builder.addFeedLine(1);
    }
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    this.builder.addText(isZwisch ? '- Zwischenbeleg -\n\n' : '- Kundenbeleg -\n\n');
    this.builder.addTextStyle(undefined, undefined, false, undefined);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addText(`USt. Nr.: ${shop.taxId}\n`);
    this.builder.addText(`Datum: ${moment(booking.created.toString()).format('DD.MM.YYYY')}\n`);
    this.builder.addText(`Uhrzeit: ${moment(booking.created.toString()).format('HH:mm [Uhr]')}\n`);
    this.builder.addText(
      isZwisch
        ? '\n\n'
        : `Rechnungsnr.: ${booking.bookingId}/${moment(booking.created).format('YYYY')}\n\n`
    );
    if (isZwisch == null || isZwisch === false) {
      if (booking.paymentType === 'card') {
        this.builder.addText(`Zahlungsart: Kartenzahlung\n`);
        if (booking.stripe) {
          this.builder.addText(`Kartenhersteller: ${booking.stripe.cardBrand}\n`);
          this.builder.addText(`Letzten 4 Ziffern: ${booking.stripe.cardLast4}\n\n`);
        }
      }
      if (booking.paymentType === 'cash') {
        this.builder.addText(`Zahlungsart: Barzahlung\n`);
      }
    }
    if (booking.paidByName && booking.paidByName.length > 0) {
      this.builder.addText(`Es bediente Sie: ${booking.paidByName}\n`);
    }
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText(''.padEnd(paperSize - 2, '-'));
    this.builder.addFeedLine(1);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    booking.bookedItems.forEach((item, index) => {
      if (item.isRefund === false) {
        if (
          index ===
          findLastIndex(cleanedUpBookedItems, (element) =>
            isEqual(element, cleanedUpBookedItems[index])
          )
        ) {
          this.builder.addText(
            `${printLine(
              countElement(index),
              item.itemTitle,
              (countElement(index) * item.amount) / 100,
              item.taxRate === 0.19 ? 'B' : 'A'
            )}\n`
          );
          if (item.options && item.options.length > 0) {
            item.options.forEach((option) => {
              this.builder.addText(`${printSpecialSelectionLine(option.name)}\n`);
            });
          }
          if (item.specialSelections && item.specialSelections.length > 0) {
            item.specialSelections.forEach((special) => {
              this.builder.addText(`${printSpecialSelectionLine(special.specialSelectionTitle)}\n`);
            });
          }
          if (item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)) {
            const discountAmount =
              item.discount.amount > 0
                ? (countElement(index) * item.discount.amount) / 100
                : (countElement(index) *
                    (item.amount -
                      Math.round(item.amount - item.amount * item.discount.percentage))) /
                  100;
            this.builder.addText(`${printDiscount(discountAmount)}\n`);
          }
        }
      }
    });
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText(''.padEnd(paperSize - 2, '-'));
    this.builder.addFeedLine(1);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addTextSize(2, 2);
    this.builder.addText(printLine2Parts('TOTAL', `${asCurrency(booking.amount / 100)}€`));
    this.builder.addText('\n');
    this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    if (reducedTaxes > 0)
      this.builder.addText(`A 7% MwSt.: ${asCurrency(reducedTaxes / 100)}€  \n`);
    if (normalTaxes > 0) this.builder.addText(`B 19% MwSt.: ${asCurrency(normalTaxes / 100)}€  \n`);
    this.builder.addText(`MwSt. Gesamt: ${asCurrency(totalTaxes / 100)}€  \n`);
    if (booking.tipAmount)
      this.builder.addText(`Trinkgeld: ${asCurrency(booking.tipAmount / 100)}€  \n`);
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addTextSize(1, 1);
    if (isZwisch == null || isZwisch === false) {
      printTSE();
    }
    this.builder.addFeedLine(1);
    // this.builder.addTextSize(1, 1);
    if (isZwisch === true) {
      this.builder.addTextStyle(undefined, undefined, true, undefined);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('Dies ist keine Rechnung.\n');
    }
    // this.builder.addText('moaby sagt danke!');
    this.builder.addFeedLine(1);

    if (isBew === true) {
      this.builder.addTextSize(1, 1);
      this.builder.addTextStyle(undefined, undefined, false, undefined);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText(`${''.padEnd(paperSize - 2, '-')}\n`);
      this.builder.addText('Bewirtungsaufwand - Angaben nach\n');
      this.builder.addText('Par. 4 Abs. 5 Ziffer 2 EStG\n');
      this.builder.addText(`${''.padEnd(paperSize - 2, '-')}\n`);
      this.builder.addFeedLine(1);
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addText('Bewirtete Personen\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText('Anlass der Bewirtung\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addFeedLine(2);
      this.builder.addText('Höhe der Aufwendungen\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addText('bei Bewirtung im Restaurant\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addText('in anderen Fällen\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addText('Ort und Datum\n');
      this.builder.addFeedLine(2);
      this.builder.addText(`${''.padEnd(paperSize - 2, '_')}\n`);
      this.builder.addText('Unterschrift\n');
      this.builder.addTextSize(1, 1);

      this.builder.addFeedLine(3);
    }

    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printRefundCustomer(booking, shop, paperSize, logo) {
    const cleanedUpBookedItems = booking.bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    const printLine2Parts = (left, right) => {
      const availableSpace = 17 - right.length + 3;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };
    const countElement = (index) =>
      countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
        .true;
    const printSpecialSelectionLine = (title) => {
      const leftSideSpace = 2 + title.length;
      const availableSpace = paperSize - 8;

      let leftString;
      if (availableSpace < leftSideSpace) {
        leftString = ` - ${title}`.substr(0, availableSpace - 4).padEnd(availableSpace, '.');
      } else {
        leftString = ` - ${title}`.padEnd(availableSpace, ' ');
      }
      return `    ${leftString}`;
    };
    const printLine = (count, title, price, taxRate) => {
      const countMaxSize = 3;
      const priceSize =
        taxRate.length === 0 ? asCurrency(price).length + 2 : asCurrency(price).length + 3; // 3 because €A or €B and some space
      const titleMaxSize = paperSize - countMaxSize - priceSize - 2; // because of space between title and price
      const titleMaxString = title.substring(0, titleMaxSize);
      const countMaxString =
        count === 0 ? ''.padStart(countMaxSize, ' ') : count.toString().padStart(countMaxSize, ' ');
      const result = `${countMaxString} ${titleMaxString}${''.padEnd(
        titleMaxSize - title.length,
        ' '
      )} ${asCurrency(price)}€${taxRate}`;
      return result;
    };
    const printDiscount = (discountAmount) => {
      const left = `Rabatt`;
      const rightSize = `-${asCurrency(discountAmount)}€`.length + 2;
      const padding = paperSize - left.length - rightSize - 7;
      const result = `     - ${left}${''.padEnd(padding, ' ')}  ${asCurrency(discountAmount)}€`;
      return result;
    };
    const printTSE = () => {
      if (booking.tssTransactions && booking.tssTransactions.length > 0) {
        this.builder.addText(
          `TSE-Start: ${moment(booking.tssTransactions[0].timeStart * 1000).format(
            'DD.MM.YYYY HH:mm:ss [Uhr]'
          )}\n`
        );
      } else {
        this.builder.addText(
          `TSE-Start: ${moment(booking.created).format('DD.MM.YYYY HH:mm:ss [Uhr]')}\n`
        );
      }
      const tssReceipts = booking.tssTransactions
        ? booking.tssTransactions.filter(
            (tss) =>
              tss.qrCodeData &&
              tss.qrCodeData.length > 0 &&
              tss.qrCodeData.includes('Kassenbeleg-V1')
          )
        : [];
      if (
        (booking.tssTransactions &&
          booking.tssTransactions.length > 0 &&
          booking.tssTransactions[booking.tssTransactions.length - 1].error.isError === true) ||
        (booking.tssTransactions && booking.tssTransactions.length === 0) ||
        booking.tssTransactions == null
      ) {
        this.builder.addTextSize(1, 1);
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText('TSE ist ausgefallen.');
      } else {
        this.builder.addFeedLine(2);
        this.builder.addTextSize(3, 3);
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        tssReceipts.forEach((tss) => {
          this.builder.addSymbol(
            tss.qrCodeData,
            this.builder.SYMBOL_QRCODE_MODEL_2,
            this.builder.LEVEL_Q,
            3
          );
          this.builder.addFeedLine(2);
        });
      }
    };
    const calculateTaxForBookedItem = (item, taxRate) => {
      if (item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)) {
        if (item.discount.amount > 0) {
          return ((item.amount - item.discount.amount) / (1 + taxRate)) * taxRate;
        }
        if (item.discount.percentage > 0) {
          return ((item.amount * (1 - item.discount.percentage)) / (1 + taxRate)) * taxRate;
        }
      }
      return (item.amount / (1 + taxRate)) * taxRate;
    };
    const reducedTaxes = booking.bookedItems
      .filter((item) => item.isCancelled === false && item.taxRate === 0.07)
      .reduce((summ, bookedItem) => summ + calculateTaxForBookedItem(bookedItem, 0.07), 0);
    const normalTaxes = booking.bookedItems
      .filter((item) => item.isCancelled === false && item.taxRate === 0.19)
      .reduce((summ, bookedItem) => summ + calculateTaxForBookedItem(bookedItem, 0.19), 0);
    const totalTaxes = normalTaxes + reducedTaxes;
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addFeedLine(1);
    if (logo) {
      this.builder.addImage(logo, 0, 0, logo.canvas.width, logo.canvas.height);
      this.builder.addFeedLine(1);
      this.builder.addTextSize(1, 1);
      this.builder.addText(`${shop.name}\n`);
      this.builder.addText(`${shop.address.street}\n`);
      this.builder.addText(`${shop.address.zip} ${shop.address.city}\n`);
      if (shop.phoneNumber) {
        this.builder.addText(`${shop.phoneNumber}\n\n`);
      }
      this.builder.addFeedLine(1);
    } else {
      this.builder.addTextSize(2, 2);
      this.builder.addText(`${shop.name}\n`);
      this.builder.addFeedLine(1);
      this.builder.addTextSize(1, 1);
      this.builder.addText(`${shop.address.street}\n`);
      this.builder.addText(`${shop.address.zip} ${shop.address.city}\n`);
      if (shop.phoneNumber) {
        this.builder.addText(`${shop.phoneNumber}\n\n`);
      }
      this.builder.addFeedLine(1);
    }
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    this.builder.addText('- Storno Bon -\n\n');
    this.builder.addTextStyle(undefined, undefined, false, undefined);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addText(`USt. Nr.: ${shop.taxId}\n`);
    this.builder.addText(`Datum: ${moment(booking.created.toString()).format('DD.MM.YYYY')}\n`);
    this.builder.addText(`Uhrzeit: ${moment(booking.created.toString()).format('HH:mm [Uhr]')}\n`);
    this.builder.addText(
      `Rechnungsnr.: ${booking.bookingId}/${moment(booking.created).format('YYYY')}R\n\n`
    );
    if (booking.paymentType === 'card') {
      this.builder.addText(`Zahlungsart: Kartenzahlung\n`);
      if (booking.stripe) {
        this.builder.addText(`Kartenhersteller: ${booking.stripe.cardBrand}\n`);
        this.builder.addText(`Letzten 4 Ziffern: ${booking.stripe.cardLast4}\n\n`);
      }
    }
    if (booking.paymentType === 'cash') {
      this.builder.addText(`Zahlungsart: Barzahlung\n`);
    }
    if (booking.paidByName && booking.paidByName.length > 0) {
      this.builder.addText(`Es bediente Sie: ${booking.paidByName}\n`);
    }
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText(''.padEnd(paperSize - 2, '-'));
    this.builder.addFeedLine(1);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    booking.bookedItems.forEach((item, index) => {
      if (
        index ===
          findLastIndex(cleanedUpBookedItems, (element) =>
            isEqual(element, cleanedUpBookedItems[index])
          ) &&
        item.isCancelled === false
      ) {
        this.builder.addText(
          `${printLine(
            countElement(index),
            item.itemTitle,
            ((countElement(index) * item.amount) / 100) * -1,
            item.taxRate === 0.19 ? 'B' : 'A'
          )}\n`
        );
        if (item.options && item.options.length > 0) {
          item.options.forEach((option) => {
            this.builder.addText(`${printSpecialSelectionLine(option.name)}\n`);
          });
        }
        if (item.specialSelections && item.specialSelections.length > 0) {
          item.specialSelections.forEach((special) => {
            this.builder.addText(`${printSpecialSelectionLine(special.specialSelectionTitle)}\n`);
          });
        }
        if (item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)) {
          const discountAmount =
            item.discount.amount > 0
              ? (countElement(index) * item.discount.amount) / 100
              : (countElement(index) *
                  (item.amount -
                    Math.round(item.amount - item.amount * item.discount.percentage))) /
                100;
          this.builder.addText(`${printDiscount(discountAmount)}\n`);
        }
      }
    });
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText(''.padEnd(paperSize - 2, '-'));
    this.builder.addFeedLine(1);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addTextSize(1, 1);
    const getItemAmount = (item) => {
      if (item.discount && item.discount.amount > 0) {
        return Math.round(item.amount) - Math.round(item.discount.amount);
      }
      if (item.discount && item.discount.percentage > 0) {
        return item.amount * (1 - item.discount.percentage);
      }
      return item.amount;
    };
    this.builder.addTextSize(2, 2);
    this.builder.addText(
      printLine2Parts(
        'TOTAL',
        `${asCurrency(
          (booking.bookedItems.reduce(
            (sum, item) => sum + (item.isCancelled === false ? getItemAmount(item) : 0),
            0
          ) /
            100) *
            -1
        )}€`
      )
    );
    this.builder.addTextSize(1, 1);
    this.builder.addText('\n');
    this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    if (reducedTaxes > 0)
      this.builder.addText(`A 7% MwSt.: ${asCurrency(reducedTaxes / 100)}€  \n`);
    if (normalTaxes > 0) this.builder.addText(`B 19% MwSt.: ${asCurrency(normalTaxes / 100)}€  \n`);
    this.builder.addText(`MwSt. Gesamt: ${asCurrency(totalTaxes / 100)}€  \n`);
    if (booking.paymentType === 'card') {
      this.builder.addFeedLine(1);
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addText(`Die Summe der Erstattung wird dir in den nächsten Tagen überwiesen.\n`);
    }
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addTextSize(1, 1);
    printTSE();
    this.builder.addFeedLine(2);
    this.builder.addTextSize(1, 1);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    // this.builder.addText('moaby sagt danke!');

    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printZBon(
    zBon,
    paperSize,
    withProtocol,
    withTips,
    withDiscounts,
    withCounting,
    cashActual,
    address,
    phoneNumber,
    taxNumber,
    actualPerformance,
    difference
  ) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };
    const printLine3Parts = (left, middle, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left} ${middle}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };
    const printLine3PartsTransfers = (left, middle, right) => {
      const leftString = left.padEnd(10, ' ');
      const middleString = middle.padEnd(10, ' ');
      return `${leftString}${middleString}${right}\n`;
    };
    const printLine3PartsMoney = (left, middle, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;
      const leftSideStartSpacing = 6 - left.length;

      const leftString = `${left.padStart(
        leftSideStartSpacing + left.length,
        ' '
      )} ${middle.padStart(2 + middle.length, ' ')}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };
    const calculateBankNotes = () =>
      Object.keys(zBon.bankNotes).reduce(
        (sum, bankNote) => sum + zBon.bankNotes[bankNote] * parseFloat(bankNote),
        0
      );

    const calculateCoins = () =>
      Object.keys(zBon.coins).reduce((sum, coin) => sum + zBon.coins[coin] * parseFloat(coin), 0);

    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addFeedLine(1);
    this.builder.addText(`${zBon.shopName}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText(`${address.street}\n`);
    this.builder.addText(`${address.zip} ${address.city}\n`);
    if (phoneNumber) {
      this.builder.addText(`${phoneNumber}\n`);
    }
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addText(`Z-Bon ${zBon.number}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText('----------------------------');
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addFeedLine(2);
    this.builder.addText('Allgemein\n');
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('Z-Bon Nr.:', (zBon.number || 0).toString()));
    this.builder.addText(printLine2Parts('USt. Nr.:', taxNumber));
    this.builder.addText(
      printLine2Parts('Erstellt am:', moment(zBon.created).format('DD.MM.YYYY'))
    );
    this.builder.addText(printLine2Parts('Uhrzeit:', moment(zBon.created).format('HH:mm [Uhr]')));
    this.builder.addText(
      printLine2Parts(
        'Bericht Beginn:',
        moment(zBon.issueDateStart).format('DD.MM.YYYY HH:mm [Uhr]')
      )
    );
    this.builder.addText(
      printLine2Parts('Bericht Ende:', moment(zBon.issueDateEnd).format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    if (zBon.lastBookingDate) {
      this.builder.addText(
        printLine2Parts(
          'Letzte Buchung:',
          moment(zBon.lastBookingDate).format('DD.MM.YYYY HH:mm [Uhr]')
        )
      );
    }
    this.builder.addFeedLine(2);
    this.builder.addText('Umsatz\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Umsatz exkl. Mwst.:', `${asCurrency(zBon.totalWithoutTaxes / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('7% Mwst.:', `${asCurrency(zBon.reducedTaxesTotal / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('19% Mwst.:', `${asCurrency(zBon.normalTaxesTotal / 100)}€`)
    );
    this.builder.addText(printLine2Parts('MwSt. Gesamt:', `${asCurrency(zBon.taxes / 100)}€`));
    this.builder.addText(
      printLine2Parts('Umsatz 7% MwSt.:', `${asCurrency(zBon.reducedTaxesTotalRevenue / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Umsatz 19% MwSt.:', `${asCurrency(zBon.normalTaxesTotalRevenue / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Umsatz inkl. MwSt.:', `${asCurrency(zBon.total / 100)}€`)
    );
    this.builder.addText(printLine2Parts('Zahlungen BAR:', `${asCurrency(zBon.cash / 100)}€`));
    this.builder.addText(printLine2Parts('Zahlungen Karte:', `${asCurrency(zBon.card / 100)}€`));
    if (withTips === true) {
      this.builder.addFeedLine(2);
      this.builder.addText('Trinkgeld\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Trinkgeld Gesamt:', `${asCurrency(zBon.totalTipAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld BAR:', `${asCurrency(zBon.totalTipCash / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld Karte:', `${asCurrency(zBon.totalTipCard / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AG:', `${asCurrency(zBon.ownerTipsAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AN:', `${asCurrency(zBon.employeeTipsAmount / 100)}€`)
      );
    }
    if (withDiscounts === true) {
      this.builder.addFeedLine(2);
      this.builder.addText('Rabatt\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Anzahl Rabattierungen:', zBon.discountsCount.toString())
      );
      this.builder.addText(
        printLine2Parts('Summe Rabattierungen:', `${asCurrency(zBon.totalDiscountsAmount / 100)}€`)
      );
    }
    this.builder.addFeedLine(2);
    this.builder.addText('Erstattung\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Erstattungen:', zBon.partlyRefundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Teil-Erstattungen:', `${asCurrency(zBon.partlyRefund / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Erstattungen:', zBon.refundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Erstattungen:', `${asCurrency(zBon.refund / 100)}€`)
    );
    this.builder.addFeedLine(2);
    this.builder.addText('Storno\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Stornos:', (zBon.partlyCancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Teil-Stornos:', `${asCurrency((zBon.partlyCancel || 0) / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Stornos:', (zBon.cancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Stornos:', `${asCurrency((zBon.cancel || 0) / 100)}€`)
    );
    if (zBon.transferredBookings && zBon.transferredBookings.length > 0) {
      this.builder.addFeedLine(2);
      this.builder.addText('Übertragene Buchungen\n');
      this.builder.addFeedLine(1);
      this.builder.addText(printLine3PartsTransfers('Buchung', 'Betrag', 'Datum'));
      zBon.transferredBookings.forEach((trans) =>
        this.builder.addText(
          printLine3PartsTransfers(
            `${trans.bookingId}`,
            `${asCurrency((trans.amount || 0) / 100)}€`,
            moment(trans.created).format('DD.MM.YY, HH:mm [Uhr]')
          )
        )
      );
      this.builder.addFeedLine(1);
      this.builder.addText('TS: Teil-Storniert, S: Storniert\n');
      this.builder.addText('TE: Teil-Erstattet, E: Erstattet\n');
    }
    this.builder.addFeedLine(2);
    if (withProtocol === true) {
      if (withCounting === true) {
        this.builder.addText('Kassen-Bestandsaufnahme');
        this.builder.addFeedLine(2);
        this.builder.addText(printLine3Parts('Anzahl  ', 'Banknoten', 'Betrag'));
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[200]).toString(),
            'Scheine zu 200€',
            `${asCurrency(zBon.bankNotes[200] * 200)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[100]).toString(),
            'Scheine zu 100€',
            `${asCurrency(zBon.bankNotes[100] * 100)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[50]).toString(),
            'Scheine zu 50€',
            `${asCurrency(zBon.bankNotes[50] * 50)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[20]).toString(),
            'Scheine zu 20€',
            `${asCurrency(zBon.bankNotes[20] * 20)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[10]).toString(),
            'Scheine zu 10€',
            `${asCurrency(zBon.bankNotes[10] * 10)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.bankNotes[5]).toString(),
            'Scheine zu 5€',
            `${asCurrency(zBon.bankNotes[5] * 5)}€`
          )
        );
        this.builder.addFeedLine(2);
        this.builder.addText(printLine3Parts('Anzahl  ', 'Münzen', 'Betrag'));
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[2]).toString(),
            'Münzen zu 2€',
            `${asCurrency(zBon.coins[2] * 2)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[1]).toString(),
            'Münzen zu 1€',
            `${asCurrency(zBon.coins[1])}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.5]).toString(),
            'Münzen zu 50Ct',
            `${asCurrency(zBon.coins[0.5] * 0.5)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.2]).toString(),
            'Münzen zu 20Ct',
            `${asCurrency(zBon.coins[0.2] * 0.2)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.1]).toString(),
            'Münzen zu 10Ct',
            `${asCurrency(zBon.coins[0.1] * 0.1)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.05]).toString(),
            'Münzen zu 5Ct',
            `${asCurrency(zBon.coins[0.05] * 0.05)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.02]).toString(),
            'Münzen zu 2Ct',
            `${asCurrency(zBon.coins[0.02] * 0.02)}€`
          )
        );
        this.builder.addText(
          printLine3PartsMoney(
            Math.round(zBon.coins[0.01]).toString(),
            'Münzen zu 1Ct',
            `${asCurrency(zBon.coins[0.01] * 0.01)}€`
          )
        );
        this.builder.addFeedLine(2);
        this.builder.addText('Kassenabstimmung');
        this.builder.addFeedLine(2);
        this.builder.addText(
          printLine2Parts('    Münzen - Gesamt', `${asCurrency(calculateCoins())}€`)
        );
        this.builder.addText(
          printLine2Parts('+   Noten - Gesamt', `${asCurrency(calculateBankNotes())}€`)
        );
        this.builder.addText(
          printLine2Parts('+/- sonstige Korrekturen', `${asCurrency(zBon.corrections)}€`)
        );
        if (zBon.corrections) {
          this.builder.addText(
            zBon.otherExplanations && zBon.otherExplanations.length > 0
              ? `    ${zBon.otherExplanations}`
              : '    k.A.'
          );
          this.builder.addText('\n');
        }
        this.builder.addText(
          printLine2Parts(
            '=   Kassen-Ist-Bestand',
            `${asCurrency(calculateBankNotes() + calculateCoins() + zBon.corrections)}€`
          )
        );
      } else {
        this.builder.addText('Kassenabstimmung');
        this.builder.addFeedLine(2);
        this.builder.addText(
          printLine2Parts('    Kassen-Bestandsaufnahme', `${asCurrency(actualPerformance || 0)}€`)
        );
        this.builder.addText(
          printLine2Parts('+/- sonstige Korrekturen', `${asCurrency(zBon.corrections)}€`)
        );
        if (zBon.corrections) {
          this.builder.addText(
            zBon.otherExplanations && zBon.otherExplanations.length > 0
              ? `    ${zBon.otherExplanations}`
              : '    k.A.'
          );
          this.builder.addText('\n');
        }
        this.builder.addText(
          printLine2Parts('=   Kassen-Ist-Bestand', `${asCurrency(cashActual || 0)}€`)
        );
      }
      this.builder.addText(printLine2Parts('-   Wechselgeld', `${asCurrency(zBon.change)}€`));
      this.builder.addText(
        printLine2Parts('=   Kassen-Ist-Einnahmen', `${asCurrency(cashActual - zBon.change)}€`)
      );
      if (zBon.ownerTotalTipsCash && zBon.ownerTotalTipsCash > 0) {
        this.builder.addText(
          printLine2Parts(
            '-   Kassen-Soll-Einnahmen*',
            `${asCurrency((zBon.cash + zBon.ownerTotalTipsCash) / 100)}€`
          )
        );
      } else {
        this.builder.addText(
          printLine2Parts('-   Kassen-Soll-Einnahmen', `${asCurrency(zBon.cash / 100)}€`)
        );
      }
      this.builder.addText(printLine2Parts('=   Kassendifferenz', `${difference}€`));
      this.builder.addFeedLine(2);
      this.builder.addFeedLine(4);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('____________________________\n');
      this.builder.addText('Datum und Unterschrift Kassenführer');
      this.builder.addFeedLine(2);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('----------------------------');
      this.builder.addFeedLine(2);
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addText(printLine2Parts('    Kassen-Ist-Bestand', `${asCurrency(cashActual)}€`));
      for (let i = 0; i < zBon.expenses.length; i++) {
        const expense = zBon.expenses[i];
        this.builder.addText(
          printLine2Parts(`-   ${expense.title}`, `${asCurrency(expense.price)}€`)
        );
      }
      if (withCounting === true) {
        this.builder.addText(
          printLine2Parts(
            '=   Wechselgeld/Übertrag',
            `${asCurrency(cashActual - zBon.expenses.reduce((sum, { price }) => sum + price, 0))}€`
          )
        );
      } else {
        this.builder.addText(
          printLine2Parts(
            '=   Wechselgeld/Übertrag',
            `${asCurrency(cashActual - zBon.expenses.reduce((sum, { price }) => sum + price, 0))}€`
          )
        );
      }
    }

    this.builder.addFeedLine(4);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('____________________________\n');
    this.builder.addText('Datum und Unterschrift');
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('----------------------------');
    this.builder.addFeedLine(1);
    if (zBon.ownerTotalTipsCash && zBon.ownerTotalTipsCash > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addText('*Beinhaltet auch die AG Bar Trinkgelder.');
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    }
    this.builder.addFeedLine(2);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    // this.builder.addText('moaby sagt danke!');
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printXBon(zBon, paperSize, withTips, withDiscounts, address, phoneNumber, taxNumber) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };

    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addFeedLine(1);
    this.builder.addText(`${zBon.shopName}\n`);
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(1);
    this.builder.addText(`${address.street}\n`);
    this.builder.addText(`${address.zip} ${address.city}\n`);
    if (phoneNumber) {
      this.builder.addText(`${phoneNumber}\n`);
    }
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addText(`X-Bon\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText('----------------------------');
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addText('Allgemein\n');
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('USt. Nr.:', taxNumber));
    this.builder.addText(
      printLine2Parts('Erstellung:', moment(zBon.created).format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    this.builder.addText(printLine2Parts('Uhrzeit:', moment(zBon.created).format('HH:mm [Uhr]')));
    this.builder.addText(
      printLine2Parts(
        'Bericht Beginn:',
        moment(zBon.issueDateStart).format('DD.MM.YYYY HH:mm [Uhr]')
      )
    );
    this.builder.addText(
      printLine2Parts('Bericht Ende:', moment(zBon.issueDateEnd).format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    this.builder.addFeedLine(2);
    this.builder.addText('Umsatz\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Umsatz exkl. Mwst.:', `${asCurrency(zBon.totalWithoutTaxes / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('7% Mwst.:', `${asCurrency(zBon.reducedTaxesTotal / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('19% Mwst.:', `${asCurrency(zBon.normalTaxesTotal / 100)}€`)
    );
    this.builder.addText(printLine2Parts('MwSt. Gesamt:', `${asCurrency(zBon.taxes / 100)}€`));
    this.builder.addText(
      printLine2Parts('Umsatz 7% MwSt.:', `${asCurrency(zBon.reducedTaxesTotalRevenue / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Umsatz 19% MwSt.:', `${asCurrency(zBon.normalTaxesTotalRevenue / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Umsatz inkl. MwSt.:', `${asCurrency(zBon.total / 100)}€`)
    );
    this.builder.addText(printLine2Parts('Zahlungen BAR:', `${asCurrency(zBon.cash / 100)}€`));
    this.builder.addText(printLine2Parts('Zahlungen Karte:', `${asCurrency(zBon.card / 100)}€`));
    this.builder.addText(
      printLine2Parts('Unbez. Bestellungen:', `${asCurrency(zBon.totalOpenBookings / 100)}€`)
    );
    if (withTips === true) {
      this.builder.addFeedLine(2);
      this.builder.addText('Trinkgeld\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Trinkgeld Gesamt:', `${asCurrency(zBon.totalTipAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld BAR:', `${asCurrency(zBon.totalTipCash / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld Karte:', `${asCurrency(zBon.totalTipCard / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AG:', `${asCurrency(zBon.ownerTipsAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AN:', `${asCurrency(zBon.employeeTipsAmount / 100)}€`)
      );
    }
    if (withDiscounts === true) {
      this.builder.addFeedLine(2);
      this.builder.addText('Rabatt\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Anzahl Rabattierungen:', zBon.discountsCount.toString())
      );
      this.builder.addText(
        printLine2Parts('Summe Rabattierungen:', `${asCurrency(zBon.totalDiscountsAmount / 100)}€`)
      );
    }
    this.builder.addFeedLine(2);
    this.builder.addText('Erstattung\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Erstattungen:', zBon.partlyRefundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Teil-Erstattungen:', `${asCurrency(zBon.partlyRefund / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Erstattungen:', zBon.refundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Erstattungen:', `${asCurrency(zBon.refund / 100)}€`)
    );
    this.builder.addFeedLine(2);
    this.builder.addText('Storno\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Stornos:', (zBon.partlyCancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Teil-Stornos:', `${asCurrency((zBon.partlyCancel || 0) / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Stornos:', (zBon.cancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Stornos:', `${asCurrency((zBon.cancel || 0) / 100)}€`)
    );
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addFeedLine(3);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    // this.builder.addText('moaby sagt danke!');
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printLogo(context, width, height) {
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addImage(context, 0, 0, width, height);
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printEmployeeSummary(employeeData, shopName, startDate, endDate, paperSize, withTips) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };

    this.builder.addTextSize(1, 1);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addTextSize(2, 2);
    this.builder.addFeedLine(1);
    this.builder.addText(`${shopName}\n`);
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(1);
    this.builder.addText('Mitarbeiter\n');
    this.builder.addText('Tageszusammenfassung\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Erstellt am:', moment().format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    this.builder.addText(
      printLine2Parts('Beginn:', moment(startDate).format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    this.builder.addText(
      printLine2Parts('Ende:', moment(endDate).format('DD.MM.YYYY HH:mm [Uhr]'))
    );
    this.builder.addFeedLine(2);
    this.builder.addText(''.padStart(paperSize, '-'));
    this.builder.addFeedLine(2);
    employeeData.forEach((employee) => {
      this.builder.addText(printLine2Parts('Mitarbeiter', Object.keys(employee)[0]));
      this.builder.addText(
        printLine2Parts('Gesamtumsatz', `${asCurrency(Object.values(employee)[0].amount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts(
          'Zahlungen BAR',
          `${asCurrency(Object.values(employee)[0].totalCash / 100)}€`
        )
      );
      this.builder.addText(
        printLine2Parts(
          'Zahlungen Karte',
          `${asCurrency(Object.values(employee)[0].totalCard / 100)}€`
        )
      );
      if (withTips === true) {
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld Gesamt',
            `${asCurrency(Object.values(employee)[0].tipAmount / 100)}€`
          )
        );
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld BAR',
            `${asCurrency(Object.values(employee)[0].totalTipCash / 100)}€`
          )
        );
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld Karte',
            `${asCurrency(Object.values(employee)[0].totalTipCard / 100)}€`
          )
        );
      }
      this.builder.addText(''.padStart(paperSize, '-'));
      this.builder.addText('\n');
    });
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printMonthlyReport(report, selectedMonth, paperSize, withTips, withDiscounts) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace - 2;

      const leftString = `${left}`.padEnd(availableSpace, ' ');
      return `${leftString} ${right}\n`;
    };

    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addFeedLine(1);
    this.builder.addText(`${report.shopName}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText(`Monatsbericht ${moment(selectedMonth).format('MM/YYYY')}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addText('----------------------------');
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addFeedLine(1);
    this.builder.addText('Allgemein\n');
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('Anzahl Buchungen:', `${report.bookingsCount}`));
    this.builder.addText(printLine2Parts('Anzahl BAR:', `${report.bookingsCountCash}`));
    this.builder.addText(printLine2Parts('Anzahl Karte:', `${report.bookingsCountCard}`));
    this.builder.addFeedLine(1);
    this.builder.addText('Umsatz\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Umsatz exkl. Mwst.:', `${asCurrency(report.totalWithoutTaxes / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('7% Mwst.:', `${asCurrency(report.reducedTaxesTotal / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('19% Mwst.:', `${asCurrency(report.normalTaxesTotal / 100)}€`)
    );
    this.builder.addText(printLine2Parts('MwSt. Gesamt:', `${asCurrency(report.taxes / 100)}€`));
    this.builder.addText(
      printLine2Parts('Umsatz inkl. MwSt.:', `${asCurrency(report.total / 100)}€`)
    );
    this.builder.addText(printLine2Parts('Zahlungen BAR:', `${asCurrency(report.cash / 100)}€`));
    this.builder.addText(printLine2Parts('Zahlungen Karte:', `${asCurrency(report.card / 100)}€`));
    if (withTips === true) {
      this.builder.addFeedLine(1);
      this.builder.addText('Trinkgeld\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Trinkgeld Gesamt:', `${asCurrency(report.totalTipAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld BAR:', `${asCurrency(report.totalTipCash / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld Karte:', `${asCurrency(report.totalTipCard / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AG:', `${asCurrency(report.ownerTipsAmount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts('Trinkgeld AN:', `${asCurrency(report.employeeTipsAmount / 100)}€`)
      );
    }
    if (withDiscounts === true) {
      this.builder.addFeedLine(2);
      this.builder.addText('Rabatt\n');
      this.builder.addFeedLine(1);
      this.builder.addText(
        printLine2Parts('Anzahl Rabattierungen:', report.discountsCount.toString())
      );
      this.builder.addText(
        printLine2Parts(
          'Summe Rabattierungen:',
          `${asCurrency(report.totalDiscountsAmount / 100)}€`
        )
      );
    }
    this.builder.addFeedLine(2);
    this.builder.addText('Erstattung\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Erstattungen:', report.partlyRefundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Teil-Erstattungen:', `${asCurrency(report.partlyRefund / 100)}€`)
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Erstattungen:', report.refundNumber.toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Erstattungen:', `${asCurrency(report.refund / 100)}€`)
    );
    this.builder.addFeedLine(2);
    this.builder.addText('Storno\n');
    this.builder.addFeedLine(1);
    this.builder.addText(
      printLine2Parts('Anzahl Teil-Stornierungen:', (report.partlyCancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts(
        'Summe Teil-Stornierungen:',
        `${asCurrency((report.partlyCancel || 0) / 100)}€`
      )
    );
    this.builder.addText(
      printLine2Parts('Anzahl Voll-Stornierungen:', (report.cancelNumber || 0).toString())
    );
    this.builder.addText(
      printLine2Parts('Summe Voll-Stornierungen:', `${asCurrency((report.cancel || 0) / 100)}€`)
    );
    this.builder.addFeedLine(2);
    this.builder.addText('Mitarbeiter\n');
    this.builder.addFeedLine(1);
    report.employeeBookings.forEach((employee) => {
      this.builder.addText(printLine2Parts('Mitarbeiter', Object.keys(employee)[0]));
      this.builder.addText(
        printLine2Parts('Gesamtumsatz', `${asCurrency(Object.values(employee)[0].amount / 100)}€`)
      );
      this.builder.addText(
        printLine2Parts(
          'Zahlungen BAR',
          `${asCurrency(Object.values(employee)[0].totalCash / 100)}€`
        )
      );
      this.builder.addText(
        printLine2Parts(
          'Zahlungen Karte',
          `${asCurrency(Object.values(employee)[0].totalCard / 100)}€`
        )
      );
      if (withTips === true) {
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld Gesamt',
            `${asCurrency(Object.values(employee)[0].tipAmount / 100)}€`
          )
        );
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld BAR',
            `${asCurrency(Object.values(employee)[0].totalTipCash / 100)}€`
          )
        );
        this.builder.addText(
          printLine2Parts(
            'Trinkgeld Karte',
            `${asCurrency(Object.values(employee)[0].totalTipCard / 100)}€`
          )
        );
      }
      this.builder.addText(''.padStart(paperSize, '-'));
      this.builder.addText('\n');
    });
    this.builder.addFeedLine(3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addTextStyle(undefined, undefined, true, undefined);
    // this.builder.addText('moaby sagt danke!');
    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printTopProducts(shop, products, paperSize, isMonth, selectedDate) {
    const printLine2Parts = (left, right) => {
      const rightSideSpace = right.length + 1;
      const availableSpace = paperSize - rightSideSpace;

      let leftString = left;
      if (leftString.length > availableSpace) {
        leftString = leftString.substring(0, availableSpace);
      }

      leftString = leftString.padEnd(availableSpace, ' ');
      return `${leftString}${right}\n`;
    };

    this.builder.addTextSize(3, 3);
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    // this.builder.addText('moaby\n');
    this.builder.addFeedLine(1);
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(1);
    this.builder.addText(`${shop.name}\n`);
    this.builder.addFeedLine(1);
    this.builder.addTextSize(2, 2);
    this.builder.addText(`Top Produkte \n\n`);
    this.builder.addText(
      `${
        isMonth
          ? moment(selectedDate).locale('de').format('MMMM YY')
          : moment(selectedDate).format('DD.MM.YY')
      }`
    );
    this.builder.addTextSize(1, 1);
    this.builder.addFeedLine(2);
    this.builder.addTextAlign(this.builder.ALIGN_LEFT);
    this.builder.addFeedLine(1);
    this.builder.addText(printLine2Parts('Produkt', 'Menge'));
    this.builder.addText('------------------------------------------\n');
    products.forEach((product) => {
      this.builder.addText(printLine2Parts(product[0], product[1].toString()));
    });

    this.builder.addFeedLine(3);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  printBarcode() {
    function getRandom(length) {
      return Math.floor(
        // eslint-disable-next-line no-restricted-properties
        Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
      ).toString();
    }
    for (let i = 0; i < 51; i++) {
      this.builder.addBarcode(getRandom(14), this.builder.BARCODE_ITF, this.builder.HRI_BELOW);
      this.builder.addFeedLine(2);
    }
    this.builder.addFeedLine(4);
    this.builder.addCut(this.builder.CUT_FEED);
    this.epos.send(this.builder.toString());
  }

  openDrawer() {
    this.builder.addPulse();
    this.epos.send(this.builder.toString());
  }
}
