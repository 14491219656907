/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import CurrencyInput from 'react-currency-input-field';
import { Modal, Button, Image, TextArea, Dropdown, Form, Radio, Icon } from 'semantic-ui-react';
import useScanDetection from 'use-scan-detection';
import StyledInput from '../../../components/general/Input/Input';
import { getCategories, getAllAllergens } from './ItemsScreenData';
import { useStateValue } from '../../../core/context/StateProvider';
import { asCurrency } from '../../../utils/NumberFormatter';
import './ItemsScreen.css';
import { createItem, deleteItem, updateItem } from '../../../api/ItemAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const NewItemsScreenModal = (props) => {
  const { item, onClose, open } = props;
  const [{ config, token, shop }] = useStateValue();
  const availabilityOptions = {
    not: 'not',
    all: 'all',
    terminal: 'terminal',
    qr: 'qr',
  };
  const [data, setData] = useState({
    item: {
      title: '',
      price: 0,
      description: '',
      barcode: '',
      ingredients: [],
      allergens: [],
      optionGroups: [],
      specialSelections: [],
      inHouseTaxRate: 0.19,
      takeAwayTaxRate: 0.19,
      availability: availabilityOptions.all,
      categoryName: '',
      picture: '',
    },
  });

  useEffect(() => {
    if (open && item) {
      setData({ item });
    }
  }, [item, open]);

  const closeModal = (refreshNeeded) => {
    setData({
      item: {
        title: '',
        price: 0,
        description: '',
        barcode: '',
        ingredients: [],
        allergens: [],
        optionGroups: [],
        specialSelections: [],
        inHouseTaxRate: 0.19,
        takeAwayTaxRate: 0.19,
        availability: availabilityOptions.all,
        categoryName: '',
        picture: '',
      },
    });
    onClose(refreshNeeded);
  };

  const parseImageSection = () => (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        minWidth: 300,
        maxWidth: '100%',
        padding: 12,
      }}
    >
      <p className="items-modal-section-title">{i18n.t('itemImageTitle')}</p>
      <div
        style={{
          height: 260,
          border: '1px dashed #dedede',
          borderRadius: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
          color: '#767676',
          fontWeight: 500,
          backgroundColor: '#f5f5f9',
        }}
      >
        <Icon name="image outline" size="big" color="grey" />
        <p>{i18n.t('itemAddImageText')}</p>
      </div>
    </div>
  );

  const parseGeneralSection = () => (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        width: '100%',
        padding: 12,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <p className="items-modal-section-title">{i18n.t('itemGeneralTitle')}</p>
      <div
        style={{
          display: 'flex',
          gap: 22,
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 20,
        }}
      >
        <div style={{ width: '100%' }}>
          <p style={{ fontWeight: 500 }}>{i18n.t('itemGeneralNameTitle')}</p>
          <input className="item-modal-input" placeholder={i18n.t('itemGeneralNameTitle')} />
        </div>
        <div style={{ width: '50%' }}>
          <p style={{ fontWeight: 500 }}>{i18n.t('itemGeneralPriceTitle')}</p>
          <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
            <input
              className="item-modal-input"
              style={{ textAlign: data.item.price ? 'right' : 'left' }}
              value={data.item.price}
              placeholder={i18n.t('itemGeneralNameTitle')}
            />
            <p style={{ fontSize: 18, fontWeight: 500 }}>€</p>
          </div>
        </div>
      </div>
      <p style={{ fontWeight: 500 }}>{i18n.t('itemDescriptionTitle')}</p>
      <textarea
        style={{
          width: '100%',
          border: '1px solid #dedede',
          backgroundColor: '#f5f5f9',
          borderRadius: 10,
          resize: 'none',
          padding: 10,
          height: '100%',
        }}
        placeholder={i18n.t('itemDescriptionTitle')}
      />
    </div>
  );

  const parseLeftSide = () => (
    <div
      style={{
        minWidth: 300,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemCategoryTitle')}</p>
        <Dropdown />
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemTaxesTitle')}</p>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemAvailabilityTitle')}</p>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemBarcodeTitle')}</p>
      </div>
    </div>
  );

  const parseRightSide = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemExtrasTitle')}</p>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemOptionsTitle')}</p>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemIngredientsTitle')}</p>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemAllergensTitle')}</p>
      </div>
    </div>
  );

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="large" closeOnEscape closeOnDimmerClick>
        <Modal.Header
          style={{
            wordBreak: 'break-all',
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            paddingBottom: 4,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '100%', marginBottom: 0 }}>
              {data.item.title || i18n.t('itemsNewItemTitle')}
            </p>
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(false)}
              floated="right"
              className="items-close-btn"
              circular
            />
          </div>
        </Modal.Header>
        <Modal.Content scrolling style={{ backgroundColor: '#f5f5f9', scrollbarWidth: 'none' }}>
          <div className="items-modal-section-group">
            {parseImageSection()}
            {parseGeneralSection()}
          </div>
          <div className="items-modal-section-group">
            {parseLeftSide()}
            {parseRightSide()}
          </div>
        </Modal.Content>
      </AnimatedModal>
    </>
  );
};

export default NewItemsScreenModal;
