/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button, Table } from 'semantic-ui-react';
import CurrencyInput from 'react-currency-input-field';
import './BookingsScreen.css';
import { useMediaQuery } from '@react-hook/media-query';
import { asCurrency } from '../../../utils/NumberFormatter';
import i18n from '../../../i18n/config';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';

const BookingsDiscountModal = (props) => {
  const { onClose, open, cartWithoutToAdd, toAdd } = props;
  const [discountsWithoutAdd, setDiscountsWithoutAdd] = useState({});
  const [discountsToAdd, setDiscountsToAdd] = useState({});

  const mobileMatches = useMediaQuery('only screen and (min-width: 768px)');

  const closeModal = (cartWithoutToAdd, toAdd) => {
    setDiscountsWithoutAdd({});
    setDiscountsToAdd({});
    onClose(cartWithoutToAdd, toAdd);
  };

  const applyDiscountAndClose = () => {
    const newCartWithoutAdd = cartWithoutToAdd.map((item, index) => {
      const nItem = item;
      nItem.amount = Math.round(item.amount);
      if (discountsWithoutAdd[index]) {
        if (discountsWithoutAdd[index].percentage > 0) {
          nItem.discount = {};
          nItem.discount.amount = 0;
          nItem.discount.percentage = parseFloat(discountsWithoutAdd[index].percentage / 100);
        } else if (discountsWithoutAdd[index].amount !== '0') {
          nItem.discount = {};
          nItem.discount.percentage = 0;
          nItem.discount.amount = parseInt(
            Math.round(parseFloat(discountsWithoutAdd[index].amount.replace(',', '.')) * 100)
          );
        } else {
          nItem.discount = {};
          nItem.discount.percentage = 0;
          nItem.discount.amount = 0;
        }
      }
      return nItem;
    });
    const newToAdd = toAdd.map((item, index) => {
      const nItem = item;
      nItem.amount = Math.round(item.amount);
      if (discountsToAdd[index]) {
        if (discountsToAdd[index].percentage > 0) {
          nItem.discount = {};
          nItem.discount.amount = 0;
          nItem.discount.percentage = parseFloat(discountsToAdd[index].percentage / 100);
        } else if (discountsToAdd[index].amount !== '0') {
          nItem.discount = {};
          nItem.discount.percentage = 0;
          nItem.discount.amount = parseInt(
            Math.round(parseFloat(discountsToAdd[index].amount.replace(',', '.')) * 100)
          );
        } else {
          nItem.discount = {};
          nItem.discount.percentage = 0;
          nItem.discount.amount = 0;
        }
      }
      return nItem;
    });
    closeModal(newCartWithoutAdd, newToAdd);
  };

  const applyDiscountAndCloseWithPercentage = (percentage) => {
    if (percentage === 0) {
      const newCartWithoutAdd = cartWithoutToAdd.map((item) => {
        const nItem = item;
        if (nItem.discount) {
          delete nItem.discount;
        }
        return nItem;
      });
      const newToAdd = toAdd.map((item) => {
        const nItem = item;
        if (nItem.discount) {
          delete nItem.discount;
        }
        return nItem;
      });
      closeModal(newCartWithoutAdd, newToAdd);
    } else {
      const newCartWithoutAdd = cartWithoutToAdd.map((item) => {
        if (item.isRefund === false || item.isRefund == null) {
          const nItem = item;
          nItem.discount = {};
          nItem.discount.amount = 0;
          nItem.discount.percentage = percentage;
          return nItem;
        }
        return item;
      });
      const newToAdd = toAdd.map((item) => {
        if (item.isRefund === false || item.isRefund == null) {
          const nItem = item;
          nItem.discount = {};
          nItem.discount.amount = 0;
          nItem.discount.percentage = percentage;
          return nItem;
        }
        return item;
      });
      closeModal(newCartWithoutAdd, newToAdd);
    }
  };

  const parseTableBodyToAdd = () =>
    toAdd &&
    toAdd.length > 0 &&
    toAdd.map((item, index) => (
      <>
        <Table.Row>
          <Table.Cell style={{ wordBreak: 'break-all' }} width="4">
            {item.itemTitle}
            <div className="checkout-screen-specialSelections">
              {item.notes ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">H</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${item.notes}`}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
              {item.options &&
                item.options.map((option) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        marginLeft: 2,
                        marginBottom: 4,
                        alignItems: 'center',
                      }}
                    >
                      <p className="bookings-extras-options-indicator">O</p>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {` ${option.name}`}
                      </p>
                    </div>
                  </>
                ))}
              {item.specialSelections.map((specialSelection) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">E</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${specialSelection.specialSelectionTitle}`}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right">{`${asCurrency(item.amount / 100)}€`}</Table.Cell>
          <Table.Cell textAlign="center">
            <input
              className="booking-discount-input"
              type="number"
              id={`${index}itm-input1`}
              name={`${index}itm-input1n`}
              value={
                discountsToAdd[index]
                  ? discountsToAdd[index].percentage
                  : toAdd[index] && toAdd[index].discount
                  ? parseInt(toAdd[index].discount.percentage * 100, 10)
                  : 0
              }
              min={0}
              max={100}
              onFocus={(e) => {
                e.target.value = '';
                window.scrollTo(0, 0);
                document.body.scrollTop = 0;
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setDiscountsToAdd({ ...discountsToAdd, [index]: { percentage: 0, amount: '0' } });
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'e' || evt.key === ',' || evt.key === '.') {
                  evt.preventDefault();
                }
              }}
              onChange={(e) => {
                let { value } = e.target;
                if (value[0] && value[0] === '0') {
                  value = value.substring(1);
                }
                if (value < 101) {
                  setDiscountsToAdd({
                    ...discountsToAdd,
                    [index]: { percentage: value, amount: '0' },
                  });
                }
                if (value === '') {
                  setDiscountsToAdd({ ...discountsToAdd, [index]: { percentage: 0, amount: '0' } });
                }
              }}
            />{' '}
            <span style={{ fontWeight: 600 }}>%</span>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <CurrencyInput
              className="booking-discount-input"
              id={`${index}itm-input2`}
              name={`${index}itm-input2n`}
              disableGroupSeparators
              allowNegativeValue={false}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onFocus={(e) => {
                e.target.value = '';
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'e') {
                  evt.preventDefault();
                }
              }}
              intlConfig={{ locale: 'de-DE' }}
              value={
                discountsToAdd[index]
                  ? discountsToAdd[index].amount
                  : toAdd[index] && toAdd[index].discount
                  ? toAdd[index].discount.amount / 100
                  : 0
              }
              onValueChange={(value) => {
                if (value) {
                  const val = parseFloat(value.replace(',', '.'));
                  if (val <= parseFloat(item.amount / 100)) {
                    setDiscountsToAdd({
                      ...discountsToAdd,
                      [index]: { percentage: 0, amount: value },
                    });
                  }
                } else {
                  setDiscountsToAdd({
                    ...discountsToAdd,
                    [index]: { percentage: 0, amount: '0' },
                  });
                }
              }}
            />{' '}
            <span style={{ fontWeight: 500 }}>€</span>
          </Table.Cell>
          <Table.Cell textAlign="right">
            {`${
              discountsToAdd[index]
                ? discountsToAdd[index].percentage > 0
                  ? asCurrency(
                      (item.amount / 100) * ((100 - discountsToAdd[index].percentage) / 100)
                    )
                  : asCurrency(
                      parseFloat(item.amount / 100) -
                        parseFloat(discountsToAdd[index].amount.replace(',', '.'))
                    )
                : toAdd[index] && toAdd[index].discount
                ? toAdd[index].discount.percentage > 0
                  ? asCurrency(item.amount * ((1 - toAdd[index].discount.percentage) / 100))
                  : asCurrency(
                      (parseFloat(item.amount) - parseFloat(toAdd[index].discount.amount)) / 100
                    )
                : asCurrency(item.amount / 100)
            }€`}
          </Table.Cell>
        </Table.Row>
      </>
    ));

  const parseTableBodyWithoutAdd = () =>
    cartWithoutToAdd &&
    cartWithoutToAdd.length > 0 &&
    cartWithoutToAdd.map((item, index) => (
      <>
        <Table.Row>
          <Table.Cell style={{ wordBreak: 'break-all' }} width="4">
            {item.itemTitle}
            <div className="checkout-screen-specialSelections">
              {item.notes ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">H</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${item.notes}`}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
              {item.options &&
                item.options.map((option) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        gap: 4,
                        marginLeft: 2,
                        marginBottom: 4,
                        alignItems: 'center',
                      }}
                    >
                      <p className="bookings-extras-options-indicator">O</p>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {` ${option.name}`}
                      </p>
                    </div>
                  </>
                ))}
              {item.specialSelections.map((specialSelection) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      marginLeft: 2,
                      marginBottom: 4,
                      alignItems: 'center',
                    }}
                  >
                    <p className="bookings-extras-options-indicator">E</p>
                    <p
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {` ${specialSelection.specialSelectionTitle}`}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </Table.Cell>
          <Table.Cell textAlign="right">{`${asCurrency(item.amount / 100)}€`}</Table.Cell>
          <Table.Cell textAlign="center">
            <input
              className="booking-discount-input"
              type="number"
              id={`${index}itm-input1`}
              name={`${index}itm-input1n`}
              value={
                discountsWithoutAdd[index]
                  ? discountsWithoutAdd[index].percentage
                  : cartWithoutToAdd[index] && cartWithoutToAdd[index].discount
                  ? parseInt(cartWithoutToAdd[index].discount.percentage * 100, 10)
                  : 0
              }
              min={0}
              max={100}
              onFocus={(e) => {
                e.target.value = '';
                window.scrollTo(0, 0);
                document.body.scrollTop = 0;
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setDiscountsWithoutAdd({
                    ...discountsWithoutAdd,
                    [index]: { percentage: 0, amount: '0' },
                  });
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'e' || evt.key === ',' || evt.key === '.') {
                  evt.preventDefault();
                }
              }}
              onChange={(e) => {
                let { value } = e.target;
                if (value[0] && value[0] === '0') {
                  value = value.substring(1);
                }
                if (value < 101) {
                  setDiscountsWithoutAdd({
                    ...discountsWithoutAdd,
                    [index]: { percentage: value, amount: '0' },
                  });
                }
                if (value === '') {
                  setDiscountsWithoutAdd({
                    ...discountsWithoutAdd,
                    [index]: { percentage: 0, amount: '0' },
                  });
                }
              }}
            />{' '}
            <span style={{ fontWeight: 600 }}>%</span>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <CurrencyInput
              className="booking-discount-input"
              id={`${index}itm-input2`}
              name={`${index}itm-input2n`}
              disableGroupSeparators
              allowNegativeValue={false}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onFocus={(e) => {
                e.target.value = '';
                window.scrollTo(0, 0);
                document.body.scrollTop = 0;
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'e') {
                  evt.preventDefault();
                }
              }}
              intlConfig={{ locale: 'de-DE' }}
              value={
                discountsWithoutAdd[index]
                  ? discountsWithoutAdd[index].amount
                  : cartWithoutToAdd[index] && cartWithoutToAdd[index].discount
                  ? cartWithoutToAdd[index].discount.amount / 100
                  : 0
              }
              onValueChange={(value) => {
                if (value) {
                  const val = parseFloat(value.replace(',', '.'));
                  if (val <= parseFloat(item.amount / 100)) {
                    setDiscountsWithoutAdd({
                      ...discountsWithoutAdd,
                      [index]: { percentage: 0, amount: value },
                    });
                  }
                } else {
                  setDiscountsWithoutAdd({
                    ...discountsWithoutAdd,
                    [index]: { percentage: 0, amount: '0' },
                  });
                }
              }}
            />{' '}
            <span style={{ fontWeight: 500 }}>€</span>
          </Table.Cell>
          <Table.Cell textAlign="right">
            {`${
              discountsWithoutAdd[index]
                ? discountsWithoutAdd[index].percentage > 0
                  ? asCurrency(
                      (item.amount / 100) * ((100 - discountsWithoutAdd[index].percentage) / 100)
                    )
                  : asCurrency(
                      parseFloat(item.amount / 100) -
                        parseFloat(discountsWithoutAdd[index].amount.replace(',', '.'))
                    )
                : cartWithoutToAdd[index] && cartWithoutToAdd[index].discount
                ? cartWithoutToAdd[index].discount.percentage > 0
                  ? asCurrency(
                      (item.amount / 1) * ((1 - cartWithoutToAdd[index].discount.percentage) / 100)
                    )
                  : asCurrency(
                      (parseFloat(item.amount) -
                        parseFloat(cartWithoutToAdd[index].discount.amount)) /
                        100
                    )
                : asCurrency(item.amount / 100)
            }€`}
          </Table.Cell>
        </Table.Row>
      </>
    ));

  return (
    <>
      <AnimatedModal
        open={open}
        size="small"
        dimmer="blurring"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#f5f5f9' }}>
          {i18n.t('discounting')}
        </Modal.Header>
        <Modal.Content
          style={{ backgroundColor: '#f5f5f9', borderRadius: 0, paddingTop: 0, paddingBottom: 6 }}
        >
          <div className="bookings-table-container-discount">
            <Table
              fixed
              padded
              style={{
                border: 'none',
                borderTopLeftRadius: mobileMatches ? 40 : 0,
                borderTopRightRadius: mobileMatches ? 40 : 0,
              }}
            >
              {mobileMatches ? (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign="left"
                      width="4"
                      style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                    >
                      {i18n.t('product')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="right"
                      style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                    >
                      {i18n.t('bookingsPrice')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="center"
                      width="3"
                      style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                    >
                      {i18n.t('discount')} %
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="center"
                      width="3"
                      style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                    >
                      {i18n.t('discount')} €
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      textAlign="right"
                      style={{ backgroundColor: '#f5f5f9', border: 'none' }}
                    >
                      {i18n.t('newPrice')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              ) : (
                <></>
              )}
              <Table.Body>
                {parseTableBodyWithoutAdd()}
                {parseTableBodyToAdd()}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('done')}
            color="teal"
            onClick={() => applyDiscountAndClose()}
            floated="right"
          />
          <div
            style={{
              display: 'flex',
              width: mobileMatches ? 310 : 230,
              height: 36,
              gap: 7,
              marginTop: 0,
            }}
          >
            <div
              style={{ paddingTop: 9 }}
              className="bookings-create-discount-buttons-unselected"
              onClick={() => applyDiscountAndCloseWithPercentage(0)}
            >
              0%
            </div>
            <div
              style={{ paddingTop: 9 }}
              className="bookings-create-discount-buttons-unselected"
              onClick={() => applyDiscountAndCloseWithPercentage(0.1)}
            >
              10%
            </div>
            <div
              style={{ paddingTop: 9 }}
              className="bookings-create-discount-buttons-unselected"
              onClick={() => applyDiscountAndCloseWithPercentage(0.2)}
            >
              20%
            </div>
            <div
              style={{ paddingTop: 9 }}
              className="bookings-create-discount-buttons-unselected"
              onClick={() => applyDiscountAndCloseWithPercentage(0.5)}
            >
              50%
            </div>
            <div
              style={{ paddingTop: 9 }}
              className="bookings-create-discount-buttons-unselected"
              onClick={() => applyDiscountAndCloseWithPercentage(1)}
            >
              100%
            </div>
          </div>
        </Modal.Actions>
      </AnimatedModal>
    </>
  );
};

export default BookingsDiscountModal;
