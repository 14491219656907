/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import i18n from 'i18next';
import { Modal, Button } from 'semantic-ui-react';
import { updateShop } from '../../../api/ShopAPI';
import StyledInput from '../../../components/general/Input/Input';
import { useStateValue } from '../../../core/context/StateProvider';
import './SettingsScreen.css';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const AddressModal = (props) => {
  const { onClose, open } = props;
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [street, setStreet] = useState({ street: '' });
  const [zip, setZip] = useState({ zip: '' });
  const [city, setCity] = useState({ city: '' });
  const [phoneNumber, setPhoneNumber] = useState({ phoneNumber: '' });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    const data = {};
    data.address = {};
    const showMissingFieldModal = () => {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('addressMandatoryFieldsError'),
      });
    };
    let dataIsAlright = true;
    if (street.street || shop.address.street) {
      data.address.street = !street.street ? shop.address.street : street.street;
    } else {
      showMissingFieldModal();
      dataIsAlright = false;
    }
    if (zip.zip || shop.address.zip) {
      data.address.zip = !zip.zip ? shop.address.zip : zip.zip;
    } else {
      showMissingFieldModal();
      dataIsAlright = false;
    }
    if (city.city || shop.address.city) {
      data.address.city = !city.city ? shop.address.city : city.city;
    } else {
      showMissingFieldModal();
      dataIsAlright = false;
    }
    if (phoneNumber.phoneNumber || shop.phoneNumber) {
      data.phoneNumber = !phoneNumber.phoneNumber ? shop.phoneNumber : phoneNumber.phoneNumber;
    } else {
      showMissingFieldModal();
      dataIsAlright = false;
    }
    data.address.country = shop.address.country;
    if (dataIsAlright) {
      setIsLoading(true);
      const res = await updateShop(token, data);
      if (res && res.success) {
        setIsLoading(false);
        setIsSaveDisabled(true);
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('addressUpdateError'),
        });
        setIsLoading(false);
        setIsSaveDisabled(true);
      }
    }
  };

  const closeModal = (refreshNeeded) => {
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="tiny" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ borderBottom: 'none' }}>
          {i18n.t('shopAddressTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="address-fields">
            <StyledInput
              id="street"
              title={shop ? shop.address.street : ''}
              placeholder={i18n.t('shopStreet')}
              onChangeTitle={(value) => {
                setStreet({ street: value });
                setIsSaveDisabled(false);
              }}
            />
            <div style={{ display: 'flex', gap: 6, marginTop: 6 }}>
              <StyledInput
                id="zip"
                title={shop ? shop.address.zip : ''}
                placeholder={i18n.t('shopZip')}
                type="number"
                onChangeTitle={(value) => {
                  if (value) {
                    setZip({ zip: value });
                    setIsSaveDisabled(false);
                  }
                }}
              />
              <StyledInput
                id="city"
                title={shop ? shop.address.city : ''}
                placeholder={i18n.t('shopCity')}
                onChangeTitle={(value) => {
                  if (value) {
                    setCity({ city: value });
                    setIsSaveDisabled(false);
                  }
                }}
              />
            </div>
            <div style={{ marginTop: 6 }}>
              <StyledInput
                id="phoneNumber"
                title={shop && shop.phoneNumber ? shop.phoneNumber : ''}
                placeholder={i18n.t('ownerPhone')}
                onChangeTitle={(value) => {
                  if (value) {
                    setPhoneNumber({ phoneNumber: value });
                    setIsSaveDisabled(false);
                  }
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          <Button
            loading={isLoading}
            content={i18n.t('shopEditSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default AddressModal;
